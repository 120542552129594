import { Team } from '../models/team.model';

export const TEAMS: Team[] = [
  {
    name: 'SENIORS 1',
    shortName: 'Séniors 1',
    thumbmail: 'assets/images/teams/seniors.jpg',
    posterImg: 'assets/images/teams/seniors.jpg',
    competition: 'Championnat Régional',
    coach: 'Dominique BLEY',
    assistantCoachs: [
      'Djibril DIALLO',
      'Jérôme LEFBVRE'
    ],
    isCollapsed: false,
    description: '',
    resultLink: 'https://paris-idf.fff.fr/recherche-clubs/?scl=121889&tab=resultats&subtab=calendar&competition=382659&stage=1&group=2&label=FUTSAL%20R2'
  },
  {
    name: 'SENIORS 2',
    shortName: 'Séniors 2',
    thumbmail: 'assets/images/teams/seniors.jpg',
    posterImg: 'assets/images/teams/seniors.jpg',
    competition: 'Championnat Régional',
    coach: 'Jérôme ROBERT',
    assistantCoachs: [
      'Manuel CABANETOS'
    ],
    isCollapsed: false,
    description: '',
    resultLink: 'https://paris-idf.fff.fr/recherche-clubs/?scl=121889&tab=resultats&subtab=calendar&competition=382698&stage=1&group=1&label=FUTSAL%20R3'
  },
  {
    name: 'FEMININES',
    shortName: 'Féminines',
    thumbmail: 'assets/images/teams/feminines.jpg',
    posterImg: 'assets/images/teams/feminines.jpg',
    competition: 'Critérium Régional',
    coach: 'Laurent VALERO',
    assistantCoachs: [
      'Cédric DARBONNENS',
      'Marylène DARBONNENS'
    ],
    isCollapsed: false,
    description: '',
    resultLink: 'https://paris-idf.fff.fr/recherche-clubs/?scl=121889&tab=resultats&subtab=calendar&competition=391489&stage=1&group=2&label_group=ESPOIR%20-%20POULE%20A'
  },
  {
    name: 'U17 - U18 ',
    shortName: 'U18',
    competition: 'Critérium Régional',
    coach: 'Boris NKONG',
    assistantCoachs: [
      'Sébastien MARAIS'
    ],
    isCollapsed: false,
    description: '',
    resultLink: 'https://paris-idf.fff.fr/recherche-clubs/?scl=121889&tab=resultats&subtab=calendar&competition=391488&stage=1&group=2&label_group=NIVEAU%203%20-%20POULE%20A'
  },
  {
    name: 'U15 - U16',
    shortName: 'U16',
    thumbmail: 'assets/images/teams/u16.jpg',
    posterImg: 'assets/images/teams/u16.jpg',
    competition: 'Critérium Départemental',
    coach: 'Sébastien MARAIS',
    assistantCoachs: [
      'Nicolas PAOLINELLI',
      'Yanis AIT-AKLI'
    ],
    isCollapsed: false,
    description: ''
  },
  {
    name: 'U13 - U14',
    shortName: 'U14',
    thumbmail: 'assets/images/teams/u14.jpg',
    posterImg: 'assets/images/teams/u14.jpg',
    competition: 'Critérium Départemental',
    coach: 'Yanis AIT-AKLI',
    assistantCoachs: [
      'Jasmin HASIC'
    ],
    isCollapsed: false,
    description: ''
  },
  {
    name: 'U11 - U12',
    shortName: 'U12',
    thumbmail: 'assets/images/teams/u12.jpg',
    posterImg: 'assets/images/teams/u12.jpg',
    competition: 'Critérium Départemental',
    coach: 'Laurents VALERO',
    assistantCoachs: [
      'Matteo BAGHINO',
      'David ALMEIDA',
      'Marco FERNANDES'
    ],
    isCollapsed: false,
    description: ''
  },
  {
    name: 'U9 - U10',
    shortName: 'U10',
    thumbmail: 'assets/images/teams/u10.jpg',
    posterImg: 'assets/images/teams/u10.jpg',
    competition: 'Critérium Départemental',
    coach: 'Laurent VALERO',
    assistantCoachs: [
      'Samy LAIMY',
      'Francis BLEY',
      'Thomas DONNADIEU'
    ],
    isCollapsed: false,
    description: ''
  },
  {
    name: 'U7 - U8',
    shortName: 'U8',
    thumbmail: 'assets/images/teams/u8.jpg',
    posterImg: 'assets/images/teams/u8.jpg',
    competition: 'Critérium Départemental',
    coach: 'Jérôme ROBERT',
    assistantCoachs: [
      'Yanis AIT-AKLI',
      'Eden M\'BIKI MEZI',
      'Kerian LECLERCQ'
    ],
    isCollapsed: false,
    description: ''
  },
  {
    name: 'Baby Futsal',
    shortName: 'Baby',
    thumbmail: 'assets/images/teams/baby.jpg',
    posterImg: 'assets/images/teams/baby.jpg',
    competition: 'Critérium Départemental',
    coach: 'Dominique BLEY',
    assistantCoachs: [
      'Sylvia DE MOURA',
      'Edwige FROC',
      'Cédric DARBONNENS',
      'David ALMEIDA'
    ],
    isCollapsed: false,
    description: ''
  },
  {
    name: 'FEMIKIDS',
    shortName: 'Fémikids',
    thumbmail: 'assets/images/teams/femikids.jpg',
    posterImg: 'assets/images/teams/femikids.jpg',
    competition: 'Critérium Départemental',
    coach: 'Cédric DARBONNENS',
    assistantCoachs: [
    ],
    isCollapsed: false,
    description: ''
  },
  {
    name: 'U12F - U13F',
    shortName: 'u13f',
    thumbmail: 'assets/images/teams/u13f.jpg',
    posterImg: 'assets/images/teams/u13f.jpg',
    competition: null,
    coach: 'Cédric DARBONNENS',
    assistantCoachs: [
      'Marco FERNANDES'
    ],
    isCollapsed: false,
    description: ''
  },
  {
    name: 'LOISIRS',
    shortName: 'Loisirs',
    coach: 'Guillaume BRUSSET',
    assistantCoachs: [
    ],
    competition: null,
    isCollapsed: false,
    description: ''
  },
  {
    name: 'FUTSAL EN MARCHANT',
    shortName: 'marcheurs',
    coach: 'Gislain BOUNGO BOKO',
    assistantCoachs: [
    ],
    competition: null,
    isCollapsed: false,
    description: ''
  }
]
