import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { CodirService } from 'src/app/services/codir.service';
import { Person } from 'src/app/models/person.model';

@Component({
  selector: 'b2m-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.scss']
})
export class ClubComponent implements OnInit {
  age: number;
  boardTitle: string = "Le comité de direction";
  boardArray: Person[];

  constructor(
    private viewportScroller: ViewportScroller,
    private codirService: CodirService
    ) { }

  ngOnInit(): void {
    this.age = (new Date()).getFullYear() - 2003;
    this.boardArray = this.codirService.getCodir();
  }
}
