import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Team } from 'src/app/models/team.model';
import { Poster } from '../../models/poster.model';

@Component({
  selector: 'b2m-collapse-info',
  templateUrl: './collapse-info.component.html',
  styleUrls: ['./collapse-info.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*',
        opacity: 1,
        zIndex: 2
      })),
      state('closed', style({
        height: '0',
        opacity: 0,
        zIndex: -1
      })),
      transition('open => closed', [
        animate(300, style({height: 0, zIndex: -1})),
        animate('200ms ease-in-out', style({'opacity': '0'}))
    ]),
      transition('closed => open', [
        animate(300, style({height: '*', zIndex: 2})),
        animate('400ms ease-in-out', style({'opacity': '1'}))
    ]),
    ]),
  ]
})
export class CollapseInfoComponent implements OnInit {

  poster: Poster;
  @Input() posterInput: Team;

  constructor() {  }

  ngOnInit(): void {

  }

  ngOnChanges() {
    //console.log(this.posterInput.resultLink);
    this.poster = new Poster(this.posterInput);
    //this.poster.assistantCoachs = this.posterInput.assistantCoachs !== undefined ? this.posterInput.assistantCoachs.join(' - ') : null;
  }
}
