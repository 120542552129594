/* tslint:disable */
/* eslint-disable */
export class ApiConsentPurpose {
  createdAt?: string;
  description?: string;
  displayOrder?: (number | null);
  id?: number;
  updatedAt?: string;
  uuid?: string;
}

