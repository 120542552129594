import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageComponent } from './components/homepage/homepage.component';
import { CelebrationComponent } from './components/celebration/celebration.component';
import {RouterModule} from '@angular/router';
import { TopComponent } from './components/top/top.component';
import { LabelComponent } from './components/label/label.component';
import { GoalkeeperComponent } from './goalkeeper/goalkeeper.component';
import { TeamsComponent } from './components/teams/teams.component';
import { ProjectComponent } from './components/project/project.component';


@NgModule({
  declarations: [HomepageComponent, CelebrationComponent, TopComponent, LabelComponent, GoalkeeperComponent, TeamsComponent, ProjectComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    HomepageComponent,
    CelebrationComponent,
    TopComponent,
    LabelComponent,
    GoalkeeperComponent,
    TeamsComponent,
    ProjectComponent
  ]
})
export class BannersModule { }
