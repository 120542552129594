import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PublicModule } from '../public/public.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AppRoutingModule } from '../app-routing.module';
import { SlideMenuComponent } from './components/slide-menu/slide-menu.component';
import { GoTopComponent } from './components/go-top/go-top.component';
import { MenuService } from '../services/menu.service';
import { DatePickerModule } from './modules/date-picker/date-picker.module'
@NgModule({
  declarations: [NavbarComponent, HeaderComponent, FooterComponent, SlideMenuComponent, GoTopComponent],
  imports: [
    NgbModule,
    CommonModule,
    PublicModule,
    AppRoutingModule,
    RouterModule,
    DatePickerModule
  ],
  providers: [MenuService],
  exports: [
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    SlideMenuComponent,
    GoTopComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded.');
    }
  }
}
