<div class="progress-bar">
  <div class="progress" [style.width.%]="((currentStep + 1) / totalSteps) * 100"></div>
</div>
<div *ngIf="step" class="form-step">
  <h3 *ngIf="step.title" class="step-title">{{ step.title }}</h3>
  <p [innerHTML]="step.description"></p>
  <form [formGroup]="formGroup" class="step-form">
    <b2m-dynamic-field *ngFor="let field of step.fields" [field]="field" [formGroup]="formGroup" class="form-field">
      <span *ngIf="field.hint" class="field-hint">{{ field.hint }}</span>
      <div *ngIf="field.info" class="field-info">{{ field.info }}</div>
    </b2m-dynamic-field>
  </form>
</div>
