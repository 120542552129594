<div class="row banner-celebration">
    <div class="col-12 col-md-9 order-md-2 banner">
        <img src="assets/images/homepage/bandeau_Happy.jpg" class="img-fluid">
    </div>
    <div class="cold-12 col-md-3 order-md-1 celebration d-flex flex-column justify-content-center align-items-center text-center">
        <div class="title">B2M<br>FUTSALEBRATION</div>
        <div class="btn-more btn-blue">
            <a [routerLink]="['/events']">BIENTÔT DISPONIBLE</a>
        </div>
    </div>
</div>
