import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'b2m-goalkeeper',
  templateUrl: './goalkeeper.component.html',
  styleUrls: ['./goalkeeper.component.scss']
})
export class GoalkeeperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
