import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [],
  imports: [
    SharedModule
  ]
})
export class LoginModule { }
