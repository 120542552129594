import { AbstractControl, ValidatorFn } from '@angular/forms';

export function dateRangeValidator(minDate?: Date, maxDate?: Date): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return null; // Pas de valeur, donc pas d'erreur
    }

    const date = new Date(value);
    if (minDate && date < minDate) {
      return { 'dateRange': `La date doit être après le ${minDate.toLocaleDateString('fr-FR')}` };
    }
    if (maxDate && date > maxDate) {
      return { 'dateRange': `La date doit être avant le ${maxDate.toLocaleDateString('fr-FR')}` };
    }
    return null;
  };
}
