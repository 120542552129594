import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
//import { AnimateModule } from '@wizdm/animate';

import { StaffComponent } from './components/staff/staff.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [StaffComponent],
  imports: [
    CommonModule,
    NgbModule,
    //AnimateModule,
    SharedModule
  ]
})
export class StaffModule { }
