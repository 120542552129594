/* tslint:disable */
/* eslint-disable */
export class ApiRole {
  createdAt?: string;
  id?: number;
  name?: string;
  updatedAt?: string;
  uuid?: string;
}

