import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { CalendarComponent } from './components/calendar/calendar.component';
import { PopoverComponent } from './components/popover/popover.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { TuiCalendarModule } from '../../modules/tui-calendar/tui-calendar.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [/*CalendarComponent,*/ PopoverComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    //TuiCalendarModule
  ],
  exports: [
    //CalendarComponent,
    PopoverComponent
  ]
})
export class CalendarModule { }
