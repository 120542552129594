export class Team {
    name: string;
    shortName: string;
    thumbmail?: string;
    posterImg?: string;
    competition?: string;
    coach?: string;
    assistantCoachs?: Array<string>;
    isCollapsed?: boolean;
    description?: string;
    resultLink?: string;
}
