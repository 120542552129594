import { Component, Input, OnInit } from '@angular/core';
import { FormField } from '../../models/form-field.model';
import { FieldService } from '../../services/field.service';
import { formatDate } from '@angular/common';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'b2m-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  @Input() field: FormField;
  member: any;
  choosenCategory: any;
  currentSeason: any;
  duesRates: any[];
  mutation: boolean;
  mutationprice: number = 0;
  isMemberLastSeason: boolean;

  constructor(private fieldService: FieldService) {}

  ngOnInit() {
    this.fieldService.field$.subscribe(field => {
      this.field = field;
      if (this.field) {
        this.updateFields();
      }
    });
  }

  updateFields() {
    const findOptionValue = (label) => {
      const option = this.field.options.find(option => option.label === label);
      if (option && Array.isArray(option.value) && option.value.length > 0) {
        return option.value[0]; // retourne la première valeur si c'est un tableau
      }
      return option ? option.value : null;
    };

    this.member = findOptionValue('member');
    this.choosenCategory = findOptionValue('choosenCategory');
    this.currentSeason = findOptionValue('currentSeason');
    this.duesRates = findOptionValue('duesRates');
    this.mutation = findOptionValue('mutation');
    this.isMemberLastSeason = findOptionValue('isMemberLastSeason');

    // TODO change to dynamic date
    let memberBirth = formatDate(this.member.birthDate, 'yyyy-MM-dd', 'en-US', 'Europe/Paris');
    let mutationDate = formatDate(new Date('2013-12-31'), 'yyyy-MM-dd', 'en-US', 'Europe/Paris');
    if (memberBirth < mutationDate) {
      this.mutationprice = 35;

      // TODO change to dynamic date
      let mutationDate = formatDate(new Date('2007-12-31'), 'yyyy-MM-dd', 'en-US', 'Europe/Paris');
      if (memberBirth < mutationDate) {
        this.mutationprice = 92;
      }
    }
  }

  directlyJoined() {
    return this.choosenCategory?.isJoined && (this.isMemberLastSeason || this.choosenCategory?.acceptanceRule === 'direct_validation');
  }

  pendingJoin(){
    return this.choosenCategory?.isJoined && !this.isMemberLastSeason && this.choosenCategory?.acceptanceRule === 'staff_see';
  }

  toValidateJoin(){
    return this.choosenCategory?.isJoined && !this.isMemberLastSeason && this.choosenCategory?.acceptanceRule === 'staff_validation';
  }

  getPastelRandomColor(): string {
    const colors = ['#FFB3BA', '#FFDFBA', '#FFFFBA', '#BAFFC9', '#BAE1FF'];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  saveAsPDF() {
    const summaryContainer = document.querySelector('.summary-container') as HTMLElement;
    if (summaryContainer) {
      html2canvas(summaryContainer).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        // Create a new jsPDF instance with a dynamic height
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [canvas.width, canvas.height] // Set the format to match the canvas size
        });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('inscription_summary.pdf');
      });
    }
  }
}
