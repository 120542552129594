import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { NgxMatDateFnsDateModule } from "ngx-mat-datefns-date-adapter";
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
 //   NgxMatDateFnsDateModule
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: "fr",
    },
  ],
})
export class DatePickerModule { }
