import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'b2m-block-partners',
  templateUrl: './block-partners.component.html',
  styleUrls: ['./block-partners.component.scss']
})
export class BlockPartnersComponent implements OnInit {

  @Input() image: string;
  @Input() title: any;
  @Input() button: any;
  @Input() partners: any[];
  defaultImageWidth: string = '200px';

  constructor() { }

  ngOnInit(): void {
  }

  onLearnMoreClick(): void {

  }

}
