<div class="container text-center contact_zone" id="contact">
  <div class="contact_title">Nous contacter</div>
  <address>
    <span><a href="mailto:contact@b2mfutsal.fr" class="contact_link">contact@b2mfutsal.fr</a></span>
    /
    <span class="contact_txt">Tél : +33 (0) 6 52 34 61 80 </span>
    /
    <span class="contact_txt">11 rue Félix Faure - 94360 - Bry-sur-Marne, FRANCE</span>
  </address>
  <div>
    <ul class="btn_sociaux">

      <li><a href="https://www.facebook.com/b2mfutsal/" target="_blank"><i class="fab fa-facebook-f"></i></a>
      </li>
      <li><a href="https://www.instagram.com/b2mfutsal/" target="_blank"><i class="fab fa-instagram"></i></a>
      </li>
      <li><a href="https://twitter.com/b2mfutsal" target="_blank"><i class="fab fa-twitter"></i></a>
      </li>
      <li><a href="https://www.youtube.com/channel/UC6PGIB_obMoE01pbQs7GP0g/featured" target="_blank"><i
            class="fab fa-youtube"></i></a></li>
    </ul>
  </div>
</div>
