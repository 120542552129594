import { Injectable } from '@angular/core';
import { HateoasTransformService } from './hateoas-transform.service';
import { TrainingSlotService } from './training-slot.service';
import { VenueService } from './venue.service';
import { ApiCategory } from '../api/models';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { JoinPeriodService } from './join-period.service';
import { JoinRuleService } from './join-rule.service';

@Injectable({
  providedIn: 'root'
})
export class TransformationService {

  constructor(
    private hateoasTransformService: HateoasTransformService,
    private trainingSlotService: TrainingSlotService,
    private venueService: VenueService,
    private joinPeriodService: JoinPeriodService,
    private joinRuleService: JoinRuleService
  ) { }

  transformCategory(category: ApiCategory, headerXTransactionId: string): Observable<ApiCategory> {
    return forkJoin({
      transformedTrainingSlots: this.transformTrainingSlots(category.trainingSlots, headerXTransactionId),
      transformedJoinPeriods: this.transformJoinPeriods(category.joinPeriods, headerXTransactionId)
    }).pipe(
      map(({ transformedTrainingSlots, transformedJoinPeriods }) => ({
        ...category,
        trainingSlots: transformedTrainingSlots,
        joinPeriods: transformedJoinPeriods
      }))
    );
  }

  transformJoinPeriods(joinPeriods: any[], headerXTransactionId: string): Observable<any[]> {
    return this.hateoasTransformService.transformLinks(joinPeriods, this.joinPeriodService, headerXTransactionId).pipe(
      switchMap(joinPeriods => {
        const transformedJoinPeriods$ = joinPeriods.map(joinPeriod => {
          return this.hateoasTransformService.transformLink(joinPeriod.joinRule, this.joinRuleService, headerXTransactionId).pipe(
            map(joinRule => ({ ...joinPeriod, joinRule }))
          );
        });
        return forkJoin(transformedJoinPeriods$);
      })
    );
  }

  transformTrainingSlots(trainingSlots: any[], headerXTransactionId: string): Observable<any[]> {
    return this.hateoasTransformService.transformLinks(trainingSlots, this.trainingSlotService, headerXTransactionId).pipe(
      switchMap(trainingSlots => {
        const transformedTrainingSlots$ = trainingSlots.map(trainingSlot =>
          this.hateoasTransformService.transformLink(trainingSlot.venue, this.venueService, headerXTransactionId).pipe(
            map(venue => ({ ...trainingSlot, venue }))
          )
        );
        return forkJoin(transformedTrainingSlots$);
      })
    );
  }
}
