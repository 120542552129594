import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormField } from "../../models/form-field.model";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "b2m-dual-selector",
  templateUrl: "./dual-selector.component.html",
  styleUrls: ["./dual-selector.component.scss"],
})
export class DualSelectorComponent implements OnInit {
  @Input() field: FormField;
  @Input() formGroup: FormGroup;
  @Input() multiple: boolean = false;
  @Output() selectionChange = new EventEmitter<any[]>();

  selectedItems: Set<any> = new Set();

  ngOnInit() {
    if (this.field.defaultValue !== undefined) {
      this.selectedItems.add(this.field.defaultValue);
      this.emitSelectionChange();
    }
  }

  toggleSelection(item: { label: string; value: any }) {
    if (this.multiple) {
      if (this.isSelected(item)) {
        this.selectedItems.delete(item.value);
      } else {
        this.selectedItems.add(item.value);
      }
    } else {
      if (this.isSelected(item)) {
        this.selectedItems.clear();
      } else {
        this.selectedItems.clear();
        this.selectedItems.add(item.value);
      }
    }
    this.emitSelectionChange();
  }

  isSelected(item: { label: string; value: any }): boolean {
    return this.selectedItems.has(item.value);
  }

  emitSelectionChange() {
    this.selectionChange.emit(Array.from(this.selectedItems));
  }
}
