import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'b2m-go-top',
  templateUrl: './go-top.component.html',
  styleUrls: ['./go-top.component.scss']
})
export class GoTopComponent {
  windowScrolled: boolean = false;

  // Écoute du scroll de la fenêtre pour afficher/masquer le bouton
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset > 500) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  // Scroll fluide vers le haut
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
