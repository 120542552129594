/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApiDuesRate } from '../../models/api-dues-rate';

export interface ApiV1DuesRatesGetCollection$Params {

/**
 * The collection page number
 */
  page?: number;
  inScopeTown?: boolean;
  ranking?: number;
  'ranking[]'?: Array<number>;

/**
 * Transaction ID for the business request
 */
  'X-Transaction-Id'?: string;
}

export function apiV1DuesRatesGetCollection(http: HttpClient, rootUrl: string, params?: ApiV1DuesRatesGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiDuesRate>>> {
  const rb = new RequestBuilder(rootUrl, apiV1DuesRatesGetCollection.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('inScopeTown', params.inScopeTown, {"style":"form","explode":false});
    rb.query('ranking', params.ranking, {"style":"form","explode":false});
    rb.query('ranking[]', params['ranking[]'], {"style":"form","explode":true});
    rb.header('X-Transaction-Id', params['X-Transaction-Id'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ApiDuesRate>>;
    })
  );
}

apiV1DuesRatesGetCollection.PATH = '/v1/dues_rates';
