<div class="pl-2 pr-2 footer">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-3">
                        <div class="title2">B2M FUTSAL</div>
                        <ul class="menu">
                            <li *ngFor="let item of b2mMenu">
                                <a *ngIf="item.route !== null; else externalLink" [routerLink]="[item.route]">{{item.menuTitle}}</a>
                                <ng-template #externalLink><a [href]="item.link" target="_blank">{{item.menuTitle}}</a></ng-template>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <div class="title2">B2M FAMILY</div>
                        <ul class="menu">
                            <li *ngFor="let item of familyMenu">
                                <a *ngIf="item.route !== null; else externalLink" [routerLink]="[item.route]">{{item.menuTitle}}</a>
                                <ng-template #externalLink><a [href]="item.link" target="_blank">{{item.menuTitle}}</a></ng-template>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <div class="title2">SERVICES</div>
                        <ul class="menu">
                            <li *ngFor="let item of servicesMenu">
                                <a *ngIf="item.route !== null; else externalLink" [routerLink]="[item.route]">{{item.menuTitle}}</a>
                                <ng-template #externalLink><a [href]="item.link" target="_blank">{{item.menuTitle}}</a></ng-template>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <div class="title2">CREATION</div>
                        <div>© B2M Futsal 2003-{{currentYear}}</div>
                        <div>Tous droits réservés</div>
                        <div>v{{version}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 center separator">
                <img src="assets/images/logos/logo_B2M_monochrome.svg" class="align-self-start center b2m_logo" />
                <img src="assets/images/labels/FFF_LABEL-JEUNES_CA-FUSTAL_OR_VERTICAL_RVB.svg" class="align-self-start center fff_Label" />
            </div>
        </div>

    </div>
</div>

<div class="row">
    <img class="filet" src="assets/images/degrade_bg.jpg" width="100%" />

</div>

<!--go top-->
<div class="navbar-fixed-bottom">
    <div class="container" align="right">
        <div class="go-top">
            <div class="icon-go-top">
                <a href="#page-top" class="page-scroll">
                    <i class="fas fa-chevron-up"></i>
                </a>
            </div>
        </div>
    </div>
</div>
