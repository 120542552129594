<div class="title1">
  <span class="title-blue">b2m futsal </span> <span class="title-red">ACCESS</span>
</div>
<div class="intro-text text-center">
  <b>B2M Futsal s'attache à transmettre son expérience et ses valeurs </b>
  à tous ses licenciés, ses membres et ses partenaires.
  <br>
  Le club, par son sens de la création, des idées et ses fondations, contribue au développement du Futsal en France.
</div>

<b2m-block [blockList]="menuItems"></b2m-block>

<button class="btn-inscription" *ngIf="isMobile" routerLink="/membership" [ngClass]="{'hide': windowScrolled}">
  <i class="fa fa-user-plus"></i> Inscription
</button>

