import { CategoryFront } from 'src/app/models/category-front.model';
import { ApiCategory, ApiTrainingSlot, ApiVenue } from 'src/app/api/models';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { ApiCategoryService, ApiTrainingSlotService, ApiVenueService } from 'src/app/api/services';
import { MEMBERSHIP_SEASON_LABEL } from 'src/app/constants';
import { MembershipService } from 'src/app/services/membership.service';
import { HelperUtils } from 'src/app/utils/helper.utils';

@Component({
  selector: 'b2m-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit {
  private fragment: string;
  categories: ApiCategory[];
  categoriesFront: CategoryFront[];
  maleSubCategories: string[][];
  femaleSubCategories: string[][];
  trainingSlots: ApiTrainingSlot[];
  venues: ApiVenue[];
  headerXTransactionId: string;
  currentSeason: string = MEMBERSHIP_SEASON_LABEL;
  public isMaleCategoriesCollapsed: boolean = true;
  public isFemaleCategoriesCollapsed: boolean = true;
  public isTrainingsCollapsed: boolean = false;

  sortedCategories: { mixed: CategoryFront[], woman: CategoryFront[], man: CategoryFront[] } = {
    mixed: [],
    woman: [],
    man: []
  };
  isLoading: boolean = true;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _membershipService: MembershipService,
    private _categoryService: ApiCategoryService,
    private _venueService: ApiVenueService,
    private _trainingSlotService: ApiTrainingSlotService
    ) { }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

  ngOnInit(): void {
    this.headerXTransactionId = HelperUtils.generateUUID();
    forkJoin([
        this._categoryService.apiV1CategoriesGetCollection({
          'X-Transaction-Id': this.headerXTransactionId,
          'season.label': MEMBERSHIP_SEASON_LABEL
        }),
        this._venueService.apiV1VenuesGetCollection({'X-Transaction-Id': this.headerXTransactionId}),
        this._trainingSlotService.apiV1TrainingSlotsGetCollection({'X-Transaction-Id': this.headerXTransactionId})
    ]).subscribe(
        (([categories, venues, trainingSlots]: [ApiCategory[], ApiVenue[], ApiTrainingSlot[]]) => {
          this.categories = categories;
          this.venues = venues;
          this.trainingSlots = trainingSlots;
          this.setCategoriesFront();
          this.isLoading = false;
        })
    );

    //this.categories = this.membershipService.getAllStaticWithTrainings();
    this.maleSubCategories = this._membershipService.getStaticSubCategories('male');
    this.femaleSubCategories = this._membershipService.getStaticSubCategories('female');
    //this.trainings = this._trainingService.getAllStatic();

    this.route.fragment.subscribe(fragment => { this.fragment = fragment; })
  }

  setCategoriesFront() {
    const categoriesFront: CategoryFront[] = this.categories.map((category: ApiCategory) => {

      const traingSlotsOfCategory: ApiTrainingSlot[] = this.trainingSlots.filter((trainingSlot: ApiTrainingSlot) => HelperUtils.getIdFromIRI(trainingSlot.category) === category.uuid)
        .map((trainingSlot: ApiTrainingSlot) => {
          return {
            ...trainingSlot,
            venueFront: this.venues.filter((venue: ApiVenue) => HelperUtils.getIdFromIRI(trainingSlot.venue) === venue.uuid).shift()
          }
        });

      return {
        ...category,
        trainingSlotsFront: traingSlotsOfCategory
      };
    }).filter((category) => category.displayInTrainingSlots);

    // Trier par genre, puis par maxYear descendant, avec null en dernier
    const sortByYear = (a: CategoryFront, b: CategoryFront) => {
      if (a.maxYear === null) return 1;
      if (b.maxYear === null) return -1;
      return b.maxYear - a.maxYear;
    };

    this.sortedCategories.mixed = categoriesFront
      .filter(category => category.gender === 'mixed')
      .sort(sortByYear);

    this.sortedCategories.woman = categoriesFront
      .filter(category => category.gender === 'woman')
      .sort(sortByYear);

    this.sortedCategories.man = categoriesFront
      .filter(category => category.gender === 'man')
      .sort(sortByYear);
  }

  getGenderLabel(gender: string): string {
    switch (gender) {
      case 'mixed':
        return 'Catégories Mixtes';
      case 'woman':
        return 'Catégories Féminines';
      case 'man':
        return 'Catégories Masculines';
      default:
        return 'Autres Catégories';
    }
  }

  getYearRange(category: CategoryFront): string {
    const { minYear, maxYear } = category;
    if (minYear === maxYear && minYear !== null) {
      return `${minYear}`;
    }
    if (minYear === null && maxYear === null) {
      return 'Toutes les années de naissance';
    }
    if (minYear === undefined) {
      return `A partir de ${maxYear}`;
    }
    if (maxYear === undefined) {
      return `Jusqu'à ${minYear}`;
    }
    return `De ${minYear} à ${maxYear}`;
  }

  getIconClass(gender: string): string {
    switch (gender) {
      case 'mixed':
        return 'fas fa-venus-mars'; // Icône mixte
      case 'woman':
        return 'fas fa-venus'; // Icône féminine
      case 'man':
        return 'fas fa-mars'; // Icône masculine
      default:
        return '';
    }
  }
}
