import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiJoinRuleService } from '../api/services';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JoinRuleService {

  private joinRules$: Observable<any[]>;

  constructor(
    private apiJoinRuleService: ApiJoinRuleService
  ) { }

  getJoinRules(headerXTransactionId: string): Observable<any[]> {
    if (!this.joinRules$) {
      this.joinRules$ =  this.apiJoinRuleService.apiV1JoinRulesGetCollection({'X-Transaction-Id': headerXTransactionId}).pipe(
        shareReplay(1)
      );
    }
    return this.joinRules$;
  }

  getByUUID(uuid: string, headerXTransactionId: string): Observable<any> {
    if (!this.joinRules$) {
      this.getJoinRules(headerXTransactionId);
    }

    return this.joinRules$.pipe(
      map(joinRules => joinRules.find(joinRule => joinRule.uuid === uuid))
    );
  }
}
