/* tslint:disable */
/* eslint-disable */
export class ApiJoinRule {
  code?: string;
  createdAt?: string;
  description?: (string | null);
  id?: number;
  previousCodes?: any;
  updatedAt?: string;
  uuid?: string;
}

