import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { FacebookModule } from 'ngx-facebook';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
//import { AnimateModule } from '@wizdm/animate';

import { AlertModule } from './modules/alert/alert.module';
import { SplitPipe } from './pipes/split.pipe';
import { EncodeURIPipe } from './pipes/encode-uri.pipe';
import { BannerNewsComponent } from './components/banner-news/banner-news.component';
import { BannerMembershipComponent } from './components/banner-membership/banner-membership.component';
import { AppRoutingModule } from '../app-routing.module';
import { ContactComponent } from './components/contact/contact.component';
import { CollapseInfoComponent } from './components/collapse-info/collapse-info.component';
import { NewsComponent } from './components/news/news.component';
import { MessengerComponent } from './components/messenger/messenger.component';
import { BannerB2mprojectComponent } from './components/banner-b2mproject/banner-b2mproject.component';
import { BannerBusinessCupComponent } from './components/banner-business-cup/banner-business-cup.component';
import { BannerUaeComponent } from './components/banner-uae/banner-uae.component';
import { BannerPartnerComponent } from './components/banner-partner/banner-partner.component';
import { OrganizationChartComponent } from './components/organization-chart/organization-chart.component';
import { BannerStoreComponent } from './components/banner-store/banner-store.component';
import { BannersModule } from './modules/banners/banners.module';
import { UnavailableComponent } from './components/unavailable/unavailable.component';
import { GenderSelectorComponent } from './components/gender-selector/gender-selector.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { MatInputModule } from '@angular/material/input';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TownSelectComponent } from './components/town-select/town-select.component';
import { MatOptionModule } from '@angular/material/core';
import { SelectComponent } from './components/select/select.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    SplitPipe,
    EncodeURIPipe,
    BannerNewsComponent,
    BannerMembershipComponent,
    ContactComponent,
    CollapseInfoComponent,
    NewsComponent,
    MessengerComponent,
    BannerB2mprojectComponent,
    BannerBusinessCupComponent,
    BannerUaeComponent,
    BannerPartnerComponent,
    OrganizationChartComponent,
    BannerStoreComponent,
    UnavailableComponent,
    GenderSelectorComponent,
    InputFieldComponent,
    DatePickerComponent,
    TownSelectComponent,
    SelectComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    AlertModule,
    BannersModule,
    AppRoutingModule,
    RouterModule,
    FacebookModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatOptionModule,
    MatSelectModule
    //AnimateModule
  ],
  exports: [
    AlertModule,
    BannersModule,
    SplitPipe,
    EncodeURIPipe,
    BannerNewsComponent,
    BannerMembershipComponent,
    ContactComponent,
    CollapseInfoComponent,
    NewsComponent,
    MessengerComponent,
    BannerB2mprojectComponent,
    BannerBusinessCupComponent,
    BannerUaeComponent,
    BannerPartnerComponent,
    OrganizationChartComponent,
    BannerStoreComponent,
    UnavailableComponent,
    GenderSelectorComponent,
    InputFieldComponent,
    DatePickerComponent,
    TownSelectComponent,
    SelectComponent
  ]
})
export class SharedModule { }
