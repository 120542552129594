import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, take, timeout } from 'rxjs/operators';
import moment from 'moment';

import {
  ApiCategory, ApiConsent, ApiConsentPurpose, ApiCountry, ApiJoinPeriod, ApiJoinRule,
  ApiMember, ApiMembership, ApiRole, ApiSeason, ApiVenue, ApiWaitingList
} from 'src/app/api/models';

import {
  ApiConsentPurposeService,
  ApiConsentService,
  ApiCountryService, ApiMemberService, ApiMembershipService, ApiWaitingListService
} from 'src/app/api/services';

import { VenueService } from 'src/app/services/venue.service';
import { MembershipService } from 'src/app/services/membership.service';
import { CategoryService } from 'src/app/services/category.service';
import { HateoasTransformService } from 'src/app/services/hateoas-transform.service';
import { TrainingSlotService } from 'src/app/services/training-slot.service';
import { JoinPeriodService } from 'src/app/services/join-period.service';
import { TransformationService } from 'src/app/services/transformation.service';

import { FormStep } from '../dynamic-form/models/form-step.model';
import { MembershipFormComponent } from '../dynamic-form/components/membership-form/membership-form.component';
import { zipCodeValidator } from '../dynamic-form/validators/zip-code.validator';
import { emailValidator } from '../dynamic-form/validators/email.validator';
import { frenchMobileValidator } from '../dynamic-form/validators/french-mobile.validator';

import { LAST_SEASON_LABEL, MEMBERSHIP_SEASON_LABEL } from 'src/app/constants';
import { CategoryFront } from 'src/app/models/category-front.model';
import { HelperUtils } from 'src/app/utils/helper.utils';
import { MEMBER_TEST } from 'src/app/data/member-data';
import { NotificationService } from '../dynamic-form/services/notification.service';
import { Member } from 'src/app/models/member.model';
import { FormField } from '../dynamic-form/models/form-field.model';
import { mustBeValidator } from '../dynamic-form/validators/value.validator';

@Component({
  selector: 'b2m-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {
  private readonly RETRY_COUNT = 3;
  private readonly TIMEOUT_MS = 10000;
  private headerXTransactionId: string;
  @ViewChild('membershipForm') membershipFormComponent: MembershipFormComponent;
  formSteps: FormStep[];
  formGroups: FormGroup[];
  member: ApiMember;
  isMemberLastSeason: boolean;
  havePlayedInAnotherClub: boolean;
  isContinuingWithDoubleLicense: boolean;
  categories: ApiCategory[];
  candidateCategoriesFront: CategoryFront[];
  selectedCategory: CategoryFront | null = null;
  birthYear: number;
  gender: any;
  title: string;
  currentSeason: ApiSeason;
  categoriesOfCurrentSeason: ApiCategory[];
  joinRules: Array<ApiJoinRule>;
  venues: Array<ApiVenue>;
  callsFinished: boolean = false;
  serviceAvailable: boolean = true;
  serviceErrorCode: number;
  serviceErrorMessage: string;
  rolePlayer: ApiRole;
  consentPurposes: ApiConsentPurpose[];
  countries: ApiCountry[];
  private apiStatus = new BehaviorSubject<boolean>(false);
  apiStatus$ = this.apiStatus.asObservable();
  isDirectlyEnrolled: boolean = false;
  lastSeasonMemberships: ApiMembership[] = [];

  alertOptions = {
    id: 'alert-global',
    autoClose: false,
    keepAfterRouteChange: false,
    closable: false
  };

  constructor(
    private apiCountryService: ApiCountryService,
    private categoryService: CategoryService,
    private membershipService: MembershipService,
    private memberService: ApiMemberService,
    private joinPeriodService: JoinPeriodService,
    private hateoasTransformService: HateoasTransformService,
    private trainingSlotService: TrainingSlotService,
    private venueService: VenueService,
    private apiMembershipService: ApiMembershipService,
    private apiWaitingListService: ApiWaitingListService,
    private fb: FormBuilder,
    private transformationService: TransformationService,
    private notificationService: NotificationService,
    private consentService: ApiConsentService,
    private apiConsentPurposeService: ApiConsentPurposeService
  ) {

  }

  ngOnInit(): void {
    this.headerXTransactionId = HelperUtils.generateUUID();
    console.log('Transaction ID:', this.headerXTransactionId);
    this.member = new ApiMember();
    this.loadInitialData();
    this.initializeFormSteps();
    this.membershipService.selectedCategory$.subscribe(
      (category: CategoryFront | null) => {
        if (category) {
          this.selectedCategory = category;
          this.selectedCategory.isAttendanceRateOK = this.checkMinimumAttendanceRate(category);
          // Handle any other logic when the selectedCategory changes
        } else {
          console.warn('Selected category is null.');
        }
      },
      (error) => {
        console.error('Error occurred while subscribing to selectedCategory: ', error);
      }
    );
  }

  private initializeFormSteps(): void {
    this.formSteps = this.getFormSteps();
    this.formGroups = this.formSteps.map(() => this.fb.group({}));
  }

  private getFormSteps(): FormStep[] {
    return [
      this.createFirstStep(), // is membership last season
      this.createSecondStep(), // minimum datas to filter categories
      this.createThirdStep(), // select category
      this.createFourthStep(), // personal data
      this.createFifthStep(), // member address
      this.createSixthStep(), // member contact
      this.createSeventhStep(), // measurements
      this.createEighthStep(), // consents
      this.createLastStep()
    ];
  }

  private createFirstStep(): FormStep {
    return {
      title: "Déjà inscrit l'année dernière ?",
      fields: [
        {
        type: 'dual-selector',
        label: `Etiez-vous déjà inscrit en ${LAST_SEASON_LABEL} ?`,
        name: 'isMembership',
        options: [
          { label: 'Oui', value: true },
          { label: 'Non', value: false }
        ],
        multiple: false,
        // TODO : remove this test before deploying
        defaultValue: MEMBER_TEST?.isMembership,
        validation: [Validators.required]
      },
      {
        type: 'dual-selector',
        label: `Avez-vous joué cette saison ${MEMBERSHIP_SEASON_LABEL} ou la saison précédente dans un autre club de football affilié à la FFF ?`,
        name: 'playedInAnotherClub',
        hidden: true,
        options: [
          { label: 'Oui', value: true },
          { label: 'Non', value: false }
        ],
        multiple: false,
        // TODO : remove this test before deploying
        defaultValue: MEMBER_TEST?.isChangingClub
      },
      {
        type: 'dual-selector',
        label: 'Souhaitez-vous continuer avec une double licence (Futsal et Football) ?',
        name: 'continueDoubleLicense',
        hidden: true,
        options: [
          { label: 'Oui', value: true },
          { label: 'Non', value: false }
        ],
        multiple: false
      }
      ],
      onValueChange: (currentIndex, formGroups) => {
        const isMembershipField = this.formSteps[currentIndex].fields.find(field => field.name === 'isMembership');
        const playedInAnotherClubField = this.formSteps[currentIndex].fields.find(field => field.name === 'playedInAnotherClub');
        const continueDoubleLicenseField = this.formSteps[currentIndex].fields.find(field => field.name === 'continueDoubleLicense');

        this.isMemberLastSeason = formGroups[currentIndex].get('isMembership').value.pop() ?? this.isMemberLastSeason;
        playedInAnotherClubField.hidden = !this.isMemberLastSeason ? false : true;

        if(formGroups[currentIndex].get('playedInAnotherClub').value) {
          this.havePlayedInAnotherClub = formGroups[currentIndex].get('playedInAnotherClub').value.pop() ?? this.havePlayedInAnotherClub;
        }

        continueDoubleLicenseField.hidden = this.havePlayedInAnotherClub ? false : true;

        if(formGroups[currentIndex].get('continueDoubleLicense').value) {
          this.isContinuingWithDoubleLicense = formGroups[currentIndex].get('continueDoubleLicense').value.pop() ?? this.isContinuingWithDoubleLicense;
        }
      },
      postStep: (currentIndex, formGroups, nextStepCallback) => {
        if (this.member) {
          // Vérifiez que toutes les variables nécessaires sont définies
          if (this.isMemberLastSeason !== undefined && this.havePlayedInAnotherClub !== undefined && this.isContinuingWithDoubleLicense !== undefined) {
            this.member.changingClub = !this.isMemberLastSeason && this.havePlayedInAnotherClub && !this.isContinuingWithDoubleLicense;
          } else {
            console.error('One or more variables are undefined', this.isMemberLastSeason, this.havePlayedInAnotherClub, this.isContinuingWithDoubleLicense);
          }
        } else {
          console.error('Member is undefined');
        }

        nextStepCallback();
      }
    };
  }

  private createSecondStep(): FormStep {
    return {
      title: 'Quelques infos sur la personne à inscrire !',
      fields: [
        {
          type: 'select',
          label: 'Genre',
          name: 'gender',
          options: [
            { label: 'Masculin', value: 'man' },
            { label: 'Féminin', value: 'woman' }
          ],
          validation: [Validators.required]
        },
        {
          type: 'text',
          label: 'Année de Naissance',
          name: 'birthYear',
          validation: [Validators.required, Validators.min(1900), Validators.max(new Date().getFullYear())]
        }
      ],
      preStep: (currentIndex, formGroups) => {
        this.prepopulateSecondStep(formGroups[currentIndex]);
      },
      postStep: (currentIndex, formGroups, nextStepCallback) => {
        this.birthYear = formGroups[currentIndex].get('birthYear').value;
        this.gender = formGroups[currentIndex].get('gender').value;
        this.loadFilteredCategories(nextStepCallback);
      }
    };
  }

  private prepopulateSecondStep(formGroup: FormGroup): void {
    /*formGroup.patchValue({
      gender: this.member,
      birthYear: MEMBER_TEST?.birthYear || null,
    });*/
  }

  private loadFilteredCategories(nextStepCallback: () => void): void {
    this.getFilteredCategories(this.gender, this.birthYear)
        .pipe(switchMap(categories => this.transformCategoriesWithChildObjects(categories))
        ).subscribe(
          categoriesWithSlots => this.handleSuccessfulCategoryTransformation(categoriesWithSlots, nextStepCallback),
          error => this.handleCategoryTransformationError(error, nextStepCallback)
    );
  }

  private createThirdStep(): FormStep {
    return {
      title: 'Quelle catégorie ?',
      fields: [{
        type: 'category-selector',
        label: 'Catégories',
        name: 'categories',
        multiple: false,
        validation: [Validators.required]
      }],
      preStep: (currentIndex, formGroups) => {
        this.prepopulateThirdStep(formGroups[currentIndex]);
      },
      postStep: (currentIndex, formGroups, nextStepCallback) => {
        // Set the selected category in the service
        const selectedCategory = formGroups[currentIndex].get('categories').value[0];

        // Subscribe to the selectedCategory and ensure the callback is triggered once it's updated
        this.membershipService.selectedCategory$.subscribe(
          (category: CategoryFront | null) => {
            if (category === selectedCategory) {
              // Trigger the next step callback only when the selectedCategory has been set
              nextStepCallback();
            }
          },
          (error) => {
            console.error('Error occurred while updating selectedCategory: ', error);
          }
        );

        // Update selectedCategory in the service
        this.membershipService.setSelectedCategory(selectedCategory);
      }
    };
  }

  private prepopulateThirdStep(formGroup: FormGroup): void {
    const categoryField = this.formSteps[2].fields.find(field => field.name === 'categories');
    if (categoryField) {
      categoryField.options = this.candidateCategoriesFront;
      categoryField.additionalData = {
        birthYear: this.birthYear,
        gender: this.gender
      };
    }
  }

  private createFourthStep(): FormStep {
    return {
      title: "Qui est l'adhérent ?",
      fields: [
        { type: 'select', label: 'Genre', name: 'gender', options: [{ label: 'Masculin', value: 'man' }, { label: 'Féminin', value: 'woman' }], validation: [Validators.required], disabled: true },
        { type: 'text', label: 'Nom', name: 'lastname', hint: 'Votre nom de famille', info: 'Entrez votre nom tel qu\'il figure sur vos documents officiels.', validation: [Validators.required] },
        { type: 'text', label: 'Nom de naissance', name: 'birthName', hint: 'Votre nom de naissance', info: 'Entrez votre nom de naissance s\'il est différent de votre nom d\'usage' },
        { type: 'text', label: 'Prénom', name: 'firstname', hint: 'Votre prénom', info: 'Entrez votre prénom tel qu\'il figure sur vos documents officiels.', validation: [Validators.required] },
        { type: 'date', label: 'Date de naissance', name: 'birthDate' }
      ],
      preStep: (currentIndex, formGroups) => {
        this.prepopulateFourthStep(formGroups[currentIndex]);
      },
      postStep: (currentIndex, formGroups, nextStepCallback) => {
        this.validateFourthStepAndFetchMemberData(formGroups[currentIndex], nextStepCallback);
      }
    };
  }

  private prepopulateFourthStep(formGroup: FormGroup): void {
    const minDate = new Date(this.birthYear, 0, 1);
    const maxDate = new Date(this.birthYear, 11, 31);
    //const birthDateParts = MEMBER_TEST.birthDate.split('/');
    //const birthDate = new Date(+birthDateParts[2], +birthDateParts[1] - 1, +birthDateParts[0]);
    formGroup.patchValue({
      gender: this.gender,
      lastname: this.member?.lastName || null,
      firstname: this.member?.firstName || null,
      //birthDate: birthDate || null,
    });
    formGroup.get('birthDate').setValidators([Validators.required, Validators.min(minDate.getTime()), Validators.max(maxDate.getTime())]);
    formGroup.get('birthDate').updateValueAndValidity();
  }

  private validateFourthStepAndFetchMemberData(formGroup: FormGroup, nextStepCallback: () => void): void {
    let paramsMember = {
      'X-Transaction-Id': this.headerXTransactionId,
      gender: formGroup.get('gender').value,
      firstName: formGroup.get('firstname').value.trim(),
      lastName: formGroup.get('lastname').value.trim(),
      birthDate: moment(formGroup.get('birthDate').value).format('YYYY-MM-DD'),
      birthName: null
    };

    this.memberService.apiV1MembersGetCollection(paramsMember).pipe(
      timeout(this.TIMEOUT_MS),
      retry(this.RETRY_COUNT),
      catchError((error) => {
        console.error('API error:', error);
        const errorMessage = "Nous avons rencontré une erreur lors de la récupération des données des membres.";
        return this.throwError(error, 1001, errorMessage);
      })
    ).subscribe(data => {
      if (data && Array.isArray(data) && data.length > 0) {
        this.member = { ...this.member, ...data[0] };

      } else {
        this.member = { ...this.member, ...paramsMember };
      }
      this.member.birthName = formGroup.get('birthName').value;

      if (this.isMemberLastSeason && data.length === 0) {
        const message = "Les informations saisies ne correspondent à aucun adhérent dans notre base de données. Veuillez vérifier vos nom, prénom et date de naissance. Si vous pensez qu'il s'agit d'une erreur, contactez-nous à contact@b2mfutsal.fr.";
        this.notificationService.showWarning(message, 'Attention');
      }

      if (data.length === 0) {
        this.isMemberLastSeason = false;
      }

      if (this.isMemberLastSeason) {
        const paramsMembership = {
          'X-Transaction-Id': this.headerXTransactionId,
          'member.uuid': this.member.uuid,
          'category.season.label': LAST_SEASON_LABEL
        };
        this.apiMembershipService.apiV1MembershipsGetCollection(paramsMembership).pipe(
          timeout(this.TIMEOUT_MS),
          retry(this.RETRY_COUNT),
          catchError(error => {
            console.error('API error:', error);
            const errorMessage = "Nous avons rencontré une erreur lors de la récupération des adhésions de la saison dernière.";
            return this.throwError(error, 1002, errorMessage);
          })
        ).subscribe((memberships: ApiMembership[]) => {
          this.lastSeasonMemberships = memberships;
          this.selectedCategory.isAttendanceRateOK = this.checkMinimumAttendanceRate(this.selectedCategory);
        });
      }

      nextStepCallback();
    });
  }

  private createFifthStep(): FormStep {
    return {
      title: 'Où habitez-vous ?',
      fields: [
        { type: 'text', label: 'N° / Rue', name: 'address', validation: [Validators.required] },
        { type: 'text', label: 'Code Postal', name: 'zip', validation: [Validators.required, zipCodeValidator('town')] },
        { type: 'text', label: 'Ville', name: 'town', validation: [Validators.required] }
      ],
      preStep: (currentIndex, formGroups) => {
        formGroups[currentIndex].patchValue({
          address: this.member.address || MEMBER_TEST?.address,
          zip: this.member.zip || MEMBER_TEST?.zip,
          town: this.member?.town || MEMBER_TEST?.town,
        });
      },
      postStep: (currentIndex, formGroups, nextStepCallback) => {
        this.validateFifthStepAndFetchMemberData(formGroups[currentIndex], nextStepCallback);
      }
    };
  }

  private validateFifthStepAndFetchMemberData(formGroup: FormGroup, nextStepCallback: () => void): void {
    if (formGroup && formGroup.controls) {
        const address = formGroup.get('address')?.value;
        const zip = formGroup.get('zip')?.value;
        const town = formGroup.get('town')?.value;

        if (address && zip && town) {
            const params = {
                address: address,
                zip: zip,
                town: town
            };
            this.member = { ...this.member, ...params };
            nextStepCallback();
        } else {
            console.error('Validation failed: One or more fields are invalid or missing');
        }
    } else {
        console.error('Validation failed: FormGroup is invalid');
    }
}

  private createSixthStep(): FormStep {
    return {
      title: 'Vos coordonnées',
      fields: [
        { type: 'text', label: 'Email', name: 'email', validation: [Validators.required, emailValidator()] },
        { type: 'text', label: 'N° mobile', name: 'phoneMobile', validation: [Validators.required, frenchMobileValidator()] },
        { type: 'text', label: 'Ville de naissance', name: 'birthTown', validation: [Validators.required] },
        { type: 'select', label: 'Pays de naissance', name: 'birthCountry', validation: [Validators.required] },
        { type: 'text', label: 'Nationalité', name: 'nationality', validation: [Validators.required] }
      ],
      preStep: (currentIndex, formGroups) => {
        this.loadCountries().subscribe({
          next: () => {
            if (this.member) {
              this.prepopulateContactInfo(formGroups[currentIndex]);
            }
          },
          error: (error) => {
            console.error('Failed to load countries:', error);
            // Bloquer la suite du traitement
            const errorMessage = "Le chargement de la liste des pays a échoué.";
            return this.throwError(error, 1003, errorMessage);
          }
        });
      },
      postStep: (currentIndex, formGroups, nextStepCallback) => {
        this.member.birthCountry = HelperUtils.getIRI('/v1/countries/' + formGroups[currentIndex].get('birthCountry').value);
        this.validateSixthStepAndFetchMemberData(formGroups[currentIndex], nextStepCallback);
      }
    };
  }

  private validateSixthStepAndFetchMemberData(formGroup: FormGroup, nextStepCallback: () => void): void {
    if (formGroup && formGroup.controls) {
        const email = formGroup.get('email')?.value;
        const phoneMobile = formGroup.get('phoneMobile')?.value;
        const birthTown = formGroup.get('birthTown')?.value;
        const nationality = formGroup.get('nationality')?.value;

        if (email && phoneMobile && birthTown && nationality) {
            const params = {email, phoneMobile, birthTown, nationality};
            this.member = { ...this.member, ...params };
            nextStepCallback();
        } else {
            console.error('Validation failed: One or more fields are invalid or missing');
        }
    } else {
        console.error('Validation failed: FormGroup is invalid');
    }
  }
  private throwError(error, code: number = 999, errorMessage: string = "Une erreur inconnue est survenue"): Observable<any> {

    if (error.status === 0) {
      // Cela signifie que l'appel a été bloqué par CORS ou qu'il y a un problème réseau
      errorMessage = "Une erreur CORS s'est produite";
      code = 998;
    }

    this.serviceAvailable = false;
    this.serviceErrorCode = code;
    this.serviceErrorMessage = errorMessage;
    return throwError(() => new Error(errorMessage));
  }

  private loadCountries(): Observable<void> {
    if (this.countries.length === 0) {
      return this.apiCountryService.apiV1CountriesGetCollection({'X-Transaction-Id': this.headerXTransactionId}).pipe(
        timeout(this.TIMEOUT_MS),
        retry(this.RETRY_COUNT),
        catchError(error => {
          console.error('API error:', error);
          return throwError(error);
        }),
        map(data => {
          if (data && data['hydra:member']) {
            this.countries = data['hydra:member'];
            this.updateBirthCountryOptions();
          }
        })
      );
    } else {
      this.updateBirthCountryOptions();
      return of(null);
    }
  }


  private updateBirthCountryOptions(): void {
    const birthCountryField = this.formSteps[5].fields.find(field => field.name === 'birthCountry');
    if (birthCountryField) {
      birthCountryField.options = this.countries.map(country => ({ label: country.label, value: country.uuid }));
    }
  }

  private prepopulateContactInfo(formGroup: FormGroup): void {
    let birthCountryUuid: string | null = null;

    if (this.member?.birthCountry) {
      birthCountryUuid = this.hateoasTransformService.getUUID(this.member.birthCountry);
    }

    // Create an object with only the fields that have values
    const contactInfo: any = {
      email: this.member?.email,
      phoneMobile: this.member?.phoneMobile,
      birthTown: this.member?.birthTown,
      nationality: this.member?.nationality,
    };

    // Conditionally add birthCountry if it is not null
    if (birthCountryUuid !== null) {
      contactInfo.birthCountry = birthCountryUuid;
    }

    formGroup.patchValue(contactInfo);
  }


  private createSeventhStep(): FormStep {
    return {
      title: "Tailles et pointures",
      description : "Pour faciliter la distribution du package d'équipements à chaque adhérents du club, renseignez ci-dessous vos mensurations",
      fields: [
        {
          type: 'select',
          label: 'Taille',
          name: 'size',
          validation: [Validators.required],
          options: [
            { label: 'Enfant - 6A', value: '6A' },
            { label: 'Enfant - 8A', value: '8A' },
            { label: 'Enfant - 10A', value: '10A' },
            { label: 'Enfant - 12A', value: '12A' },
            { label: 'Enfant - 14A', value: '14A' },
            { label: 'Adulte - S', value: 'S' },
            { label: 'Adulte - M', value: 'M' },
            { label: 'Adulte - L', value: 'L' },
            { label: 'Adulte - XL', value: 'XL' },
            { label: 'Adulte - 2XL', value: '2XL' },
            { label: 'Adulte - 3XL', value: '3XL' }
          ]
        },
        {
          type: 'select',
          label: 'Pointure',
          name: 'shoeSize',
          validation: [Validators.required],
          options: [
            { label: '20/28', value: '20/28' },
            { label: '28/32', value: '28/32' },
            { label: '33/36', value: '33/36' },
            { label: '37/40', value: '37/40' },
            { label: '41/44', value: '41/44' },
            { label: '45/48', value: '45/48' }
          ]
        },
      ],
      preStep: (currentIndex, formGroups) => {
        this.prepopulateSevenStep(formGroups[currentIndex]);
      },
      postStep: (currentIndex, formGroups, nextStepCallback) => {
        this.member.size = formGroups[currentIndex].get('size').value;
        this.member.shoeSize = formGroups[currentIndex].get('shoeSize').value;
        nextStepCallback();
      }
    };
  }

  private prepopulateSevenStep(formGroup: FormGroup): void {
    formGroup.patchValue({
      size: this.member?.size,
      shoeSize: this.member?.shoeSize
    });
  }

  private createEighthStep(): FormStep {
    return {
      title: "Votre accord, c'est important !",
      description : "Dans le cadre de notre association, nous collectons des informations à caractère personnel sur nos membres et les enregistrons dans un fichier. Nous collectons ces données dans le but de tenir à jour notre liste de membres et, si vous le souhaitez (en cochant la case d'acceptation), de vous envoyer notre newsletter. En aucun cas, ces données ne seront cédées ou vendues à des tiers. Le staff technique et le comité de direction ont accès à vos données dans le cadre de leurs missions respectives. Conformément au Règlement Général sur la Protection des Données (RGPD), vous avez le droit d'accéder à vos données et de les faire rectifier en nous contactant par e-mail à <a href=\"mailto:rgpd@b2mfutsal.fr\">rgpd@b2mfutsal.fr</a>.",
      fields: [],
      preStep: async (currentIndex, formGroups, updateFormGroup) => {
        try {
          let formFields: FormField[] = [];
          const consentPurposes = await this.apiConsentPurposeService.apiV1ConsentPurposesGetCollection({
                'X-Transaction-Id': this.headerXTransactionId,
                'order[displayOrder]': 'asc'
          }).toPromise();

          consentPurposes.forEach((consentPurpose: ApiConsentPurpose) => {
            let validators: any[] = consentPurpose.displayOrder === 0 ? [mustBeValidator(true)] : [];
            let formField: FormField = {
              type: 'checkbox',
              label: consentPurpose.description,
              name: consentPurpose.uuid,
              validation: validators/*,
              value: MEMBER_TEST !== undefined ? true : false*/
            };
            formFields.push(formField);
          });

          updateFormGroup(currentIndex, formFields);
        } catch (error) {
          console.error('Error fetching consent purposes:', error);
        }
      },
      postStep: (currentIndex, formGroups, nextStepCallback) => {
        this.saveMemberData(() => {
          this.postConsents(currentIndex, formGroups, nextStepCallback);
        });
      }
    };
  }

  private postConsents(currentIndex: number, formGroups: FormGroup[], nextStepCallback: () => void): void {
    const consentFields = this.formSteps[currentIndex].fields;
    let successCount: number = 0;
    let allConsentsSent: boolean = false;

    consentFields.forEach(consentField => {
      const consent: ApiConsent = {
        member: HelperUtils.getIRI('/v1/members/' + this.member.uuid),
        consentPurpose: HelperUtils.getIRI('/v1/consent_purposes/' + consentField.name),
        acceptDate: consentField.value ? new Date().toISOString() : undefined,
        rejectDate: !consentField.value ? new Date().toISOString() : undefined,
      };
      this.consentService.apiV1ConsentsPost({
        'X-Transaction-Id': this.headerXTransactionId,
        body: consent
      }).pipe(
        retry(2),
        catchError(error => {
          console.error('Error fetching household members:', error);
          const errorMessage = "Une erreur s'est produite et empêche l'enregistrement de vos consentements.";
          return this.throwError(error, 1004, errorMessage);
        })
      ).subscribe((data) => {
        successCount++;
        allConsentsSent = successCount === consentFields.length;
        if (allConsentsSent) {
          nextStepCallback();
        }
      });
    });
  }

  private saveMemberData(endCallback: () => void): void {
    let observableSetMember: Observable<ApiMember>;
    this.isDirectlyEnrolled = this.shouldEnrollDirectly(this.selectedCategory, this.member);
    if (this.isDirectlyEnrolled) {
      this.member.status = 'pending';
    } else {
      this.member.status = 'suspended';
    }

    if (this.member.uuid !== undefined) {
      delete this.member.memberships;
      delete this.member.externalDatas;
      delete this.member.createdAt;
      delete this.member.updatedAt;
      delete this.member.waitingLists;
      delete this.member.consents;
      delete this.member.id;
      observableSetMember = this.memberService.apiV1MembersUuidPut({
        'X-Transaction-Id': this.headerXTransactionId,
        uuid: this.member.uuid,
        body: this.member
      });
    } else {
      delete this.member.uuid;
      observableSetMember = this.memberService.apiV1MembersPost({
          'X-Transaction-Id': this.headerXTransactionId,
          body: this.member
        });
    }

    observableSetMember.pipe(
      timeout(this.TIMEOUT_MS),
      retry(this.RETRY_COUNT),
      catchError(error => this.throwError(error, 1007, "Erreur sur enregistrement des données personnelles")),
    ).subscribe((member: ApiMember) => {
        this.member.uuid = member.uuid;
        this.saveMembership(endCallback);
      });
  }


  private saveMembership(nextStepCallback: () => void): void {
    if (this.isDirectlyEnrolled) {
      const apiMembership: ApiMembership = {
        category: HelperUtils.getIRI('/v1/categories/' + this.selectedCategory.uuid),
        member: HelperUtils.getIRI('/v1/members/' + this.member.uuid),
        role: HelperUtils.getIRI('/v1/roles/' + this.rolePlayer.uuid)
      };

      this.apiMembershipService.apiV1MembershipsPost({
        'X-Transaction-Id': this.headerXTransactionId,
        body: apiMembership
      }).pipe(
        timeout(this.TIMEOUT_MS),
        retry(this.RETRY_COUNT),
        catchError(error => {
          if (error.status === 422) {
            // La catégorie est complète, ajouter à la liste d'attente
            this.addToWaitingList(nextStepCallback);
          } else {
            return this.throwError(error, 1007, "Erreur sur enregistrement de l'inscription dans la catégorie");
          }
          return;
        })
      ).subscribe(() => {
        this.membershipService.selectedCategory$.pipe(take(1)).subscribe(
          (category: CategoryFront | null) => {
            this.selectedCategory.isJoined = true;
          },
          (error) => {
            console.error('Error occurred while updating selectedCategory: ', error);
          }
        );

        if (this.isMemberLastSeason) {
          const message = "Votre inscription est un succès";
          this.notificationService.showSuccess(message, 'Succès');
        } else {
          this.notificationService.showSuccess("Votre demande d'inscription a été reçue. Prochaine étape : participez à une première séance d'entraînement et rencontrez l'entraîneur de votre catégorie.", 'Inscription en cours');
        }
        nextStepCallback();
      });
    } else {
      this.addToWaitingList(nextStepCallback);
    }
  }

  private addToWaitingList(nextStepCallback: () => void) {
    const waitingList: ApiWaitingList = {
      category: HelperUtils.getIRI('/v1/categories/' + this.selectedCategory.uuid),
      member: HelperUtils.getIRI('/v1/members/' + this.member.uuid),
    };

    this.apiWaitingListService.apiV1WaitingListsPost({
      'X-Transaction-Id': this.headerXTransactionId,
      body: waitingList
    }).pipe(
        timeout(this.TIMEOUT_MS),
        retry(this.RETRY_COUNT),
        catchError(error => this.throwError(error, 1006, "Erreur sur liste d'attente")),
      )
      .subscribe((waitingList: ApiWaitingList) => {
        this.membershipService.selectedCategory$.pipe(take(1)).subscribe(
          (category: CategoryFront | null) => {
            this.selectedCategory.isWaitingList = true;
            this.notificationService.showInfo("Votre inscription est en liste d'attente. Nous vous contacterons dès qu'une place sera disponible.", "Liste d'attente");
            nextStepCallback();
          },
          (error) => {
            console.error('Error occurred while updating selectedCategory: ', error);
          }
        );
    });
  }

  private handleError<T>(error: any): Observable<T> {
    console.error('Error:', error);
    return of([] as T);
  }

  private createLastStep() {
    return {
      title : "Réponse à votre demande !",
      fields: [
        { type: 'summary', label: 'summary', name: 'summary', options: [] },
      ],
      preStep: (currentIndex, formGroups) => {
        const summaryField = this.formSteps[currentIndex].fields.find(field => field.type === 'summary');
        if (summaryField) {
          summaryField.options.push({ label: 'member', value: this.member });
          summaryField.options.push({ label: 'choosenCategory', value: this.selectedCategory });
          summaryField.options.push({ label: 'currentSeason', value: this.currentSeason });
          summaryField.options.push({ label: 'duesRates', value: 'other' });
          summaryField.options.push({ label: 'mutation', value: this.member.changingClub });
          summaryField.options.push({ label: 'mutationprice', value: 'other' });
          summaryField.options.push({ label: 'isMemberLastSeason', value: this.isMemberLastSeason });
        }
      },
    }
  }

  handleCheckCallbackResult(result: any) {
    this.isMemberLastSeason = result;
  }

  private loadInitialData() {

    this.title = 'Inscription ' + MEMBERSHIP_SEASON_LABEL;

    // needs to call services to initialize singletons of each object arrays
    this.venueService.getVenues(this.headerXTransactionId);

    this.joinPeriodService.getJoinPeriods(this.headerXTransactionId).subscribe(
      data => {
        console.debug('Join periods loaded:', data);
      },
      error => {
        console.error('Error loading join periods', error);
      }
    );

    this.membershipService.getInitialData(this.headerXTransactionId).subscribe(
      data => {
        this.currentSeason = data.currentSeason;
        this.categoriesOfCurrentSeason = data.categoriesOfCurrentSeason;
        this.joinRules = data.joinRules;
        this.venues = data.venues;
        this.rolePlayer = data.rolePlayer;
        this.countries = data.countries;
        this.callsFinished = true;
        this.apiStatus.next(true);
      },
      error => {
        this.throwError(error, 997, "Une ereur est survenue au chargement du formulaire");
      }
    );
  }

  private getFilteredCategories(gender: string, birthYear: number): Observable<ApiCategory[]> {
    return this.categoryService.getFilteredCategories(
      MEMBERSHIP_SEASON_LABEL,
      gender,
      birthYear,
      this.headerXTransactionId
    );
  }

  private transformCategoriesWithChildObjects(categories: ApiCategory[]): Observable<ApiCategory[]> {
    this.trainingSlotService.getTrainingSlots(categories, this.headerXTransactionId); // Initialize singleton

    return forkJoin(
      categories.map(category => this.transformCategory(category))
    );
  }

  private transformCategory(category: ApiCategory): Observable<ApiCategory> {
    return this.transformationService.transformCategory(category, this.headerXTransactionId);
  }

  handleSuccessfulCategoryTransformation(categoriesWithSlots, nextStepCallback: () => void): void {
    // Filtrer les joinPeriods de chaque catégorie
    this.candidateCategoriesFront = categoriesWithSlots.map(category => ({
      ...category,
      joinPeriods: category.joinPeriods.filter(joinPeriod => this.isWithinJoinPeriod(joinPeriod))
    }));
    nextStepCallback();
  }

  private isWithinJoinPeriod(joinPeriod: ApiJoinPeriod): boolean {
    const today = new Date();
    const startDate = new Date(joinPeriod.startDate);
    const endDate = new Date(joinPeriod.endDate);
    return today >= startDate && today <= endDate;
  }

  private checkPeriod(joinPeriods:any[], joinRuleCode): boolean {
    return joinPeriods.some(joinPeriod => joinPeriod.joinRule.code === joinRuleCode && this.isWithinJoinPeriod(joinPeriod));
  }

  private shouldEnrollDirectly(selectedCategory: CategoryFront, member: Member): boolean {

    if (selectedCategory && selectedCategory.gender === 'mixed'
        && member.gender === 'woman') {
      return true;
    }

    if (
      selectedCategory && !selectedCategory.complete
      && this.checkPeriod(selectedCategory.joinPeriods, 'category_renew')
      && (this.isMemberLastSeason && selectedCategory.isAttendanceRateOK)
    ) {
      return true;
    }

    if (
      selectedCategory && !selectedCategory.complete
      && this.checkPeriod(selectedCategory.joinPeriods, 'towns_new')
      && this.membershipService.filterByTown(member)
    ) {
      return true;
    }

    if (
      selectedCategory && !selectedCategory.complete
      && this.checkPeriod(selectedCategory.joinPeriods, 'all_new')
    ) {
      return true;
    }

    return false;
  }

  private handleCategoryTransformationError(error: any, callback: () => void): Observable<any> {
    console.error('API error:', error);
    const errorMessage = "Nous avons rencontré une erreur lors du chargement des catégories.";
    return this.throwError(error, 1005, errorMessage);
  }

  private checkMinimumAttendanceRate(newCategory: CategoryFront): boolean {
    let result: boolean = true;

    if (!newCategory || newCategory.minimumAttendanceRate === undefined) {
      console.error('newCategory or newCategory.minimumAttendanceRate is undefined');
      return result;
    }

    const membershipsWithAttendanceRate = this.lastSeasonMemberships.filter(membership => membership.attendanceRate !== null);
    membershipsWithAttendanceRate.forEach(membershipWithAttendanceRate => {
      if (membershipWithAttendanceRate?.attendanceRate !== undefined) {
          result = result && (membershipWithAttendanceRate.attendanceRate >= newCategory.minimumAttendanceRate);
      }
    });

    return result;
  }
}
