import { MatDateFormats } from '@angular/material/core';

export const MEMBERSHIP_SEASON_LABEL: string = '2024-2025';
export const LAST_SEASON_LABEL: string = '2023-2024';
export const MAX_BIRTH_DATE: Date = new Date('2021-12-31');

export const TOWN_ZIP_CODES = [
  '94170', // Le Perreux-sur-Marne
  '94360', // Bry-sur-Marne
  '93360', // Neuilly-Plaisance
  '93160', // Noisy-le-Grand
];

export const START_BIRTHDATE_PICKER: Date = new Date(1995, 0, 1);

export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
