const arrayMembers = [
  // Case 0
  {
    isMembership: true,
    gender: '',
    birthYear: 1900,
    categories: '',
    lastname: '',
    firstname: '',
    birthDate: '',
    'address': '',
    'zip': '',
    'town': '',
    'email': '',
    'phoneMobile': '',
    'birthTown': '',
    birthCountry: '',
    'nationality': ''
  },
  // Case 1
  {
    isMembership: false,
    isChangingClub: false,
    gender: 'man',
    birthYear: 2021,
    lastname: 'Case 1',
    firstname: 'John',
    birthDate: '01/01/2021',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    category: 'Baby U4 ouest',
    size: '6A',
    shoeSize: '20/28'
  },
  // Case 2
  {
      isMembership: true,
      gender: 'man',
      birthYear: 2020,
      lastname: 'Bekri',
      firstname: 'Sohan',
      birthDate: '03/09/2020'
  },
  // Case 3
  {
    isMembership: false,
    isChangingClub: false,
    gender: 'man',
    birthYear: 2020,
    lastname: 'Case 3',
    firstname: 'John',
    birthDate: '01/01/2020',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    size: '6A',
    shoeSize: '20/28'
  },
  // Case 4
  {
      isMembership: false,
      isChangingClub: false,
      gender: 'woman',
      birthYear: 2020,
      lastname: 'Case 4',
      firstname: 'Jane',
      birthDate: '01/01/2020',
      address: '2 rue du ballon',
      zip: '94360',
      email: 'test@b2mfutsal.fr',
      phoneMobile: '0612345678',
      birthTown: 'Saint-Maur',
      birthCountry: 'FR',
      nationality: 'FR',
      size: '6A',
      shoeSize: '20/28'
  },
  // Case 5
  {
      isMembership: true,
      gender: 'man',
      birthYear: 2019,
      lastname: 'Sekkai',
      firstname: 'Rahim',
      birthDate: '17/03/2019'
  },
  // Case 6
  {
      isMembership: false,
      isChangingClub: false,
      gender: 'man',
      birthYear: 2019,
      lastname: 'Case 6',
      firstname: 'John',
      birthDate: '01/01/2019',
      address: '2 rue du ballon',
      zip: '94360',
      email: 'test@b2mfutsal.fr',
      phoneMobile: '0612345678',
      birthTown: 'Saint-Maur',
      birthCountry: 'FR',
      nationality: 'FR',
      size: '6A',
      shoeSize: '20/28'
  },
  // Case 7
  {
      isMembership: false,
      isChangingClub: false,
      gender: 'woman',
      birthYear: 2019,
      lastname: 'Case 7',
      firstname: 'Jane',
      birthDate: '01/01/2019',
      address: '2 rue du ballon',
      zip: '94360',
      email: 'test@b2mfutsal.fr',
      phoneMobile: '0612345678',
      birthTown: 'Saint-Maur',
      birthCountry: 'FR',
      nationality: 'FR',
      size: '6A',
      shoeSize: '20/28'
  },
  // Case 8
  {
      isMembership: true,
      gender: 'man',
      birthYear: 2018,
      lastname: 'Almeida',
      firstname: 'André',
      birthDate: '17/10/2018'
  },
  // Case 9
  {
      isMembership: false,
      isChangingClub: false,
      gender: 'man',
      birthYear: 2018,
      lastname: 'Case 9',
      firstname: 'John',
      birthDate: '01/01/2018',
      address: '2 rue du ballon',
      zip: '94360',
      email: 'test@b2mfutsal.fr',
      phoneMobile: '0612345678',
      birthTown: 'Saint-Maur',
      birthCountry: 'FR',
      nationality: 'FR',
      size: '6A',
      shoeSize: '28/32'
  },
  // Case 10
  {
      isMembership: false,
      isChangingClub: false,
      gender: 'woman',
      birthYear: 2018,
      lastname: 'Case 10',
      firstname: 'Jane',
      birthDate: '01/01/2018',
      address: '2 rue du ballon',
      zip: '94360',
      email: 'test@b2mfutsal.fr',
      phoneMobile: '0612345678',
      birthTown: 'Saint-Maur',
      birthCountry: 'FR',
      nationality: 'FR',
      size: '6A',
      shoeSize: '28/32'
  },
  // Case 11
  {
      isMembership: true,
      gender: 'man',
      birthYear: 2017,
      lastname: 'Benayoun',
      firstname: 'Eden',
      birthDate: '02/02/2017'
  },
  // Case 12
  {
    isMembership: false,
    isChangingClub: false,
    gender: 'man',
    birthYear: 2017,
    lastname: 'Case 12',
    firstname: 'John',
    birthDate: '01/01/2017',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    size: '6A',
    shoeSize: '28/32'
},
// Case 13
{
    isMembership: false,
    isChangingClub: false,
    gender: 'woman',
    birthYear: 2017,
    lastname: 'Case 13',
    firstname: 'Jane',
    birthDate: '01/01/2017',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    size: '6A',
    shoeSize: '28/32'
},
  // Case 14
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2016,
    lastname: 'Bertho',
    firstname: 'Evan',
    birthDate: '24/01/2016'
},
  // Case 15
  {
    isMembership: false,
    isChangingClub: false,
    gender: 'man',
    birthYear: 2016,
    lastname: 'Case 15',
    firstname: 'John',
    birthDate: '01/01/2016',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    size: '6A',
    shoeSize: '28/32'
},
// Case 16
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2016,
  lastname: 'Case 16',
  firstname: 'Jane',
  birthDate: '01/01/2016',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: '6A',
  shoeSize: '28/32'
},
  // Case 17
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2015,
    lastname: 'Marandici',
    firstname: 'Vlad',
    birthDate: '07/12/2015'
},
  // Case 18
  {
    isMembership: false,
    isChangingClub: false,
    gender: 'man',
    birthYear: 2015,
    lastname: 'Case 18',
    firstname: 'John',
    birthDate: '01/01/2015',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    size: '6A',
    shoeSize: '28/32'
},
// Case 19
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2015,
  lastname: 'Case 19',
  firstname: 'Jane',
  birthDate: '01/01/2015',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: '8A',
  shoeSize: '33/36'
},
  // Case 20
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2014,
    lastname: 'Bley',
    firstname: 'Noam',
    birthDate: '27/01/2014'
},
  // Case 21
  {
    isMembership: false,
    isChangingClub: false,
    gender: 'man',
    birthYear: 2014,
    lastname: 'Case 21',
    firstname: 'John',
    birthDate: '01/01/2014',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    size: '10A',
    shoeSize: '33/36'
},
// Case 22
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2014,
  lastname: 'Case 22',
  firstname: 'Jane',
  birthDate: '01/01/2014',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: '10A',
  shoeSize: '33/36'
},
  // Case 23
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2013,
    lastname: 'Donnadieu',
    firstname: 'Sandro',
    birthDate: '29/12/2013'
},
  // Case 24
  {
    isMembership: false,
    isChangingClub: false,
    gender: 'man',
    birthYear: 2013,
    lastname: 'Case 24',
    firstname: 'John',
    birthDate: '01/01/2013',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    size: '12A',
    shoeSize: '37/40'
},
// Case 25
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2013,
  lastname: 'Case 25',
  firstname: 'Jane',
  birthDate: '01/01/2013',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: '12A',
  shoeSize: '37/40'
},
  // Case 26
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2012,
    lastname: 'Fernandes',
    firstname: 'Lucas',
    birthDate: '26/01/2012'
},
  // Case 27
  {
    isMembership: false,
    isChangingClub: false,
    gender: 'man',
    birthYear: 2012,
    lastname: 'Case 27',
    firstname: 'John',
    birthDate: '01/01/2012',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    size: '14A',
    shoeSize: '37/40'
},
// Case 28
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2012,
  lastname: 'Case 28',
  firstname: 'Jane',
  birthDate: '01/01/2012',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: '14A',
  shoeSize: '37/40'
},
  // Case 29
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2011,
    lastname: 'Bocci',
    firstname: 'Emil',
    birthDate: '21/09/2011'
},
  // Case 30
  {
    isMembership: false,
    isChangingClub: false,
    gender: 'man',
    birthYear: 2011,
    lastname: 'Case 30',
    firstname: 'John',
    birthDate: '01/01/2011',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    size: 'S',
    shoeSize: '41/44'
},
// Case 31
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2011,
  lastname: 'Case 31',
  firstname: 'Jane',
  birthDate: '01/01/2011',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: '14A',
  shoeSize: '37/40'
},
  // Case 32
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2010,
    lastname: 'Segal',
    firstname: 'Alexandre',
    birthDate: '24/01/2010'
},
  // Case 33
  {
    isMembership: false,
    isChangingClub: false,
    gender: 'man',
    birthYear: 2010,
    lastname: 'Case 33',
    firstname: 'John',
    birthDate: '01/01/2010',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    size: 'S',
    shoeSize: '41/44'
},
// Case 34
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2010,
  lastname: 'Case 34',
  firstname: 'Jane',
  birthDate: '01/01/2010',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: 'S',
  shoeSize: '37/40'
},
  // Case 35
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2009,
    lastname: 'Froc',
    firstname: 'Cyril',
    birthDate: '28/01/2009'
},
  // Case 36
  {
    isMembership: false,
    isChangingClub: false,
    gender: 'man',
    birthYear: 2009,
    lastname: 'Case 36',
    firstname: 'John',
    birthDate: '01/01/2009',
    address: '2 rue du ballon',
    zip: '94360',
    email: 'test@b2mfutsal.fr',
    phoneMobile: '0612345678',
    birthTown: 'Saint-Maur',
    birthCountry: 'FR',
    nationality: 'FR',
    size: 'S',
    shoeSize: '41/44'
},
// Case 37
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2009,
  lastname: 'Case 37',
  firstname: 'Jane',
  birthDate: '01/01/2009',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: 'S',
  shoeSize: '37/40'
},
  // Case 38
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2008,
    lastname: 'Leclercq',
    firstname: 'Kerian',
    birthDate: '03/01/2008'
},
 // Case 39
 {
  isMembership: false,
  isChangingClub: false,
  gender: 'man',
  birthYear: 2008,
  lastname: 'Case 39',
  firstname: 'John',
  birthDate: '01/01/2008',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: 'M',
  shoeSize: '41/44'
},
// Case 40
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2008,
  lastname: 'Case 40',
  firstname: 'Jane',
  birthDate: '01/01/2008',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: 'S',
  shoeSize: '37/40'
},
  // Case 41
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2007,
    lastname: 'Terral',
    firstname: 'Guillaume',
    birthDate: '09/11/2007'
},
 // Case 42
 {
  isMembership: false,
  isChangingClub: false,
  gender: 'man',
  birthYear: 2007,
  lastname: 'Case 42',
  firstname: 'John',
  birthDate: '01/01/2007',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: 'M',
  shoeSize: '41/44'
},
// Case 43
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2007,
  lastname: 'Case 43',
  firstname: 'Jane',
  birthDate: '01/01/2007',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: 'M',
  shoeSize: '37/40'
},
  // Case 44
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2006,
    lastname: 'Eggel--Cougniot',
    firstname: 'Jules',
    birthDate: '28/12/2006'
},
 // Case 45
 {
  isMembership: false,
  isChangingClub: false,
  gender: 'man',
  birthYear: 2006,
  lastname: 'Case 45',
  firstname: 'John',
  birthDate: '01/01/2006',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: 'L',
  shoeSize: '41/44'
},
// Case 46
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2006,
  lastname: 'Case 46',
  firstname: 'Jane',
  birthDate: '01/01/2006',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: 'L',
  shoeSize: '37/40'
},
  // Case 47
  {
    isMembership: true,
    gender: 'man',
    birthYear: 2005,
    lastname: 'Fradet',
    firstname: 'Adrien',
    birthDate: '19/07/2005'
},
 // Case 48
 {
  isMembership: false,
  isChangingClub: false,
  gender: 'man',
  birthYear: 2005,
  lastname: 'Case 48',
  firstname: 'John',
  birthDate: '01/01/2005',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: 'L',
  shoeSize: '41/44'
},
// Case 49
{
  isMembership: false,
  isChangingClub: false,
  gender: 'woman',
  birthYear: 2005,
  lastname: 'Case 49',
  firstname: 'Jane',
  birthDate: '01/01/2005',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: 'L',
  shoeSize: '37/40'
},
  // Case 50
  {
    isMembership: true,
    gender: 'man',
    birthYear: 1980,
    lastname: 'WOLFF',
    firstname: 'Jean-François',
    birthDate: '07/08/1980'
},
 // Case 51
 {
  isMembership: false,
  isChangingClub: false,
  gender: 'man',
  birthYear: 1980,
  lastname: 'Case 51',
  firstname: 'John',
  birthDate: '01/01/1980',
  address: '2 rue du ballon',
  zip: '94360',
  email: 'test@b2mfutsal.fr',
  phoneMobile: '0612345678',
  birthTown: 'Saint-Maur',
  birthCountry: 'FR',
  nationality: 'FR',
  size: 'XL',
  shoeSize: '45/48'
},
]

export const MEMBER_TEST = null; //arrayMembers[2];
