import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MenuService } from 'src/app/services/menu.service';
import { Menu } from 'src/app/models/menu.model';

@Component({
  selector: 'b2m-banner-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {

  public display: boolean = true;
  menuItem: Menu;

  constructor(
    private menuService: MenuService,
    location: Location,
    router: Router
  ) {
    this.menuItem = new Menu();
    router.events.subscribe(val => {
      this.menuItem = this.menuService.setFlat(this.menuService.getMenus())
        .filter(item => item.route === location.path()).shift();

      this.display = this.menuItem.bannerSrc !== null ? true : false;

    });
  }

  ngOnInit(): void {
  }

}
