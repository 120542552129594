import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let contentTypeReq = request;
    if (request.method === 'POST' || request.method === 'PUT') {
      const headers = request.headers
      .set('Content-Type', 'application/json');
      contentTypeReq = request.clone({
        headers: headers
      });
    }
    return next.handle(contentTypeReq);
  }
}
