<!-- team-draw.component.html -->
<div class="team-draw-container">
  <h2 class="title1">Génération aléatoire d'Équipes</h2>
  <form [formGroup]="drawForm" (ngSubmit)="generateTeams()">

      <label for="numberOfTeams" class="label">Sélectionner les équipes :</label>
      <div class="colors-container">
        <div *ngFor="let teamColor of teamColors; let i = index" class="color-circle" (click)="toggleSelection(i)" [class.selected]="teamColor.selected" [title]="teamColor.name">
          <i *ngIf="teamColor.color !== null" class="fas fa-tint" [ngStyle]="{'color': teamColor.color}"></i>
          <i *ngIf="teamColor.color === null" class="fas fa-tint-slash" style="color: rgb(97, 96, 96);"></i>
        </div>

      </div>

      <br>
      <label for="playersText" class="label">Nom des joueurs (un par ligne):</label>
      <textarea formControlName="playersText" class="textarea" rows="6"></textarea>
      <br>
      <button type="submit" class="button">Générer les équipes</button>
  </form>

  <div id="result" *ngIf="teams.length > 0">
    <div *ngFor="let team of teams; let i = index">
      <strong>Équipe {{ team.color }}:</strong> {{team.members.join(", ")}}
    </div>
  </div>

</div>
