import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encodeURI',
})
export class EncodeURIPipe implements PipeTransform {
  transform(input: any) {
    if (typeof input !== 'string') {
      return input;
    }

    return encodeURI(input);
  }
}