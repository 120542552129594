import { Component, Input } from '@angular/core';
import { FormStep } from '../../models/form-step.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'b2m-step',
  templateUrl: './step.component.html',
  styleUrls: [
    './step.component.scss'
  ]
})
export class StepComponent {
  @Input() step: FormStep;
  @Input() formGroup: FormGroup;
  @Input() currentStep: number;
  @Input() totalSteps: number;
}
