<div class="container">
    <div class="row">
        <div class="d-flex justify-content-center flex-wrap block-container">

            <div *ngFor="let block of blockList" class="block" wmAnimate="bounceInDown" speed="slower" aos="1">
                <img [src]="block.src" class="block-item img-fluid">
                <div class="title" [innerHTML]="block.blockTitle" [style.color]="block.blockColor !== undefined ? block.blockColor : 'white'"></div>
                <div class="btn-more" 
                [style.color]="block.blockColor !== null ? block.blockBgColor : 'blue' "
                [style.background-color]="block.blockColor !== null ? block.blockColor : 'white'">
                    <a *ngIf="block.route !== null; else externalLink" [routerLink]="[block.route]">{{block.buttonTitle}}</a>
                    <ng-template #externalLink><a [href]="block.link" target="_blank">{{block.buttonTitle}}</a></ng-template>
                </div>
            </div>
        </div>
    </div>
</div>