/* tslint:disable */
/* eslint-disable */
export class ApiJsonld {
  '@context'?: (string | {
'@vocab': string;
'hydra': 'http://www.w3.org/ns/hydra/core#';
[key: string]: any;
});
  '@id'?: string;
  '@type'?: string;
  category?: (string | null);
  clubMember?: (string | null);
  country?: (string | null);
  data?: any;
  dataProvider?: string;
  dataType?: string;
  externalLabel?: string;
  externalReference?: string;
  id?: number;
  season?: (string | null);
}

