<div class="title1">
    <span class="title-blue">Tombola </span><span class="title-red">2022/2023</span>
</div>

<div class="container">
    <div class="col-xs-12">
        <div class="d-flex justify-content-center flex-wrap block-container">
            <div *ngFor="let block of blockList" class="block" wmAnimate="bounceInDown" speed="slower" aos="1">
                <div class="content">
                    <div class="description">{{block.line1}}</div>
                    <div class="description">{{block.line2}}</div>
                </div>
            </div>
        </div>
    </div>

    <hr class="violet-hr">

    <div class="col-xs-12">
        <div class="px-8 py-5">
            <div class="text-center title2">Liste de gagnants</div>
        </div>
        <div class="card-body">
            <div class="table-responsive-sm">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>N° Lot</th>
                            <th>Lot</th>
                            <th>N° Gagant</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of csvData">
                            <td>{{ row.id }}</td>
                            <td>{{ row.name }}</td>
                            <td>{{ row.winnerNumber }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
