<div *ngIf="display" id="inscription" class="grey_bg">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 match_height2 membership_bg">
            </div>
            <div class="col-sm-6 match_height2" align="left" wmAnimate="fadeInLeft" speed="fast" aos replay>
                <div class="banner_title title-red">INSCRIPTION 2023/2024</div>
                <div class="banner_txt dark_txt">B2M Futsal vous propose de vivre le Futsal intensément.
                    Renouvellement ou première licence, soyez attentifs aux dates d’ouverture des
                    pré-inscriptions en ligne !</div>
                <div style="margin-bottom: 35px;">
                    <!--<span class="white_btn">Prochainement</span>-->
                    <a [routerLink]="['/membership']" class="red_btn">&gt; S'inscrire</a>
                </div>
            </div>
        </div>
    </div>
</div>
