import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
//import { AnimateModule } from '@wizdm/animate';

import { SharedModule } from 'src/app/shared/shared.module';
import { InternalRulesComponent } from './components/internal-rules/internal-rules.component';
import { ClubComponent } from './components/club/club.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ClubComponent,
    InternalRulesComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    //AnimateModule,
    RouterModule
  ]
})
export class ClubModule { }
