import { Menu } from '../models/menu.model';

const defaultBlockColor: string = '#fff';
const altBlockColor: string = '#203b57';

export const MENU: Menu[] = [
    {
        menuTitle: "Accueil",
        blockTitle: null,
        buttonTitle: null,
        chapter: null,
        route: "/home",
        link: null,
        src: null,
        scrollLink: false,
        navLink: false,
        active: false,
        menuColor: null,
        blockColor: null,
        blockBgColor: null,
        orderNavBar: 0,
        orderSlideMenu: 1,
        orderBlock: 1,
        orderSitemap: 0,
        subItems: null
    },
    {
      menuTitle: "Le club",
      blockTitle: 'NOTRE CLUB<br>B2M FUTSAL',
      buttonTitle: 'EN SAVOIR PLUS',
      chapter: "B2M FUTSAL",
      link: null,
      route: "/club",
      src: 'assets/images/homepage/bloc-Club.jpg',
      bannerSrc: 'assets/images/banners/Header_Club_001.jpg',
      scrollLink: false,
      navLink: false,
      active: false,
      menuColor: null,
      blockColor: defaultBlockColor,
      blockBgColor: altBlockColor,
      orderNavBar:0,
      orderSlideMenu: 3,
      orderBlock: 1,
      orderSitemap: 1,
      subItems: null
  },
    {
        icon: 'fas fa-users',
        menuTitle: "Staff & académie",
        blockTitle: 'STAFF & ACADEMIE',
        buttonTitle: 'EN SAVOIR PLUS',
        chapter: "B2M FUTSAL",
        link: null,
        route: "/staff",
        src: 'assets/images/homepage/bloc-Staff_Academie.jpg',
        bannerSrc: 'assets/images/banners/Header_Academy_001.jpg',
        scrollLink: false,
        navLink: true,
        active: false,
        menuColor: null,
        blockColor: defaultBlockColor,
        blockBgColor: altBlockColor,
        orderNavBar: 1,
        orderSlideMenu: 4,
        orderBlock: 3,
        orderSitemap: 3,
        subItems: null
    },
    {
        menuTitle: "Les équipes",
        blockTitle: 'TOUTES NOS EQUIPES',
        buttonTitle: 'EN SAVOIR PLUS',
        chapter: "B2M FUTSAL",
        link: null,
        route: "/teams",
        src: 'assets/images/homepage/bloc-Equipes.jpg',
        bannerSrc: 'assets/images/banners/Header_Equipes_001.jpg',
        scrollLink: false,
        navLink: false,
        active: false,
        menuColor: null,
        blockColor: defaultBlockColor,
        blockBgColor: altBlockColor,
        orderNavBar:0,
        orderSlideMenu: 7,
        orderBlock: 2,
        orderSitemap: 4,
        subItems: null
    },
    {
        icon: 'fas fa-running',
        menuTitle: "Training & Futsal",
        blockTitle: 'TRAINING & FUTSAL',
        buttonTitle: 'EN SAVOIR PLUS',
        chapter: "B2M FUTSAL",
        link: null,
        route: "/training",
        src: 'assets/images/homepage/bloc-Entrainement.jpg',
        bannerSrc: 'assets/images/banners/Header_Entrainements_001.jpg',
        scrollLink: false,
        navLink: true,
        active: false,
        menuColor: null,
        blockColor: defaultBlockColor,
        blockBgColor: altBlockColor,
        orderNavBar: 0,
        orderSlideMenu: 6,
        orderBlock: 4,
        orderSitemap: 5,
        subItems: null
    },
    {
      icon: 'fas fa-running',
      menuTitle: "Planning Hebdo",
      blockTitle: 'PLANNING HEBDO',
      buttonTitle: 'EN SAVOIR PLUS',
      chapter: "B2M FUTSAL",
      link: null,
      route: "/planning",
      src: 'assets/images/homepage/bloc-Entrainement.jpg',
      bannerSrc: 'assets/images/banners/Header_Entrainements_001.jpg',
      scrollLink: false,
      navLink: true,
      active: false,
      menuColor: null,
      blockColor: defaultBlockColor,
      blockBgColor: altBlockColor,
      orderNavBar: 2,
      orderSlideMenu: 5,
      orderBlock: 4,
      orderSitemap: 5,
      subItems: null
    },
    {
      menuTitle: null,
      blockTitle: null,
      buttonTitle: null,
      chapter: null,
      link: null,
      route: "/planning",
      src: null,
      bannerSrc: 'assets/images/banners/Header_Entrainements_001.jpg',
      scrollLink: false,
      navLink: true,
      active: false,
      menuColor: null,
      blockColor: defaultBlockColor,
      blockBgColor: altBlockColor,
      orderNavBar: 2,
      orderSlideMenu: 5,
      orderBlock: 4,
      orderSitemap: 5,
      subItems: null
  },
    {
        icon: 'fas fa-futbol',
        menuTitle: "Les stadiums",
        blockTitle: 'LES STADIUMS',
        buttonTitle: 'EN SAVOIR PLUS',
        chapter: "B2M FUTSAL",
        link: null,
        route: "/stadiums",
        src: 'assets/images/homepage/bloc-Stadiums.jpg',
        bannerSrc: 'assets/images/banners/Header_Stadium_001.jpg',
        scrollLink: false,
        navLink: true,
        active: false,
        menuColor: null,
        blockColor: defaultBlockColor,
        blockBgColor: altBlockColor,
        orderNavBar: 3,
        orderSlideMenu: 6,
        orderBlock: 9,
        orderSitemap: 6,
        subItems: null
    },
    {
        icon: 'fas fa-envelope',
        menuTitle: "CONTACT",
        blockTitle: null,
        buttonTitle: null,
        chapter: null,
        route: "contact",
        link: null,
        src: null,
        scrollLink: true,
        navLink: true,
        active: false,
        menuColor: null,
        blockColor: null,
        blockBgColor: null,
        orderNavBar: 4,
        orderSlideMenu: 10,
        orderBlock:0,
        orderSitemap: 0,
        subItems: null
    },
    {
        icon: 'fas fa-user-plus',
        menuTitle: "Inscription",
        blockTitle: null,
        buttonTitle: null,
        chapter: "SERVICES",
        route: "/membership",
        link: null,
        src: null,
        scrollLink: false,
        navLink: true,
        active: false,
        menuColor: 'red',
        blockColor: null,
        blockBgColor: null,
        orderNavBar: 5,
        orderSlideMenu: 2,
        orderBlock:0,
        orderSitemap: 10,
        subItems: null
    },
    {
        menuTitle: "B2M Partners",
        blockTitle: 'B2M PARTNERS',
        buttonTitle: 'EN SAVOIR PLUS',
        chapter: "B2M FAMILY",
        link: null,
        route: "/partners",
        src: 'assets/images/homepage/bloc-Partners.jpg',
        bannerSrc: 'assets/images/banners/Header_Partners_001.jpg',
        scrollLink: false,
        navLink: false,
        active: false,
        menuColor: null,
        blockColor: altBlockColor,
        blockBgColor: defaultBlockColor,
        orderNavBar:0,
        orderSlideMenu: 9,
        orderBlock: 5,
        orderSitemap: 1,
        subItems: null
    },
    {
        menuTitle: "B2M Project",
        blockTitle: 'B2M PROJECT',
        buttonTitle: 'EN SAVOIR PLUS',
        chapter: "B2M FAMILY",
        link: null,
        //route: "/project",
        route: "/project/association",
        src: 'assets/images/homepage/bloc-B2MProject.jpg',
        bannerSrc: 'assets/images/banners/Header_Project_001.jpg',
        scrollLink: false,
        navLink: false,
        active: false,
        menuColor: null,
        blockColor: altBlockColor,
        blockBgColor: defaultBlockColor,
        orderNavBar:0,
        orderSlideMenu: 8,
        orderBlock: 6,
        orderSitemap: 2,
        subItems: null
    },
    {
        menuTitle: "B2M TV",
        blockTitle: 'B2M TV',
        buttonTitle: 'DECOUVRIR',
        chapter: "B2M FAMILY",
        route: null,
        link: 'https://www.youtube.com/channel/UC6PGIB_obMoE01pbQs7GP0g/featured',
        src: 'assets/images/homepage/bloc-B2MTV.jpg',
        scrollLink: false,
        navLink: false,
        active: false,
        menuColor: null,
        blockColor: altBlockColor,
        blockBgColor: defaultBlockColor,
        orderNavBar:0,
        orderSlideMenu:0,
        orderBlock: 8,
        orderSitemap: 3,
        subItems: null
    },
    {
        menuTitle: "B2M Store",
        blockTitle: 'B2M STORE',
        buttonTitle: 'DECOUVRIR',
        chapter: "SERVICES",
        route: null,
        link: 'https://boutique.b2mfutsal.fr',
        src: 'assets/images/homepage/bloc-B2MStore.jpg',
        scrollLink: false,
        navLink: false,
        active: false,
        menuColor: null,
        blockColor: altBlockColor,
        blockBgColor: defaultBlockColor,
        orderNavBar:0,
        orderSlideMenu:0,
        orderBlock: 7,
        orderSitemap: 11,
        subItems: null
    },
    {
        menuTitle: null,
        chapter: null,
        blockTitle: 'SC BRAGA',
        buttonTitle: 'EN SAVOIR PLUS',
        src: 'assets/images/homepage/bloc-SCBraga.jpg',
        bannerSrc: 'assets/images/banners/Header_Braga_001.jpg',
        route: '/braga',
        link: null,
        scrollLink: true,
        navLink: true,
        active: false,
        menuColor: null,
        blockColor: defaultBlockColor,
        blockBgColor: altBlockColor,
        orderNavBar:0,
        orderSlideMenu:0,
        orderBlock: 10,
        orderSitemap: 0,
        subItems: null
    },
    {
        menuTitle: null,
        chapter: null,
        blockTitle: 'EVENTS',
        buttonTitle: 'EN SAVOIR PLUS',
        src:'assets/images/homepage/bloc-Event.jpg',
        bannerSrc: 'assets/images/banners/Header_Event_001.jpg',
        //route: '/events',
        route: '/tombola',
        link: null,
        scrollLink: true,
        navLink: true,
        active: false,
        menuColor: null,
        blockColor: defaultBlockColor,
        blockBgColor: altBlockColor,
        orderNavBar:0,
        orderSlideMenu:0,
        orderBlock: 11,
        orderSitemap: 0,
        subItems: null
    },
    {
        menuTitle: 'Baby Futsal',
        chapter: null,
        blockTitle: 'BABY FUTSAL',
        buttonTitle: 'BIENTÔT DISPONIBLE',
        src:'assets/images/homepage/bloc-Baby.jpg',
        bannerSrc: 'assets/images/banners/Header_Baby_001B.jpg',
        route: '/baby',
        link: null,
        scrollLink: true,
        navLink: false,
        active: false,
        menuColor: null,
        blockColor: defaultBlockColor,
        blockBgColor: altBlockColor,
        orderNavBar:0,
        orderSlideMenu:0,
        orderBlock: 12,
        orderSitemap: 12,
        subItems: null
    },
    {
      menuTitle: "Générateur Équipes",
      blockTitle: null,
      buttonTitle: null,
      chapter: "SERVICES",
      route: "/team-generator",
      src: null,
      bannerSrc: null,
      link: null,
      scrollLink: false,
      navLink: false,
      active: false,
      menuColor: null,
      blockColor: defaultBlockColor,
      blockBgColor: altBlockColor,
      orderNavBar: 6, // Adjust based on your navigation bar order
      orderSlideMenu: 0, // Adjust based on your slide menu order
      orderBlock: 13, // Adjust based on the order in which you want this block to appear
      orderSitemap: 13, // Adjust based on your sitemap order
      subItems: null
    }
    /*{
      icon: 'fas fa-calendar',
      menuTitle: "Calendrier",
      blockTitle: null,
      buttonTitle: null,
      chapter: "SERVICES",
      route: "/calendar",
      link: null,
      src: null,
      scrollLink: false,
      navLink: false,
      active: false,
      menuColor: 'red',
      blockColor: null,
      blockBgColor: null,
      orderNavBar: 0,
      orderSlideMenu: 3,
      orderBlock:0,
      orderSitemap: 11,
      subItems: null
  },*/
  /*{
      icon: null,
      menuTitle: "Paramétrage",
      blockTitle: null,
      buttonTitle: null,
      chapter: "SERVICES",
      route: "/registration-settings",
      link: null,
      src: null,
      scrollLink: false,
      navLink: false,
      active: false,
      menuColor: 'red',
      blockColor: null,
      blockBgColor: null,
      orderNavBar: 0,
      orderSlideMenu: 4,
      orderBlock:0,
      orderSitemap: 12,
      subItems: null
  },*/
];
