import { Person } from '../models/person.model';

export const STAFF: Person[] = [
  {
    name: 'Marylène DARBONNENS',
    thumbmail: 'assets/images/staff/mdarbonnens.jpg',
    roles: [
      //'Arbitre'
      'Séniors Féminines'
    ],
    isCollapsed: false,
  },
  /*{
    name: 'Soufien OUMHAMDI',
    thumbmail: 'assets/images/staff/soumhamdi.jpg',
    roles: [
      'Arbitre'
    ],
    isCollapsed: false,
  },*/
  {
    name: 'Jérôme ROBERT',
    thumbmail: 'assets/images/staff/jrobert.jpg',
    roles: [
      'Directeur Technique'
    ],
    isCollapsed: false,
  },
  {
    name: 'Laurent VALERO',
    thumbmail: 'assets/images/staff/lvalero.jpg',
    roles: [
      'U11-U12 Ouest',
      'U9-U10 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Bruno SIMÕES DA SILVA',
    thumbmail: 'assets/images/staff/bsimoes.jpg',
    roles: [
      'U9-U10 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Francis BLEY',
    thumbmail: 'assets/images/staff/fbley.jpg',
    roles: [
      'U9-U10 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Thomas DONNADIEU',
    thumbmail: 'assets/images/staff/tdonnadieu.jpg',
    roles: [
      'U11-U12 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Samy LAIMY',
    thumbmail: 'assets/images/staff/slaimy.jpg',
    roles: [
      'U11-U12 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Cédric DARBONNENS',
    thumbmail: 'assets/images/staff/cdarbonnens.jpg',
    roles: [
      'Fémiteens U14F-U16F',
      'Fémikids',
      'Baby U4/U5/U6 Est'
    ],
    isCollapsed: false,
  },
  {
    name: 'Eden M\'BIKI MEZI',
    thumbmail: 'assets/images/staff/embikimezi.jpg',
    roles: [
      'U7-U8 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Maceo CABANETOS',
    thumbmail: 'assets/images/staff/mcabanetos.jpg',
    roles: [
      'U7-U8 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Jasmin HASIC',
    thumbmail: 'assets/images/staff/jhasic.jpg',
    roles: [
      'U13-U14 Est/Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Matteo BAGHINO',
    thumbmail: 'assets/images/staff/mbaghino.jpg',
    roles: [
      'U11-U12 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Marco FERNANDES',
    thumbmail: 'assets/images/staff/mfernandes.jpg',
    roles: [
      'U11-U12 Ouest',
      'Fémiteens U14F-U16F'
    ],
    isCollapsed: false,
  },
  {
    name: 'David ALMEIDA',
    thumbmail: 'assets/images/staff/dalmeida.jpg',
    roles: [
      'U11-U12 Ouest',
      'Baby Futsal U6 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Sébastien MARAIS',
    thumbmail: 'assets/images/staff/smarais.jpg',
    roles: [
      'U15-U16',
    ],
    isCollapsed: false,
  },
  {
    name: 'Nicolas PAOLINELLI',
    roles: [
      'Tournois Internationaux'
    ],
    isCollapsed: false,
  },
  {
    name: 'Yanis AIT-AKLI',
    thumbmail: 'assets/images/staff/yaitakli.jpg',
    roles: [
      'Séniors Féminines',
      'U17-U18',
      'U11-U12 Est'
    ],
    isCollapsed: false,
  },
  {
    name: 'Dominique BLEY',
    thumbmail: 'assets/images/staff/dbley.jpg',
    roles: [
      'Séniors Masculins',
      'Fémiteens U14F-U16F',
      'Baby U4/U5/U6 Est/Ouest',
      'U9-U10 Est',
      'FitFut'
    ],
    isCollapsed: false,
  },
  {
    name: 'Djibril DIALLO',
    thumbmail: 'assets/images/staff/ddiallo.jpg',
    roles: [
      'U17/U18',
      'U9/U10 Est',
      'U7/U8 Est'
    ],
    isCollapsed: false,
  },
  {
    name: 'Manuel NUNES',
    thumbmail: 'assets/images/staff/mnunes.jpg',
    roles: [
      'Gardiens de Buts'
    ],
    isCollapsed: false,
  },
  {
    name: 'Dominique LEMOINE',
    thumbmail: 'assets/images/staff/dlemoine.jpg',
    roles: [
      'Gardiens de Buts',
      'U9-U10 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Maxence BUREAU',
    thumbmail: 'assets/images/staff/mbureau.jpg',
    roles: [
      'Gardiens de Buts'
    ],
    isCollapsed: false,
  },
  {
    name: 'Miguel CORREIA',
    thumbmail: 'assets/images/staff/mcorreia.jpg',
    roles: [
      'Gardiens de Buts'
    ],
    isCollapsed: false,
  },
  {
    name: 'Jules EGGEL--COUGNIOT',
    thumbmail: 'assets/images/staff/jeggelcougniot.jpg',
    roles: [
      'Gardiens de Buts'
    ],
    isCollapsed: false,
  },
  {
    name: 'Leonel NKOUAMENI',
    thumbmail: 'assets/images/staff/lnkouameni.jpg',
    roles: [
      'Séniors Masculins 2',
      'U9/U10 Est'
    ],
    isCollapsed: false,
  },
  {
    name: 'Marc BODIOT',
    thumbmail: 'assets/images/staff/mbodiot.jpg',
    roles: [
      'U17/U18'
    ],
    isCollapsed: false,
  },
  {
    name: 'Anthony FRADET',
    thumbmail: 'assets/images/staff/afradet.jpg',
    roles: [
      'U7/U8 Est'
    ],
    isCollapsed: false,
  },
  {
    name: 'Abdelkader MORJAN',
    thumbmail: 'assets/images/staff/amorjan.jpg',
    roles: [
      'U9/U10 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Yazid ZEROUK',
    thumbmail: 'assets/images/staff/yzerouk.jpg',
    roles: [
      'U9/U10 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Anna CARVALHO',
    thumbmail: 'assets/images/staff/acarvalho.jpg',
    roles: [
      'Fémikids'
    ],
    isCollapsed: false,
  },
  {
    name: 'Yannick VALENTIN',
    thumbmail: 'assets/images/staff/yvalentin.jpg',
    roles: [
      'Séniors Masculins'
    ],
    isCollapsed: false,
  },
  {
    name: 'Guillaume BRUSSET',
    thumbmail: 'assets/images/staff/gbrusset.jpg',
    roles: [
      'Loisirs Masculins'
    ],
    isCollapsed: false,
  },
  {
    name: 'Jérôme LEFBEVRE',
    thumbmail: 'assets/images/staff/jlefebvre.jpg',
    roles: [
      'Séniors Masculins'
    ],
    isCollapsed: false,
  },
  {
    name: 'Dimitri ALLOT',
    roles: [
      'U13/U14 Est/Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Frank BAMBA',
    roles: [
      'Séniors Féminines'
    ],
    isCollapsed: false,
  },
  {
    name: 'Sébastien CLAUDE',
    roles: [
      'Fémiteens U11F-U13F'
    ],
    isCollapsed: false,
  },
  {
    name: 'Matthieu FRANCOIS',
    roles: [
      'Fémiteens U11F-U13F'
    ],
    isCollapsed: false,
  },
  {
    name: 'Gérald FRADET',
    thumbmail: 'assets/images/staff/gfradet.jpg',
    roles: [
      'U7-U8 Est'
    ],
    isCollapsed: false,
  },
  {
    name: 'Florent CRUZ',
    roles: [
      'Fémikids'
    ],
    isCollapsed: false,
  },
  {
    name: 'Edwige FROC',
    roles: [
      'Baby U4/U5/U6 Ouest'
    ],
    isCollapsed: false,
  },
  {
    name: 'Franck BUREAU',
    thumbmail: 'assets/images/staff/fbureau.jpg',
    roles: [
      'U17/U18'
    ],
    isCollapsed: false,
  },
  {
    name: 'Enthoumana SAMASSA',
    thumbmail: 'assets/images/staff/esamassa.jpg',
    roles: [
      'U17/U18'
    ],
    isCollapsed: false,
  },
];

// Fonction de tri personnalisée
function triMixteRoleNom(a: Person, b: Person): number {
  // Comparez d'abord les rôles
  const rolesComparison = a.roles[0].localeCompare(b.roles[0]);
  // Si les rôles sont différents, retournez le résultat de la comparaison des rôles
  if (rolesComparison !== 0) {
    return rolesComparison;
  }
  // Si les rôles sont les mêmes, comparez les noms
  return a.name.localeCompare(b.name);
}

// Tri mixte par rôle puis par nom
STAFF.sort(triMixteRoleNom);
