<div class="category-selector">
  <div class="category-card"
       *ngFor="let category of categories"
       (click)="toggleSelection(category)"
       [class.selected]="isSelected(category)">
    <div class="category-header" [style.backgroundColor]="category.color">
      <span class="category-gender">
        <i [ngClass]="getIcon(category.gender)"></i>
      </span>
      <span class="category-name">{{ category.label }}</span>
    </div>
    <div *ngIf="category.isComplete" class="category-full-indicator">
      <div class="full-text">Complet</div>
      <div class="waitlist-instruction">En sélectionnant cette catégorie, vous serez placé sur sa liste d'attente.</div>
    </div>
    <ng-container *ngIf="!category.isComplete">
      <ng-container *ngFor="let rule of category.rules">
        <ng-container [ngSwitch]="rule">
          <div *ngIf="rule!=='all_new'" [ngClass]="rule" class="rule-indicator" [style.backgroundColor]="getRuleColor(rule)">
            <span class="full-text">{{ getRuleTitle(rule) }}</span>
            <p *ngSwitchCase="'suspended'">Inscriptions suspendues en attendant la mise à disposition du gymnase</p>
            <p *ngSwitchCase="'closed'">Inscriptions fermées pour le moment</p>
            <p *ngSwitchCase="'towns_new'">Inscriptions réservées pour le moment aux habitants de Bry, Neuilly-Plaisance, Noisy-le-Grand et Le Perreux.</p>
            <p *ngSwitchCase="'category_renew'">Inscriptions réservées pour le moment aux renouvellements uniquement</p>
            <p *ngSwitchCase="'staff_see'">L’inscription ne sera confirmée qu'à l'issue d'une séance d'essai.</p>
            <p *ngSwitchCase="'previous_renew'">Réservé aux renouvellements pour le moment.</p>
            <p *ngSwitchCase="'antena_renew'">Réservé aux renouvellements au sein du même secteur (rouge/bleu).</p>
            <p *ngSwitchCase="'sibling'">Réservé aux membres d'un foyer dont un membre était déjà adhérent la saison précédente.</p>
            <p *ngSwitchCase="'waiting_list'">Réservé aux personnes de la liste d'attente de la saison précédente.</p>
            <p *ngSwitchCase="'staff_validation'">L’inscription est soumise à validation par le staff technique à l'issue d'un essai.</p>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <!--<div *ngFor="let joinPeriod of category.joinPeriods">{{joinPeriod.joinRules}</div>-->
    <ng-container *ngIf="allSlotsAreDraft(category); else slotsDefined">
      <div class="warning">Les créneaux ne sont pas encore définis pour le moment.</div>
    </ng-container>
    <ng-template #slotsDefined>
      <div class="category-content">
          <h3 class="training-title">Horaires d'entraînement hebdomadaire</h3>
          <div *ngFor="let trainingSlot of category.trainingSlots" class="category-details">
            <div class="category-schedule">
              <span class="weekday">{{ trainingSlot.weekday }}</span>
              <span class="time">{{ trainingSlot.startTime }} à {{ trainingSlot.endTime }}</span>
              <span class="category-gym" [matTooltip]="trainingSlot.venue.address">{{ trainingSlot.venue.shortName }}<span class="gym-city">({{ trainingSlot.venue.town }})</span></span>
            </div>
          </div>
      </div>
      <div *ngIf="category?.minimumAttendanceRate" class="warning">
        <p>Pour garantir une inscription automatique la saison suivante, le joueur doit respecter un taux de présence d'au moins {{category.minimumAttendanceRate}} % sur l'ensemble de la saison.</p>
      </div>
    </ng-template>
    <div *ngIf="category.gender === 'mixed' && memberGender === 'woman'" class="mixed-category-info">
      <i [ngClass]="getIcon(category.gender)"></i><p> Catégorie mixte </p>
    </div>
    <div *ngIf="isLowerReclassification(category)" class="mixed-category-info">
      <p>Sous-classement autorisé dans cette catégorie pour une joueuse née en {{memberBirthYear}}</p>
    </div>
    <div class="category-note" *ngIf="field.note">
      <small>{{ field.note }}</small>
    </div>
  </div>
</div>
