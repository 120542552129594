import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'b2m-raffle',
  templateUrl: './raffle.component.html',
  styleUrls: ['./raffle.component.scss']
})
export class RaffleComponent implements OnInit {

  blockList: any[] = [
    {
      line1: "Lancement de la tombola",
      line2: "12/11/2022",
    },
    {
      line1: "3750 tickets en vente"
    },
    {
      line1: "1575 tickets vendus"
    },
    {
      line1: "Tirage au sort",
      line2: "11/12/2022",
    }
  ];
  
  prizes: Array<{id: number, name: String, winnerNumber: number}> = [];
  csvData;

  constructor(
    private _http: HttpClient,
    private _papa: Papa) {
  }

  ngOnInit(): void {
    this._http.get('assets/tombola.csv', { responseType: 'text' })
    .subscribe(
      data => {
        this.parseCsvFile(data);
      },
      error => {
        console.log('File assets/tombola.csv not exists');
      }
    );
  }

  parseCsvFile(file) {
    this._papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: 'greedy',
      worker: true,
      complete: this.papaParseCompleteFunction
    });
  }
  
  papaParseCompleteFunction = (results) => {
    this.csvData = results.data;
  }
  
}
