import { Component, OnInit } from '@angular/core';
import { trigger, animate, style, transition, useAnimation } from '@angular/animations';

@Component({
  selector: 'b2m-partners',
  templateUrl: './partners.component.html',
  styles: [
  ]
})
export class PartnersComponent implements OnInit {

  blocks: Array<any> = [
    {
      title: {
        title1: 'les collectivités locales'
      },
      button: {
        label: null,
        website: null
      },
      partners: [
        {
          name: 'LE PERREUX-SUR-MARNE',
          since: 2006,
          website: 'https://www.leperreux94.fr/',
          imageSrc: 'assets/images/partners/logo_le_perreux.jpeg'
        },
        {
          name: 'BRY-SUR-MARNE',
          since: 2011,
          website: 'https://www.brysurmarne.fr/',
          imageSrc: 'assets/images/partners/Logo-Bry-partenaire-couleur-inverse.png'
        },
        {
          name: 'NEUILLY-PLAISANCE',
          since: 2018,
          website: 'https://www.mairie-neuillyplaisance.com/',
          imageSrc: 'assets/images/partners/logo_neuilly_plaisance.jpeg'
        },
        {
          name: 'NOISY-LE-GRAND',
          since: 2023,
          website: 'https://www.noisylegrand.fr/',
          imageSrc: 'assets/images/partners/mairie-de-noisy-le-grand-collectivite-locale-noisy-le-grand.png'
        }
      ]
    },
    {
      title: {
        title1: 'les institutions'
      },
      button: {
        label: null,
        website: null
      },
      partners: [
        {
          name: 'Ligue de Paris-Ile-de-France de Football',
          since: null,
          website: 'https://paris-idf.fff.fr/',
          imageSrc: 'assets/images/partners/LPIFF_Logo_RB_Carre_20.jpg'
        },
        {
          name: 'Fédération Française de Football',
          since: null,
          website: 'https://www.fff.fr/',
          imageSrc: 'assets/images/partners/37218423_2230689070305145_6841979023896084480_n-3.png'
        },
        {
          name: 'District du Val-de-Marne de de Football',
          since: null,
          website: 'https://districtvaldemarne.fff.fr/',
          imageSrc: 'assets/images/partners/logo_district94.jpeg'
        }
      ]
    },
    {
      title: {
        title1: 'Equipementier et partenaires privés'
      },
      button: {
        label: null,
        website: null
      },
      partners: [
        {
          name: 'Skita',
          since: 2010,
          website: 'https://www.skita.fr',
          imageSrc: 'assets/images/partners/skita.png'
        },
        {
          name: 'Moreno 2000',
          since: 2023,
          website: 'https://www.agence-moreno2000.com',
          imageSrc: 'assets/images/partners/moreno2000.jpeg'
        },
        {
          name: 'Jean Lefebvre',
          since: 2023,
          website: 'https://www.ejl-idf.fr',
          imageSrc: 'assets/images/partners/jean_lefebvre_idf.jpeg'
        },
        {
          name: 'L’Instant Argile',
          since: 2020,
          website: 'https://www.linstantargile.com',
          imageSrc: 'assets/images/partners/linstant_argile.png'
        },
        {
          name: 'Nazzaro',
          since: 2023,
          website: 'https://www.nazzarofrank.com',
          imageSrc: 'assets/images/partners/logo_nazzaro.png'
        },
        {
          name: 'BLM',
          since: 2023,
          imageSrc: 'assets/images/partners/no-image-e260aa637493656a3ab41f5027d1d343.jpg'
        },
        {
          name: 'HWP',
          since: 2023,
          imageSrc: 'assets/images/partners/no-image-e260aa637493656a3ab41f5027d1d343.jpg'
        }
      ]
    },
    {
      title: {
        title1: 'PARTENARIAT CLUB'
      },
      button: {
        label: 'EN SAVOIR PLUS',
        website: ['/braga']
      },
      partners: [
        {
          name: 'SC BRAGA',
          since: 2020,
          website: 'https://scbraga.pt',
          description: 'Découvrez notre partenariat de futsal exceptionnel avec le prestigieux club \
            professionnel portugais, le SC Braga. Cette collaboration inédite marque un événement majeur \
            pour un club de futsal français, renforçant notre engagement envers le développement et \
            l\'excellence dans ce sport passionnant.',
          imageSrc: 'assets/images/partners/Partners_SCBraga.jpg',
          imageWidth: '750px'
        }
      ]
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
