import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HateoasTransformService {

  transformLink<T>(link: string, service: { getByUUID: (uuid: string, headerXTransactionId: string) => Observable<T> }, headerXTransactionId: string): Observable<T> {
    const uuid = link.split('/').pop(); // Extract UUID from the link
    return service.getByUUID(uuid!, headerXTransactionId);
  }

  transformLinks<T>(links: string[], service: { getByUUID: (uuid: string, headerXTransactionId: string) => Observable<T> }, headerXTransactionId: string): Observable<T[]> {
    const observables$ = links.map(link => this.transformLink(link, service, headerXTransactionId));
    return forkJoin(observables$);
  }

  getUUID(link: string): string {
    return link.split('/').pop();
  }
}
