import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'b2m-banner-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  public display: boolean;

  constructor(location: Location, router: Router) {
    router.events.subscribe(val => {
      this.display = (location.path() === '/home') ? true : false;
    });  
  }

  ngOnInit(): void {
  }

}
