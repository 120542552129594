<div *ngIf="display" class="marine_bg">
  <div class="container">
      <div class="row">
          <div class="col-sm-6 match_height1" align="right" wmAnimate="fadeInRight" speed="fast" aos replay>
              <div class="banner_title white_txt">BOUTIQUE B2M FUTSAL</div>
              <div class="banner_txt white_txt">Pour la première fois depuis sa création, B2M Futsal a décidé de proposer à ses adhérents, un accès direct aux équipements du club, mais aussi quelques produits dérivés estampillés B2M Futsal</div>
              <div style="margin-bottom: 35px;">
                  <a href= "https://boutique.b2mfutsal.fr" target="_blank" rel="noopener noreferrer" class="white_btn">&gt; Accéder à la boutique</a>
              </div>
          </div>
          <div class="col-sm-6 match_height1 store_bg"></div>
      </div>
  </div>
</div>
