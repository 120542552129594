/* tslint:disable */
/* eslint-disable */
export class ApiExternalData {
  category?: (string | null);
  clubMember?: (string | null);
  country?: (string | null);
  data?: any;
  dataProvider?: string;
  dataType?: string;
  externalLabel?: string;
  externalReference?: string;
  id?: number;
  season?: (string | null);
}

