import { Component, Input, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'b2m-date-picker',
  templateUrl: './date-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent  implements ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control = new FormControl();
  @Input() appearance: string;
  @Input() startView: string;
  @Input() startAt: Date;
  @Input() required: boolean;

  // Internal data model
  private innerValue: any;

  // Placeholders for the callbacks which are later provided by the Control Value Accessor
  private onChange: (value: any) => void;
  private onTouched: () => void;

  // Get accessor
  get value(): any {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChange(v);
    }
  }

  // ControlValueAccessor interface methods below

  // Set touched on blur
  onBlur() {
    this.onTouched();
  }

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    this.innerValue = value;
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
  }

  _closeCalendar(picker: MatDatepicker<Date>) {
    picker.close();
  }
}
