<div *ngIf="display" class="grey_bg">
	<div class="container">
		<div class="row">
			<div class="col-sm-6 match_height1" align="right" wmAnimate="fadeInRight" speed="fast" aos replay>
				<div class="banner_title marine_txt">DESTINATION UAE</div>
				<div class="banner_txt dark_txt">Découvrez la présentation de notre incroyable aventure !<br>
					Né d’un simple pari, Destination UAE, notre projet sportif<br>et éducatif est aujourd’hui aux portes
					de la réussite !
				</div>
			</div>
			<div class="col-sm-6 match_height1 uae_bg"></div>
		</div>
	</div>
</div>
