import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { trigger, animate, style, transition, useAnimation } from '@angular/animations';

@Component({
  selector: 'b2m-banner-uae',
  templateUrl: './banner-uae.component.html',
  styleUrls: ['./banner-uae.component.scss']
})
export class BannerUaeComponent implements OnInit {

  public display: boolean = true;

  activePages = {
    "active" : true,
    "pages" : ['/project/association']
  };

  constructor(location: Location, router: Router) {
    router.events.subscribe(val => {
      if (this.activePages.active) {
        this.display = this.activePages.pages.filter((page) => {
          return location.path() === page
        }).length === 1 ? true : false;
      } else {
        this.display = this.activePages.pages.filter((page) => {
          return location.path() === page
        }).length === 0 ? true : false;
      }
    });
  }

  ngOnInit(): void {
  }

}
