import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { PublicRoutingModule } from './public-routing.module';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { RegisterModule } from './register/register.module';
import { MembershipModule } from './membership/membership.module';
import { TrainingModule } from './training/training.module';
import { ClubModule } from './club/club.module';
import { StadiumsModule } from './stadiums/stadiums.module';
import { TeamsModule } from './teams/teams.module';
import { ProjectModule } from './project/project.module';
import { StaffModule } from './staff/staff.module';
import { PartnersModule } from './partners/partners.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from './calendar/calendar.module';
import { EventsModule } from './events/events.module';
import { TeamDrawComponent } from './team-draw/team-draw.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    TeamDrawComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PublicRoutingModule,
    HomeModule,
    ClubModule,
    LoginModule,
    RegisterModule,
    MembershipModule,
    TrainingModule,
    TeamsModule,
    StadiumsModule,
    ProjectModule,
    StaffModule,
    PartnersModule,
    NgbModule,
    CalendarModule,
    EventsModule,
    ReactiveFormsModule
  ],
  exports: []
})
export class PublicModule { }
