import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Stadium } from 'src/app/models/stadium.model';
import { StadiumService } from 'src/app/services/stadium.service';

@Component({
  selector: 'b2m-stadiums',
  templateUrl: './stadiums.component.html',
  styleUrls: ['./stadiums.component.scss']
})
export class StadiumsComponent implements OnInit {

  private fragment: string;
  stadiums: Array<Stadium>;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _stadiumService: StadiumService
    ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.stadiums = this._stadiumService.getAll();
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }
}
