import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiTrainingSlotService } from '../api/services';
import { ApiCategory } from '../api/models';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrainingSlotService {

  private trainingSlots$: Observable<any[]>;

  constructor(private apiTrainingSlotService: ApiTrainingSlotService) { }

  getTrainingSlots(categories: ApiCategory[], headerXTransactionId: string): Observable<any[]> {
    if (!this.trainingSlots$) {
      this.trainingSlots$ =  this.apiTrainingSlotService.apiV1TrainingSlotsGetCollection(
        {
          'X-Transaction-Id': headerXTransactionId,
          'category[]': categories.map(category => category.uuid)
        }
      ).pipe(
        shareReplay(1)
      );
    }
    return this.trainingSlots$;
  }

  getByUUID(uuid: string): Observable<any> {
    if (!this.trainingSlots$) {
      // If trainingSlots$ has not been initialized yet, return an empty observable or handle accordingly
      return of(null);
    }

    return this.trainingSlots$.pipe(
      map(slots => slots.find(slot => slot.uuid === uuid))
    );
  }
}
