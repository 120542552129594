import { Injectable } from '@angular/core';
import { POLES } from '../data/pole-data';
import { Pole } from '../models/pole.model';

@Injectable({
  providedIn: 'root'
})
export class PoleService {

  constructor() { }

  getPoles(): Pole[] {
    return POLES;
  }
}
