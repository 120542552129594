import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiCategory } from 'src/app/api/models';
import { FormField } from '../../models/form-field.model';
import { FormGroup } from '@angular/forms';
import { JoinPeriodService } from 'src/app/services/join-period.service';

@Component({
  selector: 'b2m-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss']
})
export class CategorySelectorComponent implements OnInit {
  @Input() field: FormField;
  @Input() formGroup: FormGroup;
  @Input() multiple: boolean = false;
  @Output() selectionChange = new EventEmitter<ApiCategory[]>();

  selectedCategories: ApiCategory[] = [];
  categories: ApiCategory[] = [];
  memberGender: string;
  memberBirthYear: number;

  constructor(private joinPeriodService: JoinPeriodService) { }

  ngOnInit(): void {
    this.memberGender = this.field.additionalData.gender;
    this.memberBirthYear = this.field.additionalData.birthYear;

    // Get Rules from joinPeriods
    this.categories = this.field.options.map(category => {
      return {
        ...category,
        rules: this.mapRules(category)
      }
    });

    this.selectDefaultCategory();
    this.sortCategories();

    // Écouter les changements de valeur dans formGroup
    if (this.formGroup && this.formGroup.get(this.field.name)) {
      this.formGroup.get(this.field.name).valueChanges.subscribe(value => {
        // Logique pour mettre à jour selectedCategories ou autres actions
      });
    }
  }

  selectDefaultCategory(): void {
    if (this.categories && this.categories.length === 1) {
      this.selectedCategories.push(this.categories[0]);
      this.selectionChange.emit(this.selectedCategories);
    }
  }

  sortCategories() {
    this.categories.sort((a, b) => {
      if (a.gender === this.memberGender && b.gender !== this.memberGender) {
        return -1;
      }
      if (a.gender !== this.memberGender && b.gender === this.memberGender) {
        return 1;
      }

      if (!this.isLowerReclassification(a) && this.isLowerReclassification(b)) {
        return -1;
      }
      if (this.isLowerReclassification(a) && !this.isLowerReclassification(b)) {
        return 1;
      }

      return 0;
    });
  }

  toggleSelection(category: ApiCategory) {
    if (this.multiple) {
      const index = this.selectedCategories.indexOf(category);
      if (index === -1) {
        this.selectedCategories.push(category);
      } else {
        this.selectedCategories.splice(index, 1);
      }
    } else {
      if (this.isSelected(category)) {
        this.selectedCategories = [];
      } else {
        this.selectedCategories = [category];
      }
    }
    this.selectionChange.emit(this.selectedCategories);
  }

  isSelected(category: ApiCategory): boolean {
    return this.selectedCategories.includes(category);
  }

  isLowerReclassification(category: ApiCategory) {
    return category.gender === 'mixed' && this.memberGender === 'woman' && this.memberBirthYear < category.minYear;
  }

  getIcon(gender: string) {
    switch (gender) {
      case 'man':
        return 'fas fa-mars';
      case 'woman':
        return 'fas fa-venus';
      default:
        return 'fas fa-venus-mars';
    }
  }

  joinWaitlist(category: any) {
    // Logic to join the waitlist
    alert(`Vous avez choisi de vous inscrire sur la liste d'attente pour ${category.label}`);
  }

  mapRules(category: any) {
    return category.joinPeriods
      .filter(joinPeriod => this.joinPeriodService.isJoinPeriodNow(joinPeriod))
      .map(joinPeriod => joinPeriod.joinRule.code);
  }

  allSlotsAreDraft(category: any): boolean {
    if (!category.trainingSlots || category.trainingSlots.length === 0) {
      return false;
    }
    return category.trainingSlots.every(slot => slot.status === 'draft');
  }

  getRuleColor(ruleCode: string): string {
    switch (ruleCode) {
      case 'suspended':
        return '#FF6347'; // Tomato
      case 'towns_new':
        return '#FFD700'; // Gold
      case 'category_renew':
        return '#32CD32'; // LimeGreen
      case 'staff_see':
        return '#1E90FF'; // DodgerBlue
      case 'all_new':
        return '#ADFF2F'; // GreenYellow
      case 'closed':
        return '#808080'; // Grey
      case 'previous_renew':
        return '#8B0000'; // DarkRed
      case 'antena_renew':
        return '#DA70D6'; // Orchid
      case 'sibling':
        return '#FF69B4'; // HotPink
      case 'waiting_list':
        return '#FF4500'; // OrangeRed
      case 'staff_validation':
        return '#4682B4'; // SteelBlue
      default:
        return '#FFFFFF'; // White
    }
  }

  getRuleTitle(ruleCode: string): string {
    switch (ruleCode) {
      case 'suspended':
        return 'Inscriptions suspendues';
      case 'towns_new':
        return 'Réservé aux habitants des villes';
      case 'category_renew':
        return 'Renouvellement de catégorie';
      case 'staff_see':
        return 'Validation après séance d\'essai';
      case 'closed':
        return 'Inscriptions fermées';
      case 'previous_renew':
        return 'Renouvellement précédent';
      case 'antena_renew':
        return 'Renouvellement antenne';
      case 'sibling':
        return 'Réservé aux frères et sœurs';
      case 'waiting_list':
        return 'Liste d\'attente';
      case 'staff_validation':
        return 'Validation par le staff';
      default:
        return 'Règle d\'inscription';
    }
  }
}
