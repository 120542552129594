export class Poster {

    name?: string;
    posterImg?: string;
    role?: string;
    competition? : string;
    coach?: string;
    assistantCoachs?: string;
    isCollapsed: boolean = false;
    description?: string;
    resultLink?: string;

    constructor(obj: any) {
        this.isCollapsed = false;
        Object.assign(this, obj);
    }
}
