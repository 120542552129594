import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'b2m-unavailable',
  templateUrl: './unavailable.component.html',
  styleUrls: ['./unavailable.component.scss']
})
export class UnavailableComponent implements OnInit {
  @Input() errorCode: string = ''; // Input to accept error code
  @Input() errorMessage: string = ''; // Input to accept error message
  ticketsPortalUrl: string = '';

  ngOnInit() {
    this.ticketsPortalUrl = environment.dolibarrUrl + "/htdocs/public/ticket/index.php";
    if (!this.errorCode) {
      this.errorCode = '999';
    }

    if (!this.errorMessage) {
      this.errorMessage = 'Une erreur inconnue est survenue.';
    }
  }
}
