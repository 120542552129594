import { Stadium } from '../models/stadium.model';

export const STADIUMS: Stadium[] = [
    {
        code: 'cdbm',
        name: 'Centre des Bords de Marne',
        img: 'assets/images/stadiums/cdbm.jpg',
        address: '2 Rue de la Prairie, 94170, Le Perreux-sur-Marne',
    },
    {
        code: 'lesormes',
        name: 'Gymnase des Ormes',
        img: 'assets/images/stadiums/lesormes.jpg',
        address: '51 Allée des Ormes, 94170 , Le Perreux-sur-Marne',
    },
    {
        code: 'felixfaure',
        name: 'Gymnase Félix Faure',
        img: 'assets/images/stadiums/felixfaure.jpg',
        address: '11 Rue Félix Faure, 94360, Bry-sur-Marne',
    },
    /*{
        code: 'clemenceau',
        name: 'Gymnase Clémenceau',
        img: 'assets/images/stadiums/clemenceau.jpg',
        address: '11 av Georges Clemenceau, 94360, Bry-sur-Marne',
    },*/
    {
        code: 'malf',
        name: 'Gymnase Marie-Amélie Le Fur',
        img: 'assets/images/stadiums/malf.jpg',
        address: '3 Rue du Clos Sainte-Catherine, 94360 Bry-sur-Marne',
    },
    {
        code: 'saluden',
        name: 'Gymnase Claude Saluden',
        img: 'assets/images/stadiums/saluden.jpg',
        address: '8 rue Paul Letombe, 93360 Neuilly-Plaisance',
    },
    {
      code: 'bobet',
      name: 'Gymnase Louison Bobet',
      img: 'assets/images/stadiums/bobet.jpg',
      address: '7 Rue de l\'Université, 93160 Noisy-le-Grand',
    },
    {
      code: 'cosec',
      name: 'Gymnase Marcel Cerdan',
      img: 'assets/images/stadiums/cosec.jpg',
      address: '3 All. des Hautes Plaines, 93160 Noisy-le-Grand',
    },
    {
      code: 'herriot',
      name: 'Gymnase Edouard Herriot',
      img: 'assets/images/stadiums/edouardherriot.jpg',
      address: '10 rue Aristide Briand, 93360 Neuilly-Plaisance',
    }
]
