import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UaeComponent } from './components/uae/uae.component';
import { AssociationComponent } from './components/association/association.component';
//import { AnimateModule } from '@wizdm/animate';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [UaeComponent, AssociationComponent],
  imports: [
    CommonModule,
    //AnimateModule,
    SharedModule
  ]
})
export class ProjectModule { }
