import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { TrainingComponent } from './components/training/training.component';
import { PlanningComponent } from './components/planning/planning.component';

@NgModule({
  declarations: [TrainingComponent, PlanningComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule
  ],
  exports: [TrainingComponent],
  bootstrap: [ TrainingComponent ]
})
export class TrainingModule { }
