import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { HomeComponent } from './public/home/components/home/home.component';
import { ClubComponent } from './public/club/components/club/club.component';
import { InternalRulesComponent } from './public/club/components/internal-rules/internal-rules.component';
import { StaffComponent } from './public/staff/components/staff/staff.component';
import { TeamsComponent } from './public/teams/components/teams/teams.component';
import { TrainingComponent } from './public/training/components/training/training.component';
import { PlanningComponent } from './public/training/components/planning/planning.component';
import { StadiumsComponent } from './public/stadiums/components/stadiums/stadiums.component';
import { PartnersComponent } from './public/partners/components/partners/partners.component';
import { MembershipComponent } from './public/membership/membership.component';
import { AssociationComponent } from './public/project/components/association/association.component';
import { UaeComponent } from './public/project/components/uae/uae.component';
import { NewsComponent } from './shared/components/news/news.component';
import { ScbragaComponent } from './public/partners/components/scbraga/scbraga.component';
//import { CalendarComponent } from './public/calendar/components/calendar/calendar.component';
import { RaffleComponent } from './public/events/components/raffle/raffle.component';
import { RegistrationSettingsComponent } from './authenticated/registration-settings/registration-settings.component';
import { TeamDrawComponent } from './public/team-draw/team-draw.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  initialNavigation: 'enabledNonBlocking'
};

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'club',
    component: ClubComponent
  },
  {
    path: 'internal-rules',
    component: InternalRulesComponent
  },
  {
    path: 'staff',
    component: StaffComponent
  },
  {
    path: 'teams',
    component: TeamsComponent
  },
  {
    path: 'training',
    component: TrainingComponent
  },
  {
    path: 'planning',
    component: PlanningComponent
  },
  {
    path: 'stadiums',
    component: StadiumsComponent
  },
  {
    path: 'partners',
    component: PartnersComponent
  },
  {
    path: 'project/association',
    component: AssociationComponent
  },
  {
    path: 'project/uae',
    component: UaeComponent
  },
  { path: 'news', component: NewsComponent },
  { path: 'membership', component: MembershipComponent },
  { path: 'webview/membership', component: MembershipComponent },
  { path: 'braga', component: ScbragaComponent },
  //{ path: 'calendar', component: CalendarComponent },
  { path: 'registration-settings', component: RegistrationSettingsComponent },
  { path: 'tombola', component: RaffleComponent },
  { path: 'team-generator', component: TeamDrawComponent },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
