/* tslint:disable */
/* eslint-disable */
export class ApiDuesRate {
  createdAt?: string;
  id?: number;
  inScopeTown?: boolean;
  ranking?: number;
  rate?: number;
  updatedAt?: string;
  uuid?: string;
}

