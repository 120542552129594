import { PoleService } from './../../../../services/pole.service';
import { Component, OnInit } from '@angular/core';
import { Pole } from 'src/app/models/pole.model';


@Component({
  selector: 'b2m-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  blockList: Pole[];

  constructor(private poleService: PoleService) {}

  ngOnInit(): void {
    this.blockList = this.poleService.getPoles();
  }

}
