/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1CategoriesGetCollection } from '../fn/category/api-v-1-categories-get-collection';
import { ApiV1CategoriesGetCollection$Params } from '../fn/category/api-v-1-categories-get-collection';
import { apiV1CategoriesPost } from '../fn/category/api-v-1-categories-post';
import { ApiV1CategoriesPost$Params } from '../fn/category/api-v-1-categories-post';
import { apiV1CategoriesUuidDelete } from '../fn/category/api-v-1-categories-uuid-delete';
import { ApiV1CategoriesUuidDelete$Params } from '../fn/category/api-v-1-categories-uuid-delete';
import { apiV1CategoriesUuidGet } from '../fn/category/api-v-1-categories-uuid-get';
import { ApiV1CategoriesUuidGet$Params } from '../fn/category/api-v-1-categories-uuid-get';
import { apiV1CategoriesUuidPatch } from '../fn/category/api-v-1-categories-uuid-patch';
import { ApiV1CategoriesUuidPatch$Params } from '../fn/category/api-v-1-categories-uuid-patch';
import { apiV1CategoriesUuidPut } from '../fn/category/api-v-1-categories-uuid-put';
import { ApiV1CategoriesUuidPut$Params } from '../fn/category/api-v-1-categories-uuid-put';
import { ApiCategory } from '../models/api-category';

@Injectable({ providedIn: 'root' })
export class ApiCategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1CategoriesGetCollection()` */
  static readonly ApiV1CategoriesGetCollectionPath = '/v1/categories';

  /**
   * Retrieves the collection of Category resources.
   *
   * Retrieves the collection of Category resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CategoriesGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CategoriesGetCollection$Response(params?: ApiV1CategoriesGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiCategory>>> {
    return apiV1CategoriesGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Category resources.
   *
   * Retrieves the collection of Category resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CategoriesGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CategoriesGetCollection(params?: ApiV1CategoriesGetCollection$Params, context?: HttpContext): Observable<Array<ApiCategory>> {
    return this.apiV1CategoriesGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiCategory>>): Array<ApiCategory> => r.body)
    );
  }

  /** Path part for operation `apiV1CategoriesPost()` */
  static readonly ApiV1CategoriesPostPath = '/v1/categories';

  /**
   * Creates a Category resource.
   *
   * Creates a Category resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CategoriesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CategoriesPost$Response(params: ApiV1CategoriesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCategory>> {
    return apiV1CategoriesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Category resource.
   *
   * Creates a Category resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CategoriesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CategoriesPost(params: ApiV1CategoriesPost$Params, context?: HttpContext): Observable<ApiCategory> {
    return this.apiV1CategoriesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCategory>): ApiCategory => r.body)
    );
  }

  /** Path part for operation `apiV1CategoriesUuidGet()` */
  static readonly ApiV1CategoriesUuidGetPath = '/v1/categories/{uuid}';

  /**
   * Retrieves a Category resource.
   *
   * Retrieves a Category resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CategoriesUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CategoriesUuidGet$Response(params: ApiV1CategoriesUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCategory>> {
    return apiV1CategoriesUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Category resource.
   *
   * Retrieves a Category resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CategoriesUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CategoriesUuidGet(params: ApiV1CategoriesUuidGet$Params, context?: HttpContext): Observable<ApiCategory> {
    return this.apiV1CategoriesUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCategory>): ApiCategory => r.body)
    );
  }

  /** Path part for operation `apiV1CategoriesUuidPut()` */
  static readonly ApiV1CategoriesUuidPutPath = '/v1/categories/{uuid}';

  /**
   * Replaces the Category resource.
   *
   * Replaces the Category resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CategoriesUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CategoriesUuidPut$Response(params: ApiV1CategoriesUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCategory>> {
    return apiV1CategoriesUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Category resource.
   *
   * Replaces the Category resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CategoriesUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CategoriesUuidPut(params: ApiV1CategoriesUuidPut$Params, context?: HttpContext): Observable<ApiCategory> {
    return this.apiV1CategoriesUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCategory>): ApiCategory => r.body)
    );
  }

  /** Path part for operation `apiV1CategoriesUuidDelete()` */
  static readonly ApiV1CategoriesUuidDeletePath = '/v1/categories/{uuid}';

  /**
   * Removes the Category resource.
   *
   * Removes the Category resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CategoriesUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CategoriesUuidDelete$Response(params: ApiV1CategoriesUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1CategoriesUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Category resource.
   *
   * Removes the Category resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CategoriesUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CategoriesUuidDelete(params: ApiV1CategoriesUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1CategoriesUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1CategoriesUuidPatch()` */
  static readonly ApiV1CategoriesUuidPatchPath = '/v1/categories/{uuid}';

  /**
   * Updates the Category resource.
   *
   * Updates the Category resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CategoriesUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1CategoriesUuidPatch$Response(params: ApiV1CategoriesUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCategory>> {
    return apiV1CategoriesUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the Category resource.
   *
   * Updates the Category resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CategoriesUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1CategoriesUuidPatch(params: ApiV1CategoriesUuidPatch$Params, context?: HttpContext): Observable<ApiCategory> {
    return this.apiV1CategoriesUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCategory>): ApiCategory => r.body)
    );
  }

}
