import { Component, OnInit } from '@angular/core';
import { trigger, animate, style, transition, useAnimation } from '@angular/animations';

class OrgData {
  id: number;
  name: string;
  thumbmail: string;
  posterImg: string;
  role: string;
  isCollapsed: boolean;
  description: string;
}

@Component({
  selector: 'b2m-association',
  templateUrl: './association.component.html',
  styleUrls: ['./association.component.scss']
})
export class AssociationComponent implements OnInit {

  boardTitle: string = "les membres du pôle";
  boardArray: Array<OrgData> = [
    {
      id: 1,
      name: 'Christophe GILLET',
      thumbmail: 'assets/images/b2mproject/lassociation/association_Christophe.jpg',
      posterImg: null,
      role: 'Co-Fondateur',
      isCollapsed: false,
      description: null
    },
    {
      id: 2,
      name: 'Pascal GUILLEMIN',
      thumbmail: 'assets/images/b2mproject/lassociation/association_Pascal.jpg',
      posterImg: null,
      role: 'Co-Fondateur',
      isCollapsed: false,
      description: null
    },
    {
      id: 3,
      name: 'François POUZET',
      thumbmail: 'assets/images/b2mproject/lassociation/association_Francois.jpg',
      posterImg: null,
      role: 'Trésorier',
      isCollapsed: false,
      description: null
    },
    {
      id: 4,
      name: 'Marylène DARBONNENS',
      thumbmail: 'assets/images/b2mproject/lassociation/thumb_m_darbonnens.jpg',
      posterImg: null,
      role: 'Membre',
      isCollapsed: false,
      description: null
    },
    {
      id: 5,
      name: 'Marco FERNANDES',
      thumbmail: 'assets/images/codir/thumb_m_fernandes.jpg',
      posterImg: null,
      role: 'Membre',
      isCollapsed: false,
      description: null
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
