import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ViewportScroller, isPlatformBrowser } from '@angular/common';
import  packageInfo from '../../package.json';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { RollbarService } from './services/rollbar.service';

@Component({
  selector: 'b2m-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent {
  public version: string = packageInfo.version;
  public title: string = 'b2mfutsal-www';
  public displayOnWebView: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private location: Location,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private rollbar: RollbarService
  ) {
    if (location.path() === '/webview/membership') {
      this.displayOnWebView = true;
    }

        // Écoute des événements de fin de navigation
        this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
          // Défilement vers le haut de la page
          this.viewportScroller.scrollToPosition([0,0]);
        });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const tag = document.createElement('script');
      tag.src = "https://connect.facebook.net/fr_FR/sdk.js";
      document.body.appendChild(tag);
    }
  }
}
