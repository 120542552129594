<!-- Champ de saisie du code postal -->
<b2m-input-field
  [control]="form.controls.zip"
  [(value)]="zip"
  (input)="onZipChange()"
  class="form-field"
  [label]="'Code Postal'"
  [placeholder]="'Code Postal'"
  [type]="'text'"
  [required]="true"
  [appearance]="appearance"
/>

<b2m-input-field
  [control]="form.controls.town"
  [(value)]="town"
  class="form-field"
  [label]="'Ville d\'habitation'"
  [placeholder]="'Ville d\'habitation'"
  [type]="'text'"
  [required]="true"
  [appearance]="appearance"
/>
