import { Component, EventEmitter, Input, OnInit, OnChanges, SimpleChanges, Output, AfterViewInit } from '@angular/core';
import { FormStep } from '../../models/form-step.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { dateRangeValidator } from '../../validators/date-range.validator';
import { zipCodeValidator } from '../../validators/zip-code.validator';
import { FieldService } from '../../services/field.service';
import { Subscription } from 'rxjs';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'b2m-membership-form',
  templateUrl: './membership-form.component.html',
  styleUrls: ['./membership-form.component.scss']
})
export class MembershipFormComponent implements OnInit, OnChanges {
  @Input() steps: FormStep[];
  @Output() checkCallbackResultEmitter = new EventEmitter<any>();
  currentStep = 0;
  formGroups: FormGroup[];
  isDirty: boolean[];
  isLoading = false;
  instance: any;
  checkCallbackResult: any;
  public errorMessages: string[] = [];
  isLastStep: boolean = false;
  valueChangesSubscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private fieldService: FieldService,
    private scroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this.initializeFormGroups();
    this.subscribeToValueChanges();
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.steps && !changes.steps.isFirstChange()) {
      this.initializeFormGroups();
    }
  }

  initializeFormGroups(): void {
    this.formGroups = this.steps.map(step => {
      const group = this.fb.group({});
      if (step.fields && step.fields.length > 0) {
        step.fields.forEach(field => {
          this.addFieldToFormGroup(group, field);
          this.fieldService.setField(field);
        });
      }
      group.valueChanges.subscribe(() => {
        this.updateButtonState();
        this.isDirty[this.currentStep] = true;
      });
      return group;
    });
    this.isDirty = this.formGroups.map(() => false);
  }

  subscribeToValueChanges(): void {
    this.formGroups.forEach((group, index) => {
      const currentStep: FormStep = this.steps[index];
      const subscription = group.valueChanges.subscribe(value => {
        if (currentStep && currentStep.onValueChange) {
          currentStep.onValueChange(index, this.formGroups);
        }
      });
      this.valueChangesSubscription.add(subscription);
    });
  }

  addFieldToFormGroup(group: FormGroup, field: any): void {
    const validators = field.validation ? [...field.validation] : [];
    if (field.type === 'date') {
      validators.push(dateRangeValidator(field.minDate, field.maxDate));
    }
    if (field.name === 'zip') {
      validators.push(zipCodeValidator('town'));
    }
    group.addControl(field.name, this.fb.control({value: field.value, disabled: field.disabled}, validators));
  }

  canProceed(): boolean {
    return (
      this.formGroups &&
      this.formGroups[this.currentStep] &&
      this.formGroups[this.currentStep].valid &&
      !this.isLoading &&
      (this.steps[this.currentStep].shouldProceed || this.steps[this.currentStep].shouldProceed === undefined)
    );
  }

  updateButtonState() {
    this.formGroups = [...this.formGroups];
  }

  nextStep() {
    if (this.canProceed() && this.isDirty[this.currentStep]) {
      this.isLoading = true;
      this.isDirty[this.currentStep] = false;
      const currentStepConfig = this.steps[this.currentStep];
      if (currentStepConfig.postStep) {
        currentStepConfig.postStep(this.currentStep, this.formGroups, (shouldProceed?: boolean, message?: string) => {
          this.proceedToNextStep(shouldProceed ?? true, message);
        });
      } else {
        this.proceedToNextStep(!this.isLastStep);
      }
      this.scrollToForm();
    }
  }

  proceedToNextStep(shouldProceed: boolean, message?: string) {
    if (message) {
      this.errorMessages.push(message);
    }

    if (shouldProceed) {
      this.isLoading = false;
      this.currentStep++;
      const nextStepConfig = this.steps[this.currentStep];
      if (nextStepConfig && nextStepConfig.preStep) {
        nextStepConfig.preStep(this.currentStep, this.formGroups, this.updateFormGroup.bind(this));
      }
    }
  }

  getCheckCallbackResult() {
    return this.checkCallbackResult;
  }

  previousStep() {
    this.currentStep--;
  }

  checkIfLastStep(): boolean {
    return this.currentStep === this.steps.length - 1;
  }

  updateFormGroup(stepIndex: number, fields: any[]): void {
    this.steps[stepIndex].fields = fields;
    this.initializeFormGroups();
  }

  scrollToForm(): void {
    this.scroller.scrollToAnchor("topStep");
  }

  refreshPage() {
    window.location.reload();
  }
}
