/* tslint:disable */
/* eslint-disable */
export class ApiJoinPeriod {
  category?: string;
  createdAt?: string;
  endDate?: string;
  id?: number;
  joinRule?: (string | null);
  startDate?: string;
  updatedAt?: string;
  uuid?: string;
}

