/* tslint:disable */
/* eslint-disable */
export class ApiTrainingSlot {
  category?: (string | null);
  createdAt?: string;
  endTime?: string;
  id?: number;
  startTime?: string;
  status?: 'assigned' | 'draft' | 'cancelled';
  updatedAt?: string;
  uuid?: string;
  venue?: string;
  weekday?: string;
}

