<div class="title1">
  <span class="title-blue">Partenariat </span
  ><span class="title-red">SC Braga</span>
</div>

<section>
  <div class="container text-center">
    <div class="intro-text justify">
      Né grâce au réseau développé par B2M Project dans le cadre du projet
      Destination UAE, ce partenariat inédit pour un club de futsal français,
      offre des perspectives intéressantes à B2M Futsal ainsi qu’au SC Braga.
      <br /><br />
      Les valeurs communes des deux clubs et leur volonté uniforme dès les
      premiers contacts, d’évoluer et d’innover ensemble, a rapidement créé une
      émulation de part et d’autres, pour finaliser très vite un protocole
      d’accord.
      <br /><br />
      Ce projet permet à nos coachs d’augmenter leur champ de compétences au contact de spécialistes
      et à nos meilleurs joueurs de pouvoir rejoindre le monde pro. Ensemble nous allons développer <strong>
      des projets et un état esprit toujours plus innovant et créatif au service du futsal.
      <br><br>
      L’histoire entre le SC Braga et B2M Futsal a démarré en octobre 2020</strong>.
    </div>
    <br />
    <i class="title2">Lien utiles :</i><br /><br />
    <strong
      ><a href="https://scbraga.pt/" target="_blank" rel="noopener noreferrer"
        >www.scbraga.pt</a
      >
      /
      <a
        href="https://scbraga.pt/modalidade/futsal/"
        target="_blank"
        rel="noopener noreferrer"
        >www.scbraga.pt/modalidade/futsal/</a
      ></strong
    >
    <br />
    SC Braga :
    <a
      href="https://www.instagram.com/sportingclubedebraga"
      target="_blank"
      rel="noopener noreferrer"
      >Instagram</a
    >
    /
    <a
      href="https://www.facebook.com/SCBragaOficial/"
      target="_blank"
      rel="noopener noreferrer"
      >Facebook</a
    >
    /
    <a
      href="https://twitter.com/scbragaoficial"
      target="_blank"
      rel="noopener noreferrer"
      >Twitter</a
    >
    - Futsal :
    <a
      href="https://www.instagram.com/scbraga_aaum_futsal"
      target="_blank"
      rel="noopener noreferrer"
      >Instagram</a
    >
    /
    <a
      href="https://www.facebook.com/SCBragaModalidadesOficial/"
      target="_blank"
      rel="noopener noreferrer"
      >Facebook</a
    >
    <br /><br />
    <strong
      ><a
        href="http://www.tsconsulting-international.com/"
        target="_blank"
        rel="noopener noreferrer"
        >www.tsconsulting-international.com</a
      ></strong
    >
    <br />
    <a
      href="https://www.instagram.com/tsconsulting"
      target="_blank"
      rel="noopener noreferrer"
      >Instagram</a
    >
    /
    <a
      href="https://www.facebook.com/tsconsulting-100706394953098"
      target="_blank"
      rel="noopener noreferrer"
      >Facebook</a
    >
    /
    <a
      href="https://twitter.com/tsconsultint"
      target="_blank"
      rel="noopener noreferrer"
      >Twitter</a
    >
  </div>
</section>
<hr class="violet-hr" />
<section>
  <div class="container">
<div class="row red_bg white_txt banner">
  <div class="col-12 col-md-5 text-center banner_img">
    <img
      src="assets/images/partners/visu_SCBraga_002.jpg"
      class="img-fluid mx-auto"
    />
  </div>
  <div
    class="cold-12 col-md-7 red_bg txt_banner d-flex flex-column justify-content-center align-items-left"
  >
    <div class="white_txt title1 title_banner">remerciements à :</div>
    <span>
      <strong>Joel Pereira</strong> / Ancien vice-président du SC Braga pour
      son envie immédiate de donner vie à ce projet en 2020. <br /><br />
      <b>Ricardo Vasconcelos</b> / Directeur Général de l’extra football du SC Braga,
      ravi et motivé à l’idée de poursuivre ce partenariat entre nos deux clubs.
      <br /><br />
      <b>Mario Carreiras dit "Marinho"</b> / Directeur et Team Manager
      du Futsal au SC Braga, 92 sélections en équipe nationale du Portugal et
      vainqueur de l’UEFA Futsal Cup en 2010 avec le SL Benfica où évoluait un
      certain Ricardinho. <br /><br />
      <b>TS Consulting International</b> pour nous avoir permis de
      concrétiser ce projet en réunissant les 2 clubs et en nous accompagnant du
      début à la fin de cette aventure. <br /><br />
      <b>B2M Project</b> dont la détermination, la vision et l’esprit
      d’innovation font aujourd’hui de B2M Futsal un club qui ne ressemb  le à aucun
      autre. <br /><br />
    </span>
  </div>
</div>

<br><br>
<div class="w-75 mx-auto ">
<div class="ratio ratio-16x9 gk_video">
  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/8twkZqN5Iu4"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>
</div>
</div>
<br><br>
<div class="w-75 mx-auto ">
<img
src="assets/images/partners/visu_SCBraga_001.jpg"
class="img-fluid"
/>
</div>

</div>
</section>
