import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnersComponent } from './components/partners/partners.component';
//import { AnimateModule } from '@wizdm/animate';
import { BlockPartnersComponent } from './components/block-partners/block-partners.component';
import { RouterModule } from '@angular/router';
import { ScbragaComponent } from './components/scbraga/scbraga.component';

@NgModule({
  declarations: [
    BlockPartnersComponent,
    PartnersComponent,
    ScbragaComponent
  ],
  imports: [
    CommonModule,
    //AnimateModule,
    RouterModule
  ]
})
export class PartnersModule { }
