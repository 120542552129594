import { Injectable } from '@angular/core';
import { MENU } from 'src/app/data/menu-data';
import { Menu } from '../models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor() { }

  getMenus(sort: string = 'orderSlideMenu'): Menu[] {
    // sort by value
    return MENU.sort(function (a, b) {
      return a[sort] - b[sort];
    });
  }

  setFlat(menu: Menu[]): Menu[] {
    return menu.reduce(function (accumulator, item) {
        accumulator.push(item);

      if (item.subItems !== null) {
        accumulator =  accumulator.concat(item.subItems);
      }
      return accumulator;
    },[]);
  }

  activate(item: Menu) {

    MENU.forEach((menuItem) => {
      menuItem.active = false;
    })
    item.active = true;
  }
}
