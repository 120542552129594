import { ApiCategory } from 'src/app/api/models';

export const CATEGORIES = [
    {
        code: "u4",
        shortLabel: "Baby Futsal U4",
        type: "default",
        subCategories: [
          {
            year: "2021",
            label: "U4",
            sex: "male"
          },
          {
            year: "2021",
            label: "U4F",
            sex: "female"
          }
        ],
        manager: {
            name: "Dominique BLEY"
        },
        trainings: [22]
    },
    {
        code: "u5",
        type: "default",
        shortLabel: "Baby Futsal U5",
        subCategories: [
          {
            year: "2020",
            label: "U5",
            sex: "male"
          },
          {
            year: "2020",
            label: "U5F",
            sex: "female"
          }
        ],
        manager: {
            name: "Dominique BLEY"
        },
        trainings: [21]
    },
    {
      code: "u6",
      type: "default",
      shortLabel: "Baby Futsal U6",
      subCategories: [
        {
          year: "2019",
          label: "U6",
          sex: "male"
        },
        {
          year: "2019",
          label: "U6F",
          sex: "female"
        }
      ],
      manager: {
          name: "Dominique BLEY"
      },
      trainings: [23]
  },
    {
        code: "u8",
        type: "default",
        shortLabel: "U7-U8",
        subCategories: [
          {
            year: "2018",
            label: "U7",
            sex: "male"
          },
          {
            year: "2017",
            label: "U8",
            sex: "male"
          },
          {
            year: "2018",
            label: "U7F",
            sex: "female"
          }
          ,
          {
            year: "2017",
            label: "U8F",
            sex: "female"
          }
        ],
        manager: {
            name: "Jérôme ROBERT"
        },
        trainings: [23]
    },
    {
        code: "u10",
        type: "default",
        shortLabel: "U9-U10",
        subCategories: [
          {
            year: "2016",
            label: "U9",
            sex: "male"
          },
          {
            year: "2015",
            label: "U10",
            sex: "male"
          },
          {
            year: "2016",
            label: "U9F",
            sex: "female"
          }
          ,
          {
            year: "2015",
            label: "U10F",
            sex: "female"
          }
        ],
        manager: {
            name: "Laurent VALERO"
        },
        trainings: [5, 17]
    },
    {
        code: "u12",
        type: "default",
        shortLabel: "U11-U12",
        subCategories: [
          {
            year: "2014",
            label: "U11",
            sex: "male"
          },
          {
            year: "2013",
            label: "U12",
            sex: "male"
          },
          {
            year: "2014",
            label: "U11F",
            sex: "female"
          }
          ,
          {
            year: "2013",
            label: "U12F",
            sex: "female"
          }
        ],
        manager: {
            name: "Laurent VALERO"
        },
        trainings: [4, 18]
    },
    {
        code: "u14",
        type: "default",
        shortLabel: "U13-U14",
        subCategories: [
          {
            year: "2012",
            label: "U13",
            sex: "male"
          },
          {
            year: "2011",
            label: "U14",
            sex: "male"
          },
          {
            year: "2012",
            label: "U13F",
            sex: "female"
          }
          ,
          {
            year: "2011",
            label: "U14F",
            sex: "female"
          }
        ],
        manager: {
            name: "Boris NKONG"
        },
        trainings: [8, 11]
    },
    {
        code: "u16",
        type: "default",
        shortLabel: "U15-U16",
        subCategories: [
          {
            year: "2010",
            label: "U15",
            sex: "male"
          },
          {
            year: "2009",
            label: "U16",
            sex: "male"
          }
        ],
        manager: {
            name: "Nicolas PAOLINELLI"
        },
        trainings: [9, 6]
    },
    {
        code: "u18",
        type: "default",
        shortLabel: "U17-U18",
        subCategories: [
          {
            year: "2008",
            label: "U17",
            sex: "male"
          },
          {
            year: "2007",
            label: "U18",
            sex: "male"
          }
        ],
        manager: {
            name: "Jérôme ROBERT"
        },
        trainings: [10, 3]
    },
    {
        code: "seniors",
        type: "default",
        shortLabel: "Séniors Masculins",
        subCategories: [
          {
            year: "2006",
            label: "U19",
            sex: "male"
          },
          {
            year: "2005",
            label: "U20",
            sex: "male"
          },
          {
            year: "2004+",
            label: "Séniors",
            sex: "male"
          }
        ],
        manager: {
            name: "Dominique BLEY"
        },
        trainings: [1, 2]
    },
    {
        code: "femikids",
        type: "female",
        shortLabel: "Fémikids",
        subCategories: [
          {
            year: "2018",
            label: "U7F",
            sex: "female"
          },
          {
            year: "2017",
            label: "U8F",
            sex: "female"
          },
          {
            year: "2016",
            label: "U9F",
            sex: "female"
          },
          {
            year: "2015",
            label: "U10F",
            sex: "female"
          }
        ],
        manager: {
            name: "Cédric DARBONNENS"
        },
        trainings: [15]
    },
    {
        code: "femiteens",
        type: "female",
        shortLabel: "Fémiteens",
        subCategories: [
          {
            year: "2014",
            label: "U11F",
            sex: "female"
          },
          {
            year: "2013",
            label: "U12F",
            sex: "female"
          },
          {
            year: "2012",
            label: "U13F",
            sex: "female"
          }
        ],
        manager: {
            name: "Cédric DARBONNENS"
        },
        trainings: [16]
    },
    {
      code: "u17f",
      type: "female",
      shortLabel: "U14F-U17F",
      subCategories: [
        {
          year: "2011",
          label: "U14F",
          sex: "female"
        },
        {
          year: "2010",
          label: "U15F",
          sex: "female"
        },
        {
          year: "2009",
          label: "U16F",
          sex: "female"
        },
        {
          year: "2008",
          label: "U17F",
          sex: "female"
        }
      ],
      manager: {
          name: "Dominique BLEY"
      },
      trainings: [19]
  },
    {
        code: "feminines",
        type: "female",
        shortLabel: "Séniors Féminines",
        subCategories: [
          {
            year: "2007",
            label: "U18F",
            sex: "female"
          },
          {
            year: "2006+",
            label: "Séniors F",
            sex: "female"
          }
        ],
        manager: {
            name: "Laurent VALERO"
        },
        trainings: [7]
    },
    {
      code: "spegb",
      type: "other",
      shortLabel: "Spécifique GB",
      manager: {
          name: "Manuel NUNES"
      },
      trainings: [14]
  },
    {
        code: "loisirs",
        type: "other",
        shortLabel: "Loisirs",
        subCategories: [
          {
            year: "2004+",
            label: "Séniors",
            sex: "male"
          }
        ],
        manager: {
            name: "Guillaume BRUSSET"
        },
        trainings: [24, 13, 20]
    },
    {
      code: "loisirsanciens",
      type: "other",
      shortLabel: "Loisirs Anciens",
      subCategories: [
        {
          year: "1980+",
          label: "Anciens",
          sex: "male"
        }
      ],
      manager: {
          name: "Guillaume BRUSSET"
      },
      trainings: [24, 13, 20]
  },
    {
        code: "fscem",
        type: "other",
        shortLabel: "FitFut",
        manager: {
            name: "Dominique BLEY"
        },
        trainings: [26, 25]
    }
];
