import { Component, OnChanges, ViewEncapsulation, Input, Output, EventEmitter, ElementRef, AfterViewInit, Pipe, PipeTransform, SimpleChange, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { CommonModule, ViewportScroller } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ClickOutsideDirective } from './clickOutside';
import { Menu } from 'src/app/models/menu.model';

@Component({
  selector: 'b2m-slide-menu',
  templateUrl: './slide-menu.component.html',
  styleUrls: ['./slide-menu.component.scss'],
  animations: [
      trigger('toggleMenu', [
          state('show', style({ height: '*' })),
          state('hide', style({ height: 0 })),
          transition('void => *', [
              style({ height: 0, overflow: 'hidden' })
          ]),
          transition('* => hide', [
              style({ height: '*' }),
              animate(250, style({ height: 0 }))
          ]),
          transition('hide => show', [
              style({ height: 0 }),
              animate(250, style({ height: '*' }))
          ])
      ]),
      trigger('toggleArrow', [
          state('right', style({ transform: 'rotate(0)' })),
          state('down', style({ transform: 'rotate(90deg)' })),
          transition('right <=> down', animate('100ms ease-in'))
      ])
  ]
})
export class SlideMenuComponent implements OnInit {

  @Input() menulist: any;
  menuItems: Menu[];

  @Input() config: any;

  @Output('open')
  open: EventEmitter<number> = new EventEmitter<number>();
  @Output('close')
  close: EventEmitter<number> = new EventEmitter<number>();
  @Output('onItemSelect')
  itemSelect: EventEmitter<number> = new EventEmitter<number>();
  @Input() menuState: boolean;
  changeLog: string[] = [];
  targetElement: any;
  overlayElem: any;
  defaultConfig: any = {
      "animation": "collapse",
      "offset": {
          "top": 68
      },
      closeOnCLick: false
  };
  constructor(
      private _elementRef: ElementRef,
      private sanitizer: DomSanitizer,
      public viewportScroller: ViewportScroller) {

  }

  ngOnInit() {
      this.menuItems = this.menulist.filter(item => item.menuTitle !== null);
      this.menuState === undefined ? false : this.menuState;
      this.config = Object.assign(this.defaultConfig, this.config);
      this.addOverlayElement();
  }

  menuToggle() {
      this.menuState = !this.menuState;
      this.toggleOverlay();
      this.menuState ? this.open.emit() : this.close.emit();
  }

  ngOnChanges() {
    this.menuState === false ? this.closeMenu() : null;
  }

  closeMenu() {
      this.menuState = false;
      this.overlayElem.style['opacity'] = 0;
  }

  currentItem:any;
  onItemClick(item: any) {

      if(this.currentItem){
          this.currentItem.active = this.currentItem.active ? false : true;
      }

      this.currentItem = item;
      item.active = true;
      if (item.subItems) {
          return false;
      }
      else {
          delete item["expand"];
          var obj = Object.assign(item);
          this.itemSelect.emit(obj);
          if (this.config.closeOnCLick) {
              this.closeMenu();
          }
      }


  }
  toggleSubMenu(item: any) {
      if (item.expand) {
          item.expand = item.expand == 'hide' ? 'show' : 'hide';
      }
      else {
          item.expand = 'show';
      }

  }
  addOverlayElement() {
      this.overlayElem = document.createElement('div');
      this.overlayElem.classList.add('cuppa-menu-overlay');
      this.overlayElem.style['position'] = 'fixed';
      this.overlayElem.style['background'] = 'rgba(0, 0, 0, 0.7)';
      this.overlayElem.style['top'] = this.config.offset.top + 'px';
      this.overlayElem.style['left'] = 0;
      this.overlayElem.style['right'] = 0;
      this.overlayElem.style['bottom'] = 0;
      this.overlayElem.style['opacity'] = 0;
      this.overlayElem.style['pointer-events'] = 'none';
      this.overlayElem.style['transition'] = 'all .2s linear';
      document.getElementsByTagName('body')[0].appendChild(this.overlayElem);
  }
  toggleOverlay() {
      if (this.overlayElem.style['opacity'] == 0) {
          this.overlayElem.style['opacity'] = 1;
      }
      else if (this.overlayElem.style['opacity'] == 1) {
          this.overlayElem.style['opacity'] = 0;
      }
  }

}
