<div class="container">
  <div class="title1"><span class="title-red">DESTINATION</span> UAE</div>
</div>

	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="title2">Un pari fou, une aventure incroyable qui devient réalité</div>
				<div class="intro-text">Depuis des mois le projet U8 progresse et soulève des montagnes grâce à un esprit collectif unique !
				</div>
				<hr class="violet-hr">
			</div>
		</div>
		<div class="container">
		<div class="row">



			<div class="col-sm-12 col-md-8 col-lg-6" style="padding-bottom: 15px">
				<img src="assets/images/b2mproject/leprojet/img_UAE.jpg" class="img-fluid" >
			</div>


			<div class="col-sm-12 col-md-4 col-lg-6 justify">
				<strong>Quand Pascal Guillemin souhaite son anniversaire à Christophe Gillet ce 8 décembre 2018, ils n’imaginent pas une seule seconde qu’à l’issue de ces échanges sms, va débuter une aventure humaine et sportive inimaginable</strong>
				<br><br>
				<strong>Allez jouer au Futsal aux Emirats Arabes Unis ? Et pourquoi pas après-tout !</strong>
				<br><br>
				Ainsi est né ce projet sportif et éducatif, tout simplement, avec l’étincelle qu’il faut pour se dire que tout est possible et surtout que rien n’est impossible. Entrée dans une excellente dynamique <strong>dès son lancement, l’aventure a su fédérer, motiver, donner des ailes à tout un groupe, des coachs aux parents en passant par les enfants.</strong> C’est certainement sa plus belle réussite.
				<br><br>
				<strong>Destination UAE</strong> a emmené cette belle équipe sur les marchés vendre sacs et t-shirts, à la Fédération Française de Football, étonnée et séduite par notre initiative. À Clairefontaine rencontré les bleus du football, recueillis des messages vidéos de diverses personnalités.
				<br><br>
				L'Equipe de France de futsal a également répondu présent à l’instar de tous nos partenaires et mécènes qui par leur aide nous permettent aujourd’hui d’<strong>avoir réussi ce projet hors normes !</strong>
				<br><br>
				Le Covid-19 a momentanement ralenti et stoppé le projet dans son élan à 3 semaines du départ.
				<br>
				Mais <strong>l'esprit conquérant demeure pour reprogrammer dès que possible le voyage après la crise sanitaire.</strong>
				<br><br>

				<div class="title2">Suivez nous sur les réseaux sociaux !</div>
				<div class="intro-text">Il se passe toujours quelques choses autour du projet Destination UAE</div>
				<div class="intro-text">Découvrez nos vidéos, nos événements et notre web série sur Facebook, Instagram ou Youtube.</div>
				<ul class="btn_sociaux2">
						<li><a href="https://www.facebook.com/U8DestinationUAE/" target="_blank"><img src="assets/images/b2mproject/leprojet/fb.png" class="img-fluid"></a></li>
						<li><a href="https://www.instagram.com/b2mproject/" target="_blank"><img src="assets/images/b2mproject/leprojet/instagram.png" class="img-fluid"></a></li>
						<li><a href="https://www.youtube.com/channel/UC3XD-CisZqJzwlJDstWNDZQ/featured" target="_blank"><img src="assets/images/b2mproject/leprojet/youtube.png" class="img-fluid"></a></li>
				</ul>
			</div>
			<div class="row">
				<div class="container"></div>
			</div>
			<div class="row"></div>
		</div>

	</div>
