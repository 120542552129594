<div class="row gk_background white_txt">
  <div class="col-12 col-sm-5 col-lg-3 justify-content-center align-self-center">
    <img src="assets/images/logos/logo_AGF.svg" class="img-fluid gk_logo mx-auto">
  </div>
  <div class="col-12 col-sm-7 col-lg-9">
    <div class="white_txt title1">ACADEMIE GOLEIROS DE FUTSAL</div>
    Parce que gardien de but est un poste à part, B2M Futsal a entamé une collaboration étroite avec
    l’Académie Goleiros de Futsal pour donner à ce
    poste une place à part et booster la progression des portiers de l’Académie.
    <br><br>
    Sous la houlette de Manuel Nunes accompagné de Miguel Correia et Maxence Bureau, B2M Futsal
    a mis en place des séances spécifiques régulières durant la saison, ainsi que des stages durant les vacances
    scolaires.
    <br><br>
    Durant la saison 2020/2021, nos gardiens de buts ont eu le privilège de cotoyer à plusieurs reprises Djamel Haroun
    ancien
    gardien et capitaine emblématique de l’Equipe de France de Futsal, ainsi que Samyr Teffaf, Francis Lokoka et
    Joevin Durot,
    tous internationaux de futsal français.
  </div>
</div>
<div class="ratio ratio-16x9 gk_video">
  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/WuFMIwhbKcM"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>
</div>