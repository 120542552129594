import { Component, OnInit } from '@angular/core';
import { Person } from 'src/app/models/person.model';
import { StaffService } from 'src/app/services/staff.service';

@Component({
  selector: 'b2m-staff',
  templateUrl: './staff.component.html',
  styles: [
  ]
})
export class StaffComponent implements OnInit {

  staffTitle: string = 'les coachs <span class="title-red">b2m futsal</span>';
  staffArray: Person[];

  constructor(
    private staffService: StaffService
  ) { }

  ngOnInit(): void {
    this.staffArray = this.staffService.getStaff();
  }
}
