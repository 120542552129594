<div class="container">
    <div class="title1" style="text-align: left; padding-bottom: 0;">Nos actus</div>
</div>

<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="title2">suivez-nous sur les réseaux sociaux</div>
            <div class="intro-text">Afin de suivre l’actualité du club mais aussi du Futsal, connectez-vous,
                abonnez-vous sur les pages B2M des réseaux sociaux.</div>
            <hr class="violet-hr">
        </div>
    </div>


    <div class="row form-group no-pad" style="background-color:#ececec">

        <div class="col-12 col-md-4 d-flex bd-highlight">
            <div class="d-flex align-content-around flex-wrap">
                <div class="row align-content-sm-around">
                    <div class="col-md-4 btn_sociaux_big form-group">
                        <a href="https://www.facebook.com/b2mfutsal/" target="_blank"><i
                                class="fab fa-facebook-f"></i></a>
                    </div>
                    <div class="col-md-8 btn_sociaux_big form-group">
                        <br>FACEBOOK<br>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-md-4 btn_sociaux_big form-group">
                        <a href="https://www.instagram.com/b2mfutsal/" target="_blank"><i
                                class="fab fa-instagram"></i></a>
                    </div>

                    <div class="col-md-8 btn_sociaux_big form-group">
                        <br>INSTAGRAM<br>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-md-4  btn_sociaux_big form-group">
                        <a href="https://twitter.com/b2mfutsal" target="_blank"><i class="fab fa-twitter"></i></a>

                    </div>

                    <div class="col-md-8 btn_sociaux_big form-group">
                        <br>TWITTER<br>
                    </div>
                </div>

                <div class="row align-self-center">
                    <div class="col-md-4  btn_sociaux_big form-group">
                        <a href="https://www.youtube.com/channel/UC6PGIB_obMoE01pbQs7GP0g/featured" target="_blank"><i
                                class="fab fa-youtube"></i></a>
                    </div>

                    <div class="col-md-8 btn_sociaux_big form-group">
                        <br>YOUTUBE<br>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-12 col-md-8 d-flex bd-highlight">
            <img src="assets/images/actu/actu_visu1.jpg" class="img-fluid">
        </div>

    </div>
</div>
