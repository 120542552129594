import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Menu } from '../../../models/menu.model';
import { MenuService } from '../../../services/menu.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';

@Component({
  selector: 'b2m-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  homeLink: string = environment.homeUrl;
  menuItems: Menu[];
  menuState : boolean;

  defaultConfig: any = {
    "animation": "collapse",
    "offset": {
      "top": 68
    },
    closeOnCLick: false
  };

  constructor(
    public scroller: ViewportScroller,
    private menuService: MenuService,
    private router: Router
  ) { }


  ngOnInit(): void {
    this.menuItems = this.menuService.getMenus('orderNavBar').filter(item => item.menuTitle !== null);

    /*this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const currentRoute = event.urlAfterRedirects;
      if (currentRoute === '/membership') {
        location.reload();
      }
    });*/
  }

  onMenuClose() {
    this.menuState = false;
  }
  onMenuOpen() {
    this.menuState = true;
  }
  onItemSelect(item: Menu) {
    this.menuService.activate(item);
    const currentRoute = this.router.url;
    if (currentRoute === item.route) {
        location.reload();
    }
  }

  scrollToAnchor(anchor: string) {
    this.router.navigate([], { fragment: anchor });
  }
}
