<div class="unavailable-wrapper">
  <div class="container">
    <div class="error-icon">
      <i class="fas fa-exclamation-triangle"></i>
    </div>
    <h1 class="title1">Oops! Quelque chose ne va pas</h1>
    <div class="error-details" *ngIf="errorCode || errorMessage">
      <p class="error-message">
          <strong>Code d'erreur :</strong> {{ errorCode }} - {{ errorMessage }}
      </p>
    </div>
    <div class="suggestions">
      <h2>Suggestions pour résoudre le problème :</h2>
      <ul>
        <li><i class="fas fa-wifi"></i> Vérifiez votre connexion Internet.</li>
        <li><i class="fas fa-sync-alt"></i> Assurez-vous que votre navigateur est à jour.</li>
        <li><i class="fas fa-clock"></i> Réessayez d'accéder à cette page plus tard.</li>
        <li><i class="fas fa-headset"></i> Contactez-nous si le problème persiste.</li>
      </ul>
    </div>
    <div class="contact-support">
      N'hésitez pas à contacter notre <a [attr.href]="ticketsPortalUrl" target="_blank">support</a> et à joindre les éléments suivants :
      <ul>
        <li>Code et message d'erreur</li>
        <li>Capture d'écran</li>
        <li>Informations de la personne à inscrire (nom, prénom, date de naissance)</li>
        <li>Utilisez le même email que celui renseigné à l'inscription (si l'étape a été réalisée)</li>
      </ul>
    </div>
  </div>
</div>
