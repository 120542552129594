import { Component, Input } from '@angular/core';
import { FormField } from '../../models/form-field.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'b2m-select-field',
  templateUrl: './select-field.component.html',
  styles: [
  ]
})
export class SelectFieldComponent {
  @Input() field: FormField;
  @Input() formGroup: FormGroup;
}
