<div *ngFor="let step of steps; let i = index" id="topStep">
  <b2m-step [step]="step" *ngIf="currentStep === i" [formGroup]="formGroups[i]" [currentStep]="i" [totalSteps]="steps.length"></b2m-step>
</div>

<div *ngIf="errorMessages.length > 0" class="alert alert-danger">
  <ul>
    <li *ngFor="let message of errorMessages">{{ message }}</li>
  </ul>
</div>

<div class="button-container">
  <button mat-button class="nav-button" (click)="previousStep()" [disabled]="currentStep === 0"><i class="fas fa-arrow-left"></i> Précédent</button>
  <button mat-button class="nav-button"
          (click)="checkIfLastStep() ? refreshPage() : nextStep()"
          [disabled]="isLoading || !canProceed()">
    {{ checkIfLastStep() ? 'Faire une nouvelle inscription' : 'Suivant' }}
    <i class="fas fa-arrow-right"></i>
    <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
  </button>
</div>

