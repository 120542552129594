import { Person } from '../models/person.model';

export const CODIR: Person[] = [
  {
    name: 'Marco FERNANDES',
    thumbmail: 'assets/images/codir/codir_M_Fernandes.jpg',
    posterImg: 'assets/images/codir/pres_M_Fernandes.jpg',
    roles: ['Président/Fondateur'],
    isCollapsed: false,
    description: 'Marco est le Président/Fondateur de B2M Futsal. Il en est certainement son meilleur ambassadeur. \
    Tour à tour coach, trésorier, bénévole, parent, il connait le club sur le bout des doigts et sous tous les angles. \
    Véritable garant de l’esprit B2M Futsal il est toujours à l’écoute des autres pour faire avancer et évoluer le club afin d’en écrire de nouvelles pages. \
    Marco c’est l’exigence au service des adhérents et une ouverture vers l’innovation, pour imaginer le club de demain.'
  },
  {
    name: 'Eric SALOU',
    thumbmail: 'assets/images/codir/codir_E_Salou.jpg',
    roles: ['Vice-Président'],
    isCollapsed: false
  },
  {
    name: 'Eva BUREAU',
    thumbmail: 'assets/images/codir/codir_E_Bureau.jpg',
    posterImg: 'assets/images/codir/pres_E_Bureau.jpg',
    roles: ['Trésorière'],
    isCollapsed: false,
    description: 'Dans la famille d’Eva, le futsal est une religion et B2M y tient une place particulière, \
    voir très importante. Cette maman de joueur et joueuse est toujours présente pour encourager les équipes \
    de ses protègés et les autres. Il était donc tout naturel et logique qu’elle s’investisse dans le club.	\
    Eva fait partie des nouveaux membres du Codir avec une volonté d’aider le club pour vous offrir le meilleur.'
  },
  {
    name: 'Jérôme LEFEBVRE',
    thumbmail: 'assets/images/codir/codir_J_Lefebvre.jpg',
    roles: ['Secrétaire Général'],
    isCollapsed: false
  },
  {
    name: 'Guillaume BRUSSET',
    thumbmail: 'assets/images/codir/codir_G_Brusset.jpg',
    posterImg: 'assets/images/codir/pres_G_Brusset.jpg',
    roles: ['Membre du comité'],
    isCollapsed: false,
    description: 'Guillaume fait partie de l’équipe dirigeante depuis 5 ans, il est aussi responsable de la section Loisir 2 \
    le dimanche soir. Il aime le débat, nager à contre-courant pour que la réflexion soit complète et que tous les \
    paramètres soient pris en compte avant chaque décision. Guillaume c’est une force tranquille, un garde-fou \
    dans les moments d’euphories mais toujours pour le bien de B2M Futsal.'
  },
  {
    name: 'Cédric DARBONNENS',
    thumbmail: 'assets/images/codir/codir_C_Darbonnens.jpg',
    posterImg: 'assets/images/codir/pres_C_Darbonnens.jpg',
    roles: ['Membre du comité'],
    isCollapsed: false,
    description: 'Cédric connait parfaitement le club, arrivé en 2014, il a coaché de nombreuses catégories de \
    B2M Futsal, chez les garçons comme chez les filles. Cet amoureux du futsal, se donne sans retenu dans ses \
    missions et aime participer activement à la vie du club. Cédric, c’est une franchise à toute épreuve et un \
    équipier sur lequel on peut compter.'
  },
  {
    name: 'Edwige FROC',
    thumbmail: 'assets/images/codir/codir_E_Froc.jpg',
    roles: ['Membre du comité'],
    isCollapsed: false
  },
  {
    name: 'Emmanuel CABANETOS',
    thumbmail: 'assets/images/codir/codir_E_Cabanetos.jpg',
    roles: ['Membre du comité'],
    isCollapsed: false
  },
  {
    name: 'Abdelkader MORJAN',
    thumbmail: 'assets/images/codir/codir_A_Morjan.jpg',
    roles: ['Membre du comité'],
    isCollapsed: false
  },
  {
    name: 'David ALMEIDA',
    thumbmail: 'assets/images/codir/codir_D_Almeida.jpg',
    roles: ['Membre du comité'],
    isCollapsed: false
  }
]
