import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiCategoryService } from '../api/services';
import { ApiCategory, ApiTrainingSlot } from '../api/models';
import { map } from 'rxjs/operators';
import { TrainingSlotService } from './training-slot.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private categories$: Observable<any[]>;

  constructor(
    private apiCategoryService: ApiCategoryService,
    private trainingSlotService: TrainingSlotService
  ) { }

  getCategories(season: string, headerXTransactionId: string): Observable<any[]> {
    if (!this.categories$) {
      this.categories$ =  this.apiCategoryService.apiV1CategoriesGetCollection({
        'X-Transaction-Id': headerXTransactionId,
        'season.label': season
      });
    }
    return this.categories$;
  }

  getByUUID(uuid: string): Observable<any> {
    if (!this.categories$) {
      // If trainingSlots$ has not been initialized yet, return an empty observable or handle accordingly
      return of(null);
    }

    return this.categories$.pipe(
      map(categories => categories.find(categories => categories.uuid === uuid))
    );
  }

  getFilteredCategories(season: string, gender: string, birthYear: number, headerXTransactionId: string): Observable<any[]> {
    return this.getCategories(season, headerXTransactionId).pipe(
      map(categories => {
        return categories.filter((category) => {
          const isGenderMatch = (category.gender === gender || category.gender === 'mixed');
          const isBirthYearMatch = (birthYear >= category.minYear || (category.minYear === undefined && category.maxYear !== undefined)) &&
                                   (birthYear <= category.maxYear || (category.maxYear === undefined && category.minYear !== undefined));
          const isGirlUnderagedMatch = (gender === 'woman' && category.gender === 'mixed'
                                        && category.maxYear !== category.minYear
                                        && Number(birthYear) + 1 >= category.minYear
                                        && Number(birthYear) + 1 <= category.maxYear);
          return category.subscribable && isGenderMatch && (isBirthYearMatch || isGirlUnderagedMatch);
        });
      })
    );
  }
}
