/* tslint:disable */
/* eslint-disable */
import { ApiExternalData } from '../models/api-external-data';
export class ApiCategory {
  acceptanceRule?: 'direct_validation' | 'staff_validation' | 'staff_see';
  acceptance_rule?: 'direct_validation' | 'staff_validation' | 'staff_see';
  children?: Array<string>;
  color?: (string | null);
  complete?: boolean;
  createdAt?: string;
  displayInTrainingSlots?: boolean;
  externalDatas?: Array<ApiExternalData>;
  gender?: string;
  id?: number;
  isComplete?: boolean;
  isSubscribable?: boolean;
  joinPeriods?: Array<string>;
  label?: string;
  licenseMandatory?: boolean;
  maxPlayers?: (number | null);
  maxYear?: (number | null);
  minYear?: (number | null);
  minimumAttendanceRate?: (number | null);
  parent?: (string | null);
  season?: string;
  subscribable?: boolean;
  trainingSlots?: Array<string>;
  updatedAt?: string;
  uuid?: string;
  waitListDolibarrId?: (number | null);
}

