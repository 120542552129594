import { Injectable } from '@angular/core';
import { ApiCountryService } from '../api/services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private countries$: Observable<any[]>;

  constructor(private apiCountryService: ApiCountryService) { }

  getCountries(headerXTransactionId: string): Observable<any[]> {
    if (!this.countries$) {
      this.countries$ =  this.apiCountryService.apiV1CountriesGetCollection({'X-Transaction-Id': headerXTransactionId});
    }
    return this.countries$;
  }

  getByUUID(uuid: string): Observable<any> {
    if (!this.countries$) {
      // If trainingSlots$ has not been initialized yet, return an empty observable or handle accordingly
      return of(null);
    }

    return this.countries$.pipe(
      map(countries => countries.find(country => {
        return country.uuid === uuid;
        })
      )
    );
  }
}
