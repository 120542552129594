<div class="suggestions" wmAnimate="bounceInDown" speed="slower" aos replay>
  <!-- Liste de partenaires -->
  <div class="suggestions_b_r">
    <div class="block_title">
      <div>{{ title.title1 }}</div>
      <div *ngIf="title.title2 !== null">{{ title.title2 }}</div>
    </div>
    <br />
    <!-- Utilisation de Flexbox pour les partenaires -->
    <div class="d-flex flex-wrap justify-content-center">
      <!-- Remplacez ces éléments par votre liste de partenaires -->
      <div *ngFor="let partner of partners" class="partner">
        <a [href]="partner.website" target="_blank" rel="noopener noreferrer">
          <img
            [src]="partner.imageSrc"
            alt="{{ partner.name }}"
            [style.width]="partner.imageWidth ? partner.imageWidth : defaultImageWidth"
            class="partner-image img-fluid fixed-image"
          />
        </a>
        <div class="partner-info">
          <strong>{{ partner.name }}</strong>
          <p *ngIf="partner.since">depuis {{ partner.since }}</p>
          <p *ngIf="partner.description">{{ partner.description }}</p>
          <a *ngIf="button.website" [routerLink]="button.website" class="btn btn-primary">&gt; {{button.label}}</a>
          <!-- Ajoutez d'autres informations sur le partenaire si nécessaire -->
        </div>
      </div>
    </div>
  </div>
</div>
