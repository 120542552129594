<div *ngIf="display" class="grey_bg">
	<div class="container">
		<div class="row">
			<div class="col-lg-offset-1 col-lg-10">
				<div class="row">
					<div class="col-sm-6 match_height1" align="right" wmAnimate="fadeInRight" speed="fast" aos replay>
						<div class="banner_title marine_txt">L'ASSOCIATION B2M PROJECT</div>
						<div class="banner_txt dark_txt">Créé pour gérer et donner vie au projet U8 Destination UAE,<br>
						B2M Project a vocation à devenir dans le futur,<br>l’alter ego extra sportif de B2M Futsal
						</div>
						<div style="margin-bottom: 35px;">
							<a [routerLink]="['/project/association']" class="infos_btn">&gt; En savoir plus</a>
						</div>
					</div>
					<div class="col-sm-6 match_height1 infos_asso"></div>
				</div>
			</div>
		</div>
	</div>
</div>
