import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Team } from 'src/app/models/team.model';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'b2m-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  posterSelected: Team = null;

  boardStore: Array<Team> = [];

  constructor(
    private viewportScroller: ViewportScroller,
    private _teamService: TeamService
    ) { }

  ngOnInit(): void {
    this.boardStore = this._teamService.getAll();
    this.boardStore.forEach(element => {
      element.isCollapsed = false;
    });
  }

  collapsePoster(orgData: Team) {

    if (orgData.posterImg === null) {
      orgData.posterImg = 'assets/images/teams/u11.jpg';
    }

    orgData.isCollapsed = true;
    this.posterSelected = new Team();
    this.posterSelected = orgData;
    //this.viewportScroller.scrollToAnchor("posterTeam");
  }
}
