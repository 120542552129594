import { Injectable } from '@angular/core';
import { STADIUMS } from 'src/app/data/stadium-data';
import { Stadium } from '../models/stadium.model';

@Injectable({
  providedIn: 'root'
})
export class StadiumService {

  constructor() { }

  getAll(): Stadium[] {
    return STADIUMS;
  }
}
