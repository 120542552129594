import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'b2m-uae',
  templateUrl: './uae.component.html',
  styleUrls: ['./uae.component.scss']
})
export class UaeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
