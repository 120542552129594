//import * as internal from "assert";

export class Menu {
    menuTitle: string;
    blockTitle: string;
    buttonTitle: string;
    route: string;
    link: string;
    src: string;
    bannerSrc?: string;
    chapter: string;
    scrollLink: boolean;
    navLink: boolean;
    active: boolean;
    menuColor: string;
    blockColor: string;
    blockBgColor: string;
    orderNavBar: number;
    orderSlideMenu: number;
    orderBlock: number;
    orderSitemap: number;
    subItems?: Menu[];
    headLine?: string;
    icon?: string;
}
