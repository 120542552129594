import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TeamsComponent } from './components/teams/teams.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [TeamsComponent],
  imports: [
    CommonModule,
    NgbModule,
    SharedModule
  ]
})
export class TeamsModule { }
