import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'b2m-input-field',
  templateUrl: './input-field.component.html',
})
export class InputFieldComponent implements OnInit {
  @Input() control = new FormControl();
  @Input() label: string;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() appearance: string;
  @Input() required: boolean;
  @Input() maxLength: string;
  @Input() value: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    this.prepareValue();
    this.control.valueChanges.subscribe((value) => {
      this.value = value.trim();
      this.valueChange.emit(this.value);
    });
  }
  prepareValue(): void {
    if (this.value && typeof this.value === 'string') {
      this.control.setValue(this.value.trim());
    }
  }

  getErrorMessage() {
    if (this.control.hasError('required')) {
      return 'Ce champ est obligatoire.';
    } else if (this.control.hasError('maxlength')) {
      return `La longueur maximale est de ${this.control.errors?.maxlength.requiredLength} caractères.`;
    } else if (this.control.hasError('email')) {
      return 'Adresse email invalide.';
    }
    return '';
  }
}
