/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1TrainingSlotsGetCollection } from '../fn/training-slot/api-v-1-training-slots-get-collection';
import { ApiV1TrainingSlotsGetCollection$Params } from '../fn/training-slot/api-v-1-training-slots-get-collection';
import { apiV1TrainingSlotsPost } from '../fn/training-slot/api-v-1-training-slots-post';
import { ApiV1TrainingSlotsPost$Params } from '../fn/training-slot/api-v-1-training-slots-post';
import { apiV1TrainingSlotsUuidDelete } from '../fn/training-slot/api-v-1-training-slots-uuid-delete';
import { ApiV1TrainingSlotsUuidDelete$Params } from '../fn/training-slot/api-v-1-training-slots-uuid-delete';
import { apiV1TrainingSlotsUuidGet } from '../fn/training-slot/api-v-1-training-slots-uuid-get';
import { ApiV1TrainingSlotsUuidGet$Params } from '../fn/training-slot/api-v-1-training-slots-uuid-get';
import { apiV1TrainingSlotsUuidPatch } from '../fn/training-slot/api-v-1-training-slots-uuid-patch';
import { ApiV1TrainingSlotsUuidPatch$Params } from '../fn/training-slot/api-v-1-training-slots-uuid-patch';
import { apiV1TrainingSlotsUuidPut } from '../fn/training-slot/api-v-1-training-slots-uuid-put';
import { ApiV1TrainingSlotsUuidPut$Params } from '../fn/training-slot/api-v-1-training-slots-uuid-put';
import { ApiTrainingSlot } from '../models/api-training-slot';

@Injectable({ providedIn: 'root' })
export class ApiTrainingSlotService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1TrainingSlotsGetCollection()` */
  static readonly ApiV1TrainingSlotsGetCollectionPath = '/v1/training_slots';

  /**
   * Retrieves the collection of TrainingSlot resources.
   *
   * Retrieves the collection of TrainingSlot resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1TrainingSlotsGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1TrainingSlotsGetCollection$Response(params?: ApiV1TrainingSlotsGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiTrainingSlot>>> {
    return apiV1TrainingSlotsGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of TrainingSlot resources.
   *
   * Retrieves the collection of TrainingSlot resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1TrainingSlotsGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1TrainingSlotsGetCollection(params?: ApiV1TrainingSlotsGetCollection$Params, context?: HttpContext): Observable<Array<ApiTrainingSlot>> {
    return this.apiV1TrainingSlotsGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiTrainingSlot>>): Array<ApiTrainingSlot> => r.body)
    );
  }

  /** Path part for operation `apiV1TrainingSlotsPost()` */
  static readonly ApiV1TrainingSlotsPostPath = '/v1/training_slots';

  /**
   * Creates a TrainingSlot resource.
   *
   * Creates a TrainingSlot resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1TrainingSlotsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1TrainingSlotsPost$Response(params: ApiV1TrainingSlotsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiTrainingSlot>> {
    return apiV1TrainingSlotsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a TrainingSlot resource.
   *
   * Creates a TrainingSlot resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1TrainingSlotsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1TrainingSlotsPost(params: ApiV1TrainingSlotsPost$Params, context?: HttpContext): Observable<ApiTrainingSlot> {
    return this.apiV1TrainingSlotsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiTrainingSlot>): ApiTrainingSlot => r.body)
    );
  }

  /** Path part for operation `apiV1TrainingSlotsUuidGet()` */
  static readonly ApiV1TrainingSlotsUuidGetPath = '/v1/training_slots/{uuid}';

  /**
   * Retrieves a TrainingSlot resource.
   *
   * Retrieves a TrainingSlot resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1TrainingSlotsUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1TrainingSlotsUuidGet$Response(params: ApiV1TrainingSlotsUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiTrainingSlot>> {
    return apiV1TrainingSlotsUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a TrainingSlot resource.
   *
   * Retrieves a TrainingSlot resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1TrainingSlotsUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1TrainingSlotsUuidGet(params: ApiV1TrainingSlotsUuidGet$Params, context?: HttpContext): Observable<ApiTrainingSlot> {
    return this.apiV1TrainingSlotsUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiTrainingSlot>): ApiTrainingSlot => r.body)
    );
  }

  /** Path part for operation `apiV1TrainingSlotsUuidPut()` */
  static readonly ApiV1TrainingSlotsUuidPutPath = '/v1/training_slots/{uuid}';

  /**
   * Replaces the TrainingSlot resource.
   *
   * Replaces the TrainingSlot resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1TrainingSlotsUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1TrainingSlotsUuidPut$Response(params: ApiV1TrainingSlotsUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiTrainingSlot>> {
    return apiV1TrainingSlotsUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TrainingSlot resource.
   *
   * Replaces the TrainingSlot resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1TrainingSlotsUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1TrainingSlotsUuidPut(params: ApiV1TrainingSlotsUuidPut$Params, context?: HttpContext): Observable<ApiTrainingSlot> {
    return this.apiV1TrainingSlotsUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiTrainingSlot>): ApiTrainingSlot => r.body)
    );
  }

  /** Path part for operation `apiV1TrainingSlotsUuidDelete()` */
  static readonly ApiV1TrainingSlotsUuidDeletePath = '/v1/training_slots/{uuid}';

  /**
   * Removes the TrainingSlot resource.
   *
   * Removes the TrainingSlot resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1TrainingSlotsUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1TrainingSlotsUuidDelete$Response(params: ApiV1TrainingSlotsUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1TrainingSlotsUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the TrainingSlot resource.
   *
   * Removes the TrainingSlot resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1TrainingSlotsUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1TrainingSlotsUuidDelete(params: ApiV1TrainingSlotsUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1TrainingSlotsUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1TrainingSlotsUuidPatch()` */
  static readonly ApiV1TrainingSlotsUuidPatchPath = '/v1/training_slots/{uuid}';

  /**
   * Updates the TrainingSlot resource.
   *
   * Updates the TrainingSlot resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1TrainingSlotsUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1TrainingSlotsUuidPatch$Response(params: ApiV1TrainingSlotsUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiTrainingSlot>> {
    return apiV1TrainingSlotsUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the TrainingSlot resource.
   *
   * Updates the TrainingSlot resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1TrainingSlotsUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1TrainingSlotsUuidPatch(params: ApiV1TrainingSlotsUuidPatch$Params, context?: HttpContext): Observable<ApiTrainingSlot> {
    return this.apiV1TrainingSlotsUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiTrainingSlot>): ApiTrainingSlot => r.body)
    );
  }

}
