import { Component, Input } from '@angular/core';
import { FormField } from '../../models/form-field.model';
import { FormGroup } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'b2m-date-field',
  templateUrl: './date-field.component.html',
  styles: [
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }]
})
export class DateFieldComponent {
  @Input() field: FormField;
  @Input() formGroup: FormGroup;
}
