import { environment } from "src/environments/environment";

export class HelperUtils {

  public static getIRI(urlPath: string) {
    const iri: URL = new URL(environment.b2mApiUrl + urlPath);
    return iri.pathname;
  }

  public static convertReadToWrite<TRead, TWrite extends TRead, TExtra>(readObj: TRead, extraObj: TExtra): TWrite & TExtra {
    const writeObj = readObj as TWrite;
    return {...writeObj, ...extraObj};
  }

  // Function to retrieve ID from an IRI
  public static getIdFromIRI(iri: string): string {
    // Split the IRI into segments
    const segments = iri.split('/');

    // Get the last segment (which contains the ID)
    const lastSegment = segments[segments.length - 1];

    // Check if the last segment is a number (numeric ID)
    if (!isNaN(+lastSegment)) {
      return lastSegment;
    }

    // Return the default ID (full segment)
    return lastSegment;
  }

  public static getPastelRandomColor(): string {
    // Génère des valeurs aléatoires pour les composantes Rouge, Vert et Bleu (RVB)
    const r = Math.floor(Math.random() * 100) + 100; // Valeurs entre 100 et 199 pour le rouge
    const g = Math.floor(Math.random() * 100) + 100; // Valeurs entre 100 et 199 pour le vert
    const b = Math.floor(Math.random() * 100) + 100; // Valeurs entre 100 et 199 pour le bleu

    // Convertit les composantes RVB en code hexadécimal
    const hex = ((r << 16) | (g << 8) | b).toString(16);

    // Remplit éventuellement les zéros manquants
    const paddedHex = "#" + hex.padStart(6, "0");

    return paddedHex;
  }

  public static generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
