<div *ngIf="display" class="infos">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 match_height1" align="right" wmAnimate="fadeInRight" speed="fast" aos replay>
                <div class="banner_title marine_txt">ACTU</div>
                <div class="banner_txt dark_txt">Retrouvez toutes les actus B2M sur les réseaux sociaux !<br>Facebook,
                    Twitter, Instagram et Youtube, tous ces médias<br>sont à votre disposition pour connaitre
                    les dernières news.</div>
                <div style="margin-bottom: 35px;">
                    <a [routerLink]="['/news']" class="infos_btn">&gt; En savoir plus</a>
                </div>
            </div>
            <div class="col-sm-6 match_height1 news_bg"></div>
        </div>
    </div>
</div>
