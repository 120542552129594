<div class="row">
  <div class="col-4">
  <b2m-slide-menu [menulist]="menuItems" [(menuState)]="menuState" [config]="defaultConfig"
    (open)="onMenuOpen()" (close)="onMenuClose()" (onItemSelect)="onItemSelect($event)"></b2m-slide-menu></div>

  <div class="col-4">
      <a [routerLink]="['/home']"><img src="assets/images/logos/Blason_B2M.svg" alt="logo_b2m" class="img-fluid img-logo mx-auto d-block"></a>
  </div>

  <div class="col-4">
    <ul class="btn_sociaux">

      <li><a href="https://www.facebook.com/b2mfutsal/" target="_blank"><i class="fab fa-facebook-f"></i></a>
      </li>
      <li><a href="https://www.instagram.com/b2mfutsal/" target="_blank"><i class="fab fa-instagram"></i></a>
      </li>
      <li><a href="https://twitter.com/b2mfutsal" target="_blank"><i class="fab fa-twitter"></i></a>
      </li>
      <li><a href="https://www.youtube.com/channel/UC6PGIB_obMoE01pbQs7GP0g/featured" target="_blank"><i
            class="fab fa-youtube"></i></a></li>
    </ul>
  </div>
</div>