import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'b2m-select',
  templateUrl: './select.component.html',
  styles: [
  ]
})
export class SelectComponent implements OnInit {
  @Input() options: any[] = [];
  @Input() value: string;
  @Output() valueChange = new EventEmitter<any>();
  @Input() control = new FormControl();
  @Input() label: string;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() appearance: string;
  @Input() required: boolean;

  constructor() {}

  ngOnInit() {
    this.control.setValue(this.value.trim());
    this.control.valueChanges.subscribe((value) => {
      this.value = value.trim();
      this.valueChange.emit(this.value);
    });
  }
}
