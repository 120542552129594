<div *ngIf="display" class="marine_bg">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 match_height2 fbc_bg">
            </div>
            <div class="col-sm-6 match_height2" align="left" wmAnimate="fadeInLeft" speed="fast" aos replay>
                <div class="banner_title white_txt">TOURNOI ENTREPRISES</div>
                <div class="banner_txt white_txt">Fort du succès de la première édition, la Futsal Business Cup sera de
                    retour le dimanche 6 novembre 2022 pour permettre aux entreprises de venir de nouveau partager un moment de sport avec leurs
                    collaborateurs, au profit d’un projet du club
                </div>
                <div style="margin-bottom: 35px;">
                    <span class="white_btn">Prochainement</span>
                    <!--<a href="/docs/fbc/Futsal_Business_Cup_2.pdf" class="white_btn" target="_blank">&gt; Télécharger la présentation</a>-->
                </div>
            </div>
        </div>
    </div>
</div>
