import { PortalOutlet } from '@angular/cdk/portal';
import { Pole } from '../models/pole.model';

export const POLES: Pole[] = [
  {
    name: 'PÔLE STARTER',
    thumbmail: 'assets/images/training/Training_Starter.jpg',
    description: 'Apprendre les gestes techniques fondamentaux du futsal dès le plus jeune âge, pour créer des bases solides et acquérir les bons réflexes tout en prenant du plaisir. \
    <br><br> \
    Travail de motricité pour se repérer dans l’espace et apprendre à se déplacer sur un terrain.',
    categories: [
      'Baby Futsal',
      'U7-U8',
      'U9-U10',
      'Fémikids'
    ]
  },
  {
    name: 'PÔLE PROGRESS',
    thumbmail: 'assets/images/training/Training_Progress.jpg',
    description: 'Des catégories charnières pour monter en compétences, gagner en aisance technique, en intensité et parfaire son sens tactique et collectif. \
    <br><br> \
    C’est également les meilleures années pour participer à des compétitions très disputées.',
    categories: [
      'U11-U12',
      'U13-U14',
      'U15-U16',
      'Fémiteens',
      'U14F-U16F'
    ]
  },
  {
    name: 'PÔLE EVOLUTION',
    thumbmail: 'assets/images/training/Training_Evolution.jpg',
    description: 'Les catégories de la maturité, de l’exigeance, celles où on affirme ses ambitions, son caractère et ses envies d’évoluer avec le club vers le niveau supérieur. \
    <br><br> \
    Intégrer ce pôle, nécessite d’avoir un niveau de pratique avancé et une maitrise technique aguerrie.',
    categories: [
      'U17-U18',
      'Séniors Masculins',
      'Séniors Féminines'
    ]
  },
  {
    name: 'PÔLE KEEPER',
    thumbmail: 'assets/images/training/Training_Keeper.jpg',
    description: 'Un poste essentiel et très important en futsal, qui nécessite une attention particulière et un entrainement spécifique et exigeant avec des spécialistes dès le plus jeune âge. \
    <br><br> \
    C’est l’un des premiers en France et son affluence confirme son attractivité.',
    categories: [
      'Gardiens de but de U7 à Seniors'
    ]
  },
  {
    name: 'PÔLE LOISIRS',
    thumbmail: 'assets/images/training/Training_Loisirs.jpg',
    description: 'Se faire plaisir sans compétition, en toute décontraction, pour se retrouver et partager des séances où l’envie de bien jouer et marquer des buts reste le let motive, c’est le but principal des séances loisirs. \
    <br><br> \
    Un moment sympa pour bien finir la semaine.',
    categories: [
      'Joueurs masculins de 30 à 60 ans'
    ]
  },
  {
    name: 'PÔLE SPORT-SANTÉ',
    thumbmail: 'assets/images/training/Training_Sante.jpg',
    description: 'Reprendre une activité physique lorsqu\'on ne peut plus courir, se remettre en forme, combattre la sédentarité c\'est le but du FitFut. \
    <br><br> \
    Mixte et intergénérationnelle, profitez d\'un moment ludique.',
    categories: [
      'de 7 à 77 ans'
    ]
  }
]
