/* tslint:disable */
/* eslint-disable */
export class ApiVenue {
  address?: string;
  createdAt?: string;
  id?: number;
  name?: string;
  shortName?: string;
  town?: string;
  updatedAt?: string;
  uuid?: string;
  zip?: string;
}

