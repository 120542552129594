import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'b2m-banner-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
