import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'b2m-banner-celebration',
  templateUrl: './celebration.component.html',
  styleUrls: ['./celebration.component.scss']
})
export class CelebrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
