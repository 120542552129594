<div class="collapse_info" *ngIf="poster !== null" [@openClose]="poster.isCollapsed ? 'open' : 'closed'">
    <div class="collapse_info_header">
        <div class="fermer" (click)="poster.isCollapsed = !poster.isCollapsed">fermer <img
                src="assets/images/circle_+_white.png" alt="circle_+_white"></div>
    </div>
    <div class="collapse_info_body">
        <div class="row">
            <div class="col-sm-7">
                <img [src]="poster.posterImg" alt="produit" class="img-fluid">
                <br class="visible-xs">
            </div>
            <div class="col-sm-5 align-self-center">
                <div class="d-flex flex-column">
                    <div class="collapse_title1 justify">{{poster.name}}</div>
                    <div *ngIf="poster.role!==null"><strong>{{poster.role}}</strong></div>
                    <div *ngIf="poster.competition!==null"><strong>{{poster.competition}}</strong></div>
                    <div *ngIf="poster.coach!==null">
                        <span><strong>Responsable : </strong> {{poster.coach}}</span>
                    </div>
                    <!--<div *ngIf="poster.assistantCoachs!==null && poster.assistantCoachs!==undefined">
                        <span><strong>Adjoint<span *ngIf="posterInput.assistantCoachs.length > 1">s</span> : </strong> {{poster.assistantCoachs}}</span>
                    </div>-->
                    <hr class="violet-hr2">
                    <div *ngIf="poster.resultLink!==null">
                        <a [attr.href]="poster.resultLink" target="_blank">RESULTATS</a>
                    </div>
                    <div class="cat_des justify">{{poster.description}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="collapse_info_header">
    </div>
</div>
