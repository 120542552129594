<div class="title1">
  <span class="title-red">Horaires </span
  ><span class="title-blue">d'entraînements</span>
</div>

<section>
  <div class="container">
    <div class="col-xs-12">
      <div class="cat_title">
        quelle catégorie ? dans quel stadium ? à quelle heure
      </div>
      <div class="cat_des">
        Retrouvez les horaires et les lieux d’entraînements pour toutes les
        catégories
      </div>
    </div>

  <div *ngIf="isLoading" class="spinner-container">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Chargement...</span>
    </div>
  </div>

  <div *ngIf="!isLoading" class="table-responsive-sm">
    <section *ngFor="let gender of ['mixed', 'woman', 'man']">
      <h3 class="category-header">
        <i [ngClass]="getIconClass(gender)" aria-hidden="true"></i>
        {{ getGenderLabel(gender) }}
      </h3>
      <table class="table table-striped table-sm">
        <thead>
          <tr>
            <th>Catégorie</th>
            <th>Créneau</th>
            <th>Gymnase</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let category of sortedCategories[gender]">
            <ng-container *ngFor="let slot of category.trainingSlotsFront; let i = index">
              <tr>
                <td *ngIf="i == 0" [attr.rowspan]="category.trainingSlotsFront.length" [id]="category.code" class="scrollFragment">
                  <div class="category-label">
                    <span class="badge" [ngStyle]="{'background-color': category.color, 'color': '#fff'}">
                      {{ category.label }}
                    </span>
                    <div class="year-range">
                      {{ getYearRange(category) }}
                    </div>
                  </div>
                </td>
                <td>
                  <ng-container *ngIf="slot.status === 'assigned'; else notAssigned">
                    <i class="fas fa-calendar-check"></i>
                    {{ slot.weekday }} {{ slot.startTime }}-{{ slot.endTime }}
                  </ng-container>
                  <ng-template #notAssigned>
                    <span class="unassigned-slot">Non attribué pour le moment</span>
                  </ng-template>
                </td>
                <td>
                    <ng-container *ngIf="slot.status === 'assigned'; else noVenue">
                      <a [routerLink]="['/stadiums']" [fragment]="slot.venueFront.id">
                        <div>
                          <span class="venue-name">{{ slot.venueFront.shortName }}</span>
                          <span class="venue-city">{{ slot.venueFront.town }}</span>
                        </div>
                      </a>
                    </ng-container>
                    <ng-template #noVenue>
                      <span class="unassigned-slot">-</span>
                    </ng-template>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </section>
  </div>

  </div>
</section>

<div class="legend">
  <span class="badge category-west">Secteur Ouest</span>
  <span class="badge category-east">Secteur Est</span>
  <span class="badge category-none">Pas de Secteur</span>
</div>
