import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormField } from '../models/form-field.model';

@Injectable({
  providedIn: 'root'
})
export class FieldService {
  private fieldSubject = new BehaviorSubject<FormField>(null);
  field$ = this.fieldSubject.asObservable();

  setField(field: FormField) {
    this.fieldSubject.next(field);
  }
}
