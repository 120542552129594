<mat-form-field [appearance]="appearance">
  <mat-label>{{label}}</mat-label>
  <input matInput
         [placeholder]="placeholder"
         [formControl]="control"
         [type]="type"
         [required]="required"
         [(ngModel)]="value"
         [attr.maxLength]="maxLength ? maxLength : null">
  <mat-error *ngIf="control.invalid && control.touched">
    {{ getErrorMessage() }}
  </mat-error>
</mat-form-field>
