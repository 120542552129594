<div [formGroup]="formGroup">
  <ng-container [ngSwitch]="field.type">
    <b2m-text-field *ngSwitchCase="'text'" [field]="field" [formGroup]="formGroup"></b2m-text-field>
    <b2m-select-field *ngSwitchCase="'select'" [field]="field" [formGroup]="formGroup"></b2m-select-field>
    <b2m-date-field *ngSwitchCase="'date'" [field]="field" [formGroup]="formGroup"></b2m-date-field>
    <b2m-category-selector *ngSwitchCase="'category-selector'" [field]="field" [multiple]="field.multiple" (selectionChange)="onSelectionChange($event)" [formGroup]="formGroup"></b2m-category-selector>
    <b2m-dual-selector *ngSwitchCase="'dual-selector'" [field]="field" [multiple]="field.multiple" (selectionChange)="onSelectionChange($event)" [formGroup]="formGroup"></b2m-dual-selector>
    <b2m-checkbox-field *ngSwitchCase="'checkbox'" [field]="field" [formGroup]="formGroup"></b2m-checkbox-field>
    <b2m-summary *ngSwitchCase="'summary'" [field]="field"></b2m-summary>
  </ng-container>
  <div *ngIf="control.invalid && control.touched" class="error-message">
    {{ getErrorMessage() }}
  </div>
</div>
