<span (clickOutside)="closeMenu()">
    <button (click)="menuToggle()" class="hamburger hamburger--{{config.animation}}"
        [ngClass]="{'is-active': menuState, '': !menuState}">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
    <div class="menu-container cuppa-menu" [ngClass]="{'show-menu': menuState, 'hide-menu': !menuState}"
        [ngStyle]="{'top':config.offset.top+'px'}">
        <ul>
            <li *ngFor="let item of menuItems">

                <a *ngIf="item.subItems" (click)="toggleSubMenu(item)">{{item.menuTitle}}
                    <i *ngIf="item.subItems" class="fas fa-angle-right"
                        [@toggleArrow]="item.expand == 'show' ? 'down': 'right'"></i>
                </a>

                <a *ngIf="!item.subItems && !item.scrollLink" [ngClass]="{'active': item.active}"
                    [routerLink]="[item.route]" (click)="closeMenu();onItemClick(item)"
                    [ngClass]="{'active': item.active}">{{item.menuTitle}}</a>

                <a *ngIf="!item.subItems && item.scrollLink" [ngClass]="{'active': item.active}"
                    (click)="closeMenu();viewportScroller.scrollToAnchor(item.route)"
                    [ngClass]="{'active': item.active}">{{item.menuTitle}}</a>

                <ul *ngIf="item.subItems" class="sub-menu"
                    [@toggleMenu]="item.expand == undefined ? 'hide': item.expand">

                    <li *ngFor="let subitem of item.subItems">
                        <a *ngIf="!subitem.scrollLink" [routerLink]="[subitem.route]" (click)="closeMenu();onItemClick(subitem)"
                            [ngClass]="{'active': subitem.active}">{{subitem.menuTitle}}</a>
                        <a *ngIf="subitem.scrollLink"
                            (click)="closeMenu();viewportScroller.scrollToAnchor(subitem.route)"
                            [ngClass]="{'active': subitem.active}">{{subitem.menuTitle}}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</span>