/* tslint:disable */
/* eslint-disable */
export class ApiHousehold {
  createdAt?: string;
  id?: number;
  members?: Array<string>;
  name?: string;
  numberOfActiveOrPendingMembers?: number;
  numberOfMembers?: number;
  number_of_members?: number;
  updatedAt?: string;
  uuid?: string;
}

