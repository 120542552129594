<div [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <mat-label>{{field.label}}</mat-label>
    <mat-select [formControlName]="field.name">
      <mat-option *ngFor="let option of field.options" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="formGroup.get(field.name).hasError('required')">
      Ce champ est requis
    </mat-error>
  </mat-form-field>
</div>
