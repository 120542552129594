import { Component, ElementRef, HostListener, OnInit, ViewChild, inject } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { updateLanguageServiceSourceFile } from 'typescript';
import { Menu } from '../../../../models/menu.model';
import { MenuService } from '../../../../services/menu.service';

@Component({
  selector: 'b2m-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  menuItems: Menu[];
  menuService = inject(MenuService);
  isMobile: boolean = false;
  buttonVisible: boolean = false;  // Pour gérer l'animation
  windowScrolled: boolean = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.windowScrolled = window.pageYOffset > 500;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerWidth <= 768; // Seuil typique pour un écran mobile
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 768;
    // Simuler un délai pour l'apparition du bouton
    if (this.isMobile) {
      setTimeout(() => {
        this.buttonVisible = true;
      }, 500); // Délai de 500 ms avant l'apparition
    }
    this.menuItems = this.menuService.getMenus('orderBlock');
    this.menuItems = this.menuService.setFlat(this.menuItems).filter(item => item.blockTitle !== null);
  }
}
