<div class="row">
    <div class="title1" [id]="anchorId" [innerHTML]="orgTitle"></div>

    <div class="d-flex justify-content-center flex-wrap org-container">

        <div *ngFor="let org of orgArray" class="produit">
            <div class="produit_item" (click)="collapsePoster(org)">
                <img [src]="org.thumbmail===null ? 'assets/images/staff/avatar.jpg' : org.thumbmail" class="img-fluid"
                    alt="produit">
                <div class="photo_title"><strong>{{org.name}}</strong>
                    <span *ngFor="let role of org.roles">
                        <br>{{role}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<b2m-collapse-info [posterInput]="posterSelected" id="posterPerson"></b2m-collapse-info>
