/* tslint:disable */
/* eslint-disable */
export class ApiConsent {
  acceptDate?: (string | null);
  consentPurpose?: string;
  createdAt?: string;
  id?: number;
  member?: string;
  rejectDate?: (string | null);
  updatedAt?: string;
  uuid?: string;
}

