import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RaffleComponent } from './components/raffle/raffle.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { AnimateModule } from '@wizdm/animate';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [RaffleComponent],
  imports: [
    CommonModule,
    NgbModule,
    //AnimateModule,
    SharedModule
  ]
})
export class EventsModule { }
