<section>
  <div class="container">
    <div>
      <label for="season">Saison:</label>
      <select id="season" [(ngModel)]="selectedSeason" (ngModelChange)="getCategoriesBySeason(selectedSeason)">
        <option *ngFor="let season of seasons" [ngValue]="season">
          {{ season.label }}
        </option>
      </select>
    </div>

      <div>
        <label for="category">Catégorie </label>
        <select id="category" [(ngModel)]="selectedCategory" (ngModelChange)="getJoinPeriodsByCategory(selectedCategory)">
          <option *ngFor="let category of categories" [ngValue]="category">
            {{ category.label }}
          </option>
        </select>
      </div>
      <div>
        <label for="joinRule">Règle d'adhésion </label>
        <select id="joinRule" [(ngModel)]="selectedJoinRule">
          <option *ngFor="let joinRule of joinRules" [ngValue]="joinRule">
            {{ joinRule.code }}
          </option>
        </select>
      </div>
    <form [formGroup]="joinPeriodForm">
      <div>
        <label for="startDate">Date de début:</label>
        <input type="date" id="startDate" formControlName="startDate" required>
      </div>
      <div>
        <label for="endDate">Date de fin:</label>
        <input type="date" id="endDate" formControlName="endDate" required>
      </div>
      <button mat-raised-button color="primary" (click)="addJoinPeriod()">Ajouter une période d'adhésion</button>
    </form>

    <div>
      <form>
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Période d'adhésion</th>
            <td mat-cell *matCellDef="let joinPeriod">{{ joinPeriod.id }}</td>
          </ng-container>

          <ng-container matColumnDef="joinRule">
            <th mat-header-cell *matHeaderCellDef>Règle d'adhésion</th>
            <td mat-cell *matCellDef="let joinPeriod">{{ joinPeriod.joinRuleFront.code }}</td>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef>Date de début</th>
            <td mat-cell *matCellDef="let joinPeriod">{{ joinPeriod.startDate }}</td>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef>Date de fin</th>
            <td mat-cell *matCellDef="let joinPeriod">{{ joinPeriod.endDate }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let joinPeriod">
              <button mat-icon-button color="primary" (click)="editJoinPeriod(joinPeriod)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="deleteJoinPeriod(joinPeriod)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

          <tr mat-row *matRowDef="let joinPeriod of joinPeriodsFront; columns: displayedColumns;"></tr>

        </table>
      </form>
    </div>
  </div>
</section>
