import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { StadiumsComponent } from './components/stadiums/stadiums.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [StadiumsComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ]
})
export class StadiumsModule { }
