import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Menu } from '../../../models/menu.model';
import { MenuService } from '../../../services/menu.service';

@Component({
  selector: 'b2m-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public logoBgClass: string;
  homeLink: string = environment.homeUrl;
  menuItems: Menu[];
  menuState : boolean;
  defaultConfig: any = {
    "animation": "collapse",
    "offset": {
      "top": 68
    },
    closeOnCLick: false
  };

  constructor(
    private menuService: MenuService
    ) {}

  ngOnInit(): void {
    this.menuItems = this.menuService.getMenus('orderSlideMenu').filter(item => item.orderSlideMenu !== 0);
  }
  onMenuClose() {
    this.menuState = false;
  }
  onMenuOpen() {
    this.menuState = true;
  }
  onItemSelect(item: Menu) {
    this.menuService.activate(item);
  }
}
