import { Injectable } from '@angular/core';
import { CODIR } from 'src/app/data/codir-data';
import { Person } from '../models/person.model';

@Injectable({
  providedIn: 'root'
})
export class CodirService {

  constructor() { }

  getCodir(): Person[] {
    return CODIR;
  }
}
