<div class="title1">
  <span class="title-red">Training </span><span class="title-blue">& Futsal</span>
</div>

<div class="container text-center">
  <div class="title2">
    A CHAQUE ETAPE, UNE METHODE PROGRESSIVE ET DIFFERENTE DE VIVRE LE FUTSAL
  </div>
  <div class="intro-text">
    Découvrez les grandes lignes directrices de nos pôles avec leurs spécificités.
  </div>
</div>

<section>
  <div class="container">

    <div class="visu_training mx-auto">
      <img
        src="assets/images/training/Training_terrain.jpg"
        class="img-fluid"
      />
    </div>

    <hr class="violet-hr" />

    <div class="container">
      <div class="row">
        <div class="d-flex justify-content-center flex-wrap block-container">
          <div
            *ngFor="let block of blockList"
            class="block"
            wmAnimate="bounceInDown"
            speed="slower"
            aos="1"
          >
            <img [src]="block.thumbmail" class="block-item img-fluid" />
            <div class="content">
              <div class="title" [innerHTML]="block.name"></div>
              <ul id="list">
              <li
                class="categories"
                *ngFor="let category of block.categories"
                [innerHTML]="category"
              ></li>
            </ul>
              <div class="description" [innerHTML]="block.description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
