<div class="container">
  <div class="title1"><span class="title-red">LE PÔLE </span> B2M PROJECT</div>
</div>

<section>
  <div class="container">
    <div class="intro-text justify">
      <strong>
        B2M Project est né d’une histoire imprévue, d’une rencontre et d’une
        envie commune de vivre l’extraordinaire pour donner vie à un projet hors
        norme.
      </strong>
      <br><br>
      Depuis sa création, B2M Project a développé un incroyable réseau, donné
      vie à de nombreuses initiatives qui ont su mettre en lumière B2M Futsal.
      Que ce soit des projets <strong> simples, extraordinaires, innovants, inattendus,
      sportifs et éducatifs, nous avons envie de relever ces défis.</strong>
      <br><br>
      Ce livre
      blanc que nous avons commencé à écrire avec le projet Destination UAE,
      nous espérons en noircir les pages avec l’aide des nombreux bénévoles,
      parents, joueurs, joueuses, éducateurs et dirigeants du club.
      <br><br>
      Devenu
      l’alter ego extra sportif du club, <strong>B2M Project est en perpétuel mouvement</strong>
      en fonction des projets qui se dessinent, pour leur permettre de voir le
      jour, grâce aux compétences et à l’expérience de chacun.
      <br><br>
      Pour vivre le
      futsal et l’extraordinaire, il ne faut pas avoir peur de rêver fort, se
      dire que tout est possible et tout faire pour réussir, on ne vit qu’une
      fois…
    </div>
  </div>
</section>
<hr class="violet-hr">
<b2m-banner-project></b2m-banner-project>

<div class="container">
  <div class="ratio ratio-16x9 gk_video">
    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/c4GO_gcItzs"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>
