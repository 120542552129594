<mat-form-field [appearance]="appearance">
  <mat-label>{{label}}</mat-label>
  <input matInput [formControl]="control" [placeholder]="placeholder"
    [matDatepicker]="picker" (click)="_openCalendar(picker)" [required]="required">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [startView]="startView" [startAt]="startAt" (closed)="_closeCalendar(picker)"></mat-datepicker>
  <mat-error *ngIf="control.dirty">
    <div *ngIf="control.hasError('tooYoung')">Votre enfant est encore trop jeune, nous vous invitons à revenir l'année de ses 5 ans
      ou la saison prochaine</div>
  </mat-error>
</mat-form-field>
