<div [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <mat-label>{{field.label}}</mat-label>
    <input matInput [matDatepicker]="picker" [formControlName]="field.name" placeholder="JJ/MM/AAAA">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="formGroup.get(field.name).hasError('required')">
      Ce champ est requis
    </mat-error>
    <mat-error *ngIf="formGroup.get(field.name).hasError('matDatepickerParse')">
      Format de date invalide
    </mat-error>
    <mat-error *ngIf="formGroup.get(field.name).hasError('dateRange')">
      {{ formGroup.get(field.name).getError('dateRange') }}
    </mat-error>
  </mat-form-field>
</div>
