<div *ngIf="display" class="row banner-celebration">
    <div class="col-12 col-sm-12 banner">
        <img src="assets/images/homepage/sliderHomepage_001.jpg" class="img-fluid">
    </div>
    <!--<div class="col-12 col-sm-3 match-zone">
        <div class="row">
            <div class="col-12 text-center">PROCHAIN MATCH</div>
            <div class="col-12 text-center title-red">DATE</div>
            <div class="vszone text-center">
                <div class="col-5">
                    <img src="assets/images/logos/logo_B2M_Futsal.svg" class="img-fluid">
                </div>
                <div class="col-2">VS</div>
                <div class="col-5">
                    <img src="assets/images/logos/logo_B2M_Futsal.svg" class="img-fluid">
                </div>
            </div>
            <div class="d-flex justify-content-center scorezone">
                <div class="text-right">0</div>
                <div class="text-center"> - </div>
                <div class="text-left">0</div>
            </div>
        </div>
    </div>-->
</div>
