/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiCategoryService } from './services/api-category.service';
import { ApiConsentPurposeService } from './services/api-consent-purpose.service';
import { ApiConsentService } from './services/api-consent.service';
import { ApiCountryService } from './services/api-country.service';
import { ApiDuesRateService } from './services/api-dues-rate.service';
import { ApiHouseholdService } from './services/api-household.service';
import { ApiJoinPeriodService } from './services/api-join-period.service';
import { ApiJoinRuleService } from './services/api-join-rule.service';
import { ApiMemberService } from './services/api-member.service';
import { ApiMembershipService } from './services/api-membership.service';
import { ApiRoleService } from './services/api-role.service';
import { ApiSeasonService } from './services/api-season.service';
import { ApiTrainingSlotService } from './services/api-training-slot.service';
import { ApiVenueService } from './services/api-venue.service';
import { ApiWaitingListService } from './services/api-waiting-list.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiCategoryService,
    ApiConsentPurposeService,
    ApiConsentService,
    ApiCountryService,
    ApiDuesRateService,
    ApiHouseholdService,
    ApiJoinPeriodService,
    ApiJoinRuleService,
    ApiMemberService,
    ApiMembershipService,
    ApiRoleService,
    ApiSeasonService,
    ApiTrainingSlotService,
    ApiVenueService,
    ApiWaitingListService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
