import {
  ApiCategory,
  ApiConsentPurpose,
  ApiCountry,
  ApiJoinPeriod,
  ApiJoinRule,
  ApiMember,
  ApiRole,
  ApiSeason,
  ApiTrainingSlot,
  ApiVenue,
  ApiWaitingList
} from 'src/app/api/models';
import { CategoryFront } from 'src/app/models/category-front.model';
import { Injectable } from '@angular/core';
import { CATEGORIES } from 'src/app/data/category-data';
import { HelperUtils } from '../utils/helper.utils';
import { TrainingSlotFront } from 'src/app/models/training-slot-front.model';
import { ApiCategoryService, ApiConsentPurposeService, ApiJoinRuleService, ApiRoleService, ApiSeasonService, ApiVenueService, ApiWaitingListService } from 'src/app/api/services';
import { LAST_SEASON_LABEL, MEMBERSHIP_SEASON_LABEL, TOWN_ZIP_CODES } from 'src/app/constants';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { JoinPeriod } from '../models/join-period.model';
import { CountryService } from './country.service';

const JOIN_RULE_CODES = {
  TOWNS_NEW: 'towns_new',
  WAITING_LIST: 'waiting_list',
  SIBLING: 'sibling',
  CATEGORY_RENEW: 'category_renew',
  ALL_NEW: 'all_new',
  CLOSED: 'closed'
};

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  private selectedCategorySubject = new BehaviorSubject<CategoryFront | null>(null);
  selectedCategory$ = this.selectedCategorySubject.asObservable();

  constructor(
    private countryService: CountryService,
    private apiRoleService: ApiRoleService,
    private apiSeasonService: ApiSeasonService,
    private waitingListService: ApiWaitingListService
    ) { }

    setSelectedCategory(category: CategoryFront): void {
      // Prevent setting the same category again and avoid a potential infinite loop
      if (this.selectedCategorySubject.value === category) {
        return; // Exit if the category is the same to prevent recursive updates
      }

      this.selectedCategorySubject.next(category);
    }

  getSelectedCategory(): CategoryFront | null {
      return this.selectedCategorySubject.value;
  }

  getInitialData(headerXTransactionId: string): Observable<any> {
    return forkJoin([
      this.apiSeasonService.apiV1SeasonsGetCollection({
        'X-Transaction-Id': headerXTransactionId,
        label: MEMBERSHIP_SEASON_LABEL
      }),
      this.apiRoleService.apiV1RolesGetCollection({
        'X-Transaction-Id': headerXTransactionId,
        name: 'player'
      }),
      this.countryService.getCountries(headerXTransactionId)
    ]).pipe(
      map(([currentSeasons, roles, countries]: [
        ApiSeason[], ApiRole[], ApiCountry[]
      ]) => {
        return {
          currentSeason: currentSeasons.shift(),
          rolePlayer: roles.shift(),
          countries
        };
      })
    );
  }

    public getStaticSubCategories(sex?: string): any[] {
      //let subCategories: string[][] = [];
      let subCategories: {year, label, categories: string[]}[] = [];

      CATEGORIES.forEach(category => {
        if (category.subCategories !== undefined) {
          category.subCategories.forEach(subCategory => {
            if ((sex !== undefined && sex===subCategory.sex) || sex === undefined) {
              let found = subCategories.find(subCat => subCat.year === subCategory.year);
              if (found === undefined) {
                //subCategories.push([subCategory.year, subCategory.label, category.shortLabel]);
                subCategories.push({year: subCategory.year, label: subCategory.label, categories: [category.shortLabel]});
              } else {
                found.categories.push(category.shortLabel);
              }
            }
          });
        }
      });
      return subCategories;
    }

    public getAllStatic(): any[] {
      return CATEGORIES;
    }
  /*private static executeAntenaCategoryContinuity(category: Category, member: Member): boolean {
    if (
      member.getCategories().filter((memberOldCategory) => {
        if (memberOldCategory === null || memberOldCategory.antena === null) {
          return null;
        } else if (category === null || category.antena === null) {
          return null;
        } else {
          if (category.maxYear !== null) {
            if (
              Number(memberOldCategory.antena) === Number(category.antena) ||
              category.label === "U14-U15" // U14-U15 have a break on antenaContinuity
            ) {

              return memberOldCategory;
            }
          }
        }
        return null;
      }).length === 1
    ) {
      return true;
    }

    return false;
  }*/

  /*private static filterByPreviousCategory(category: Category, member: Member): boolean {
    if (
      member.getCategories().filter((memberOldCategory) => {
        if (Number(memberOldCategory.id) === Number(category.previousid)) {
          return memberOldCategory;
        }
      }).length === 1
    ) {
      return true;
    }
  }*/

  public mapCategoryJoinRulesTrue(
    headerXTransactionId: string,
    category: ApiCategory,
    apiJoinPeriods: ApiJoinPeriod[],
    joinRules: ApiJoinRule[],
    member: ApiMember,
    isMemberLastSeason: boolean
  ): CategoryFront | null {

    const today: Date = new Date();
    const categoryFront: CategoryFront = category;
    categoryFront.available = false;
    categoryFront.objectJoinPeriods = [];

      for (const apiJoinPeriod of apiJoinPeriods) {
        let joinPeriod: JoinPeriod = {};
        const startDate: Date = new Date(Date.parse(apiJoinPeriod.startDate));
        const endDate: Date = new Date(Date.parse(apiJoinPeriod.endDate));

        if (startDate <= today && endDate > today && this.isJoinPeriodFromCategory(categoryFront, apiJoinPeriod)) {
          Object.assign(joinPeriod, apiJoinPeriod);
          joinPeriod.objectJoinRule = this.getJoinRuleByJoinPeriod(apiJoinPeriod, joinRules);
          joinPeriod.joinRuleAllDescription =  this.getJoinRuleAllDescription(joinRules, joinPeriod.objectJoinRule.previousCodes)
                                                    .concat(joinPeriod.objectJoinRule.description);
          categoryFront.objectJoinPeriods.push(joinPeriod);
        } else  {
          continue;
        }

        const joinRuleCodes: string[] = [joinPeriod.objectJoinRule.code, ...joinPeriod.objectJoinRule.previousCodes];

        joinRuleCodes.forEach((code: string) => {
          if(categoryFront.available) {
            return;
          }

          switch (joinPeriod.objectJoinRule.code) {
            case JOIN_RULE_CODES.CATEGORY_RENEW:
              categoryFront.available = isMemberLastSeason;
              break;
            case JOIN_RULE_CODES.SIBLING:
              categoryFront.available = isMemberLastSeason;
              break;
            case JOIN_RULE_CODES.WAITING_LIST:
              categoryFront.available = isMemberLastSeason;
              this.waitingListService.apiV1WaitingListsGetCollection({
                    'X-Transaction-Id': headerXTransactionId,
                    'category.season.label': LAST_SEASON_LABEL,
                    'member.uuid': member.uuid
                  }).subscribe((waitingLists: ApiWaitingList[]) => {
                    categoryFront.available = waitingLists.length > 0 || isMemberLastSeason ? true : false;
                  });
              break;
            case JOIN_RULE_CODES.TOWNS_NEW:
              categoryFront.available = this.filterByTown(member) || isMemberLastSeason;
              break;
            case JOIN_RULE_CODES.ALL_NEW:
              categoryFront.available = true;
              break;
            case JOIN_RULE_CODES.CLOSED:
              categoryFront.available = false;
              break;
            default:
              categoryFront.available = false;
              categoryFront.staffValidation = false;
              categoryFront.staffSee = false;
              break;
          }
        });
      }

    return categoryFront;
  }

  public mapCategoryTrainingSlotVenue(
    category: CategoryFront,
    trainingSlots: ApiTrainingSlot[],
    venues: ApiVenue[]
  ): CategoryFront {

    category.trainingSlotsFront = [];
    let trainingSlotsFront: TrainingSlotFront[] = [];
    Object.assign(trainingSlotsFront, trainingSlots);

    for (const trainingSlot of trainingSlotsFront) {
      if (this.isTrainingSlotFromCategory(category, trainingSlot)) {

        for (const venue of venues) {
          if (this.isVenueFromTrainingSlot(trainingSlot, venue)) {
            trainingSlot.venueFront = venue;
          }
        }

        category.trainingSlotsFront.push(trainingSlot);
      }
    }
    return category;
  }

  public filterByTown(member: ApiMember): boolean {
    if (!member.zip) {
      return false;
    }

    return TOWN_ZIP_CODES.includes(member.zip);
  }

  /*public filterByWaitingList(waitLists: WaitList[]): boolean {
    const filteredList = waitLists.filter(waitList => waitList.category.season.label === '2021-2022');
    return filteredList.length === 1;
  }*/

  public getJoinRuleByJoinPeriod(joinPeriod: ApiJoinPeriod, joinRules: ApiJoinRule[]): ApiJoinRule {

    return joinRules.filter((joinRule: ApiJoinRule) => {
      return HelperUtils.getIRI('/v1/join_rules/' + joinRule.uuid) === joinPeriod.joinRule;
    }).shift();
  }

  public isJoinPeriodFromCategory(category: ApiCategory, joinPeriod: ApiJoinPeriod) {
    return HelperUtils.getIRI('/v1/categories/' + category.uuid) === joinPeriod.category;
  }

  public getJoinRuleAllDescription(joinRules: ApiJoinRule[], codes: string[]): string[] {
    return joinRules
      .filter(rule => codes.includes(rule.code))
      .map(rule => rule.description);
  }

  public isTrainingSlotFromCategory(category: ApiCategory, trainingSlot: ApiTrainingSlot) {
    return HelperUtils.getIRI('/v1/categories/' + category.uuid) === trainingSlot.category;
  }

  public isVenueFromTrainingSlot(trainingSlot: ApiTrainingSlot, venue: ApiVenue) {
    return HelperUtils.getIRI('/v1/venues/' + venue.uuid) === trainingSlot.venue;
  }

  public hasSeason(seasons: ApiSeason[], seasonLabel: string): boolean {
    return seasons.filter(season => season.label === seasonLabel).length > 0;
  }

  public isWaitingListOnSeason(season: ApiSeason) {

  }
}
