import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

interface TeamColor {
  color: string;
  name: string;
  selected: boolean;
}

@Component({
  selector: 'b2m-team-draw',
  templateUrl: './team-draw.component.html',
  styleUrls: ['./team-draw.component.css']
})
export class TeamDrawComponent {
  drawForm: FormGroup;
  teams: {members;  color}[] = [];
  teamColors: TeamColor[] = [
    { color: null, name: 'sans chasuble', selected: false },
    { color: '#FF0000', name: 'rouge', selected: false },
    { color: '#0000FF', name: 'bleu', selected: false },
    { color: '#008000', name: 'vert', selected: false },
    { color: '#FFFF00', name: 'jaune', selected: false },
    { color: '#FFFFFF', name: 'blanc', selected: false },
    { color: '#000000', name: 'noir', selected: false },
    { color: '#FFC0CB', name: 'rose', selected: false },
    { color: '#FFA500', name: 'orange', selected: false }
  ];

  constructor(private fb: FormBuilder) {
    this.drawForm = this.fb.group({
      playersText: ['']
    });
  }

  toggleSelection(colorIndex: number) {
    this.teamColors[colorIndex].selected = !this.teamColors[colorIndex].selected;
  }

  generateTeams() {

    let selectedTeamColors = this.teamColors.filter(tc => tc.selected).map(tc => tc.name);
    const players = this.drawForm.get('playersText').value.split("\n").filter(Boolean);

    // Old code
    //const selectedTeams = this.teamColors.filter(color => color.selected);
    const numberOfTeams = selectedTeamColors.length;
    this.teams = [];

    for (let i = 0; i < numberOfTeams; i++) {
      this.teams.push({
        members: [],
        color: selectedTeamColors[i] // Ajouter la couleur ici
      });
    }

    players.sort(() => Math.random() - 0.5);

    players.forEach((player, i) => {
      this.teams[i % numberOfTeams].members.push(player);
    });
  }
}
