import { NgModule, Provider, forwardRef, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { environment } from '../environments/environment';
import { ApiModule } from './api/api.module';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticatedModule } from './authenticated/authenticated.module';
import { ToastrModule } from 'ngx-toastr';
import { RollbarService, RollbarErrorHandler } from './services/rollbar.service';

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true
};

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule, // Requis pour les animations de Toastr
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    AppRoutingModule,
    CoreModule,
    SharedModule,
    AuthenticatedModule,
    ApiModule.forRoot({ rootUrl: environment.b2mApiUrl }),
  ],
  providers: [
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    DatePipe,
    RollbarService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
