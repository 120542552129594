import { Injectable, ErrorHandler } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../../environments/environment';

const rollbarConfig = {
  accessToken: 'd4bfacd0a0294c4d9db413ad1abb592d',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: environment.production ? 'production' : 'development'
};

@Injectable({
  providedIn: 'root'
})
export class RollbarService {
  private rollbar: Rollbar;

  constructor() {
    this.rollbar = new Rollbar(rollbarConfig);
  }

  public error(error: any) {
    this.rollbar.error(error);
  }

  public info(message: string) {
    this.rollbar.info(message);
  }

  public warn(message: string) {
    this.rollbar.warning(message);
  }

  public critical(error: any) {
    this.rollbar.critical(error);
  }
}

// Custom ErrorHandler
@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private rollbar: RollbarService) {}

  handleError(error: any): void {
    if (environment.production) {
      this.rollbar.error(error);
      console.error('Error handled by Rollbar:', error);
    } else {
      console.error('Error handled:', error);
    }
  }
}
