import { Component, Input, OnInit } from '@angular/core';
import { FormField } from '../../models/form-field.model';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'b2m-dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.scss']
})
export class DynamicFieldComponent implements OnInit {
  @Input() field: FormField;
  @Input() formGroup: FormGroup;

  get control(): AbstractControl {
    return this.formGroup.get(this.field.name);
  }

  ngOnInit() {
    /*
     * Example to follow invalid changes on form
     *
    this.control.statusChanges.subscribe(status => {
      if (status === 'INVALID') {
        // Logic to display real-time validation errors
        console.error(`Field ${this.field.name} is invalid`);
      }
    });
    */
  }

  onSelectionChange(selectedCategories: any) {
    this.formGroup.get(this.field.name).setValue(selectedCategories);
  }

  getErrorMessage() {
    if (this.control.hasError('required')) {
      return 'Ce champ est obligatoire';
    } else if (this.control.hasError('mustBe') === true) {
      return "La sélection de cette case est obligatoire pour continuer à l'étape suivante.";
    } else {
      return 'Valeur incorrecte';
    }
  }
}
