import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'b2m-town-select',
  templateUrl: './town-select.component.html',
  styles: [
  ]
})
export class TownSelectComponent {
  @Input() zip: string;
  @Input() town: string;
  @Input() type: string;
  @Input() appearance: string;
  @Input() required: boolean;
  @Input() form: FormGroup;
  @Output() zipChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() townChange: EventEmitter<string> = new EventEmitter<string>();

  // Définition des villes connues avec leurs codes postaux
  knownTowns = [
    { code: '94360', name: 'BRY-SUR-MARNE' },
    { code: '94170', name: 'LE PERREUX-SUR-MARNE' },
    { code: '93360', name: 'NEUILLY-PLAISANCE' },
    { code: '93160', name: 'NOISY-LE-GRAND' }
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      zip: ['',
        [
          Validators.required,
          Validators.pattern("^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$")
        ]
      ],
      town: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.form.controls.zip.valueChanges.subscribe((zip) => {
      this.zip = zip;
      this.zipChange.emit(this.zip);
    });
    this.form.controls.town.valueChanges.subscribe((town) => {
      this.town = town;
      this.townChange.emit(this.town);
    });
  }

  onZipChange() {
    const zip = this.form.get('zip')!.value;
    if (this.knownTowns.some(town => town.code === zip)) {
      const matchedTown = this.knownTowns.find(town => town.code === zip);
      this.form.get('town')!.setValue(matchedTown!.name);
    } else {
      this.form.get('town')!.setValue('');
    }
  }
}
