/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1JoinPeriodsGetCollection } from '../fn/join-period/api-v-1-join-periods-get-collection';
import { ApiV1JoinPeriodsGetCollection$Params } from '../fn/join-period/api-v-1-join-periods-get-collection';
import { apiV1JoinPeriodsPost } from '../fn/join-period/api-v-1-join-periods-post';
import { ApiV1JoinPeriodsPost$Params } from '../fn/join-period/api-v-1-join-periods-post';
import { apiV1JoinPeriodsUuidDelete } from '../fn/join-period/api-v-1-join-periods-uuid-delete';
import { ApiV1JoinPeriodsUuidDelete$Params } from '../fn/join-period/api-v-1-join-periods-uuid-delete';
import { apiV1JoinPeriodsUuidGet } from '../fn/join-period/api-v-1-join-periods-uuid-get';
import { ApiV1JoinPeriodsUuidGet$Params } from '../fn/join-period/api-v-1-join-periods-uuid-get';
import { apiV1JoinPeriodsUuidPatch } from '../fn/join-period/api-v-1-join-periods-uuid-patch';
import { ApiV1JoinPeriodsUuidPatch$Params } from '../fn/join-period/api-v-1-join-periods-uuid-patch';
import { apiV1JoinPeriodsUuidPut } from '../fn/join-period/api-v-1-join-periods-uuid-put';
import { ApiV1JoinPeriodsUuidPut$Params } from '../fn/join-period/api-v-1-join-periods-uuid-put';
import { ApiJoinPeriod } from '../models/api-join-period';

@Injectable({ providedIn: 'root' })
export class ApiJoinPeriodService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1JoinPeriodsGetCollection()` */
  static readonly ApiV1JoinPeriodsGetCollectionPath = '/v1/join_periods';

  /**
   * Retrieves the collection of JoinPeriod resources.
   *
   * Retrieves the collection of JoinPeriod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinPeriodsGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinPeriodsGetCollection$Response(params?: ApiV1JoinPeriodsGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiJoinPeriod>>> {
    return apiV1JoinPeriodsGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of JoinPeriod resources.
   *
   * Retrieves the collection of JoinPeriod resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinPeriodsGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinPeriodsGetCollection(params?: ApiV1JoinPeriodsGetCollection$Params, context?: HttpContext): Observable<Array<ApiJoinPeriod>> {
    return this.apiV1JoinPeriodsGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiJoinPeriod>>): Array<ApiJoinPeriod> => r.body)
    );
  }

  /** Path part for operation `apiV1JoinPeriodsPost()` */
  static readonly ApiV1JoinPeriodsPostPath = '/v1/join_periods';

  /**
   * Creates a JoinPeriod resource.
   *
   * Creates a JoinPeriod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinPeriodsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1JoinPeriodsPost$Response(params: ApiV1JoinPeriodsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiJoinPeriod>> {
    return apiV1JoinPeriodsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a JoinPeriod resource.
   *
   * Creates a JoinPeriod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinPeriodsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1JoinPeriodsPost(params: ApiV1JoinPeriodsPost$Params, context?: HttpContext): Observable<ApiJoinPeriod> {
    return this.apiV1JoinPeriodsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiJoinPeriod>): ApiJoinPeriod => r.body)
    );
  }

  /** Path part for operation `apiV1JoinPeriodsUuidGet()` */
  static readonly ApiV1JoinPeriodsUuidGetPath = '/v1/join_periods/{uuid}';

  /**
   * Retrieves a JoinPeriod resource.
   *
   * Retrieves a JoinPeriod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinPeriodsUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinPeriodsUuidGet$Response(params: ApiV1JoinPeriodsUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiJoinPeriod>> {
    return apiV1JoinPeriodsUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a JoinPeriod resource.
   *
   * Retrieves a JoinPeriod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinPeriodsUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinPeriodsUuidGet(params: ApiV1JoinPeriodsUuidGet$Params, context?: HttpContext): Observable<ApiJoinPeriod> {
    return this.apiV1JoinPeriodsUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiJoinPeriod>): ApiJoinPeriod => r.body)
    );
  }

  /** Path part for operation `apiV1JoinPeriodsUuidPut()` */
  static readonly ApiV1JoinPeriodsUuidPutPath = '/v1/join_periods/{uuid}';

  /**
   * Replaces the JoinPeriod resource.
   *
   * Replaces the JoinPeriod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinPeriodsUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1JoinPeriodsUuidPut$Response(params: ApiV1JoinPeriodsUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiJoinPeriod>> {
    return apiV1JoinPeriodsUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the JoinPeriod resource.
   *
   * Replaces the JoinPeriod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinPeriodsUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1JoinPeriodsUuidPut(params: ApiV1JoinPeriodsUuidPut$Params, context?: HttpContext): Observable<ApiJoinPeriod> {
    return this.apiV1JoinPeriodsUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiJoinPeriod>): ApiJoinPeriod => r.body)
    );
  }

  /** Path part for operation `apiV1JoinPeriodsUuidDelete()` */
  static readonly ApiV1JoinPeriodsUuidDeletePath = '/v1/join_periods/{uuid}';

  /**
   * Removes the JoinPeriod resource.
   *
   * Removes the JoinPeriod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinPeriodsUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinPeriodsUuidDelete$Response(params: ApiV1JoinPeriodsUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1JoinPeriodsUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the JoinPeriod resource.
   *
   * Removes the JoinPeriod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinPeriodsUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinPeriodsUuidDelete(params: ApiV1JoinPeriodsUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1JoinPeriodsUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1JoinPeriodsUuidPatch()` */
  static readonly ApiV1JoinPeriodsUuidPatchPath = '/v1/join_periods/{uuid}';

  /**
   * Updates the JoinPeriod resource.
   *
   * Updates the JoinPeriod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinPeriodsUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1JoinPeriodsUuidPatch$Response(params: ApiV1JoinPeriodsUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiJoinPeriod>> {
    return apiV1JoinPeriodsUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the JoinPeriod resource.
   *
   * Updates the JoinPeriod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinPeriodsUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1JoinPeriodsUuidPatch(params: ApiV1JoinPeriodsUuidPatch$Params, context?: HttpContext): Observable<ApiJoinPeriod> {
    return this.apiV1JoinPeriodsUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiJoinPeriod>): ApiJoinPeriod => r.body)
    );
  }

}
