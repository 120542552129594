/* tslint:disable */
/* eslint-disable */
export class ApiJsonld {
  '@context'?: (string | {
'@vocab': string;
'hydra': 'http://www.w3.org/ns/hydra/core#';
[key: string]: any;
});
  '@id'?: string;
  '@type'?: string;
  category?: (string | null);
  createdAt?: string;
  endTime?: string;
  id?: number;
  startTime?: string;
  status?: 'assigned' | 'draft' | 'cancelled';
  updatedAt?: string;
  uuid?: string;
  venue?: string;
  weekday?: string;
}

