/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1VenuesGetCollection } from '../fn/venue/api-v-1-venues-get-collection';
import { ApiV1VenuesGetCollection$Params } from '../fn/venue/api-v-1-venues-get-collection';
import { apiV1VenuesPost } from '../fn/venue/api-v-1-venues-post';
import { ApiV1VenuesPost$Params } from '../fn/venue/api-v-1-venues-post';
import { apiV1VenuesUuidDelete } from '../fn/venue/api-v-1-venues-uuid-delete';
import { ApiV1VenuesUuidDelete$Params } from '../fn/venue/api-v-1-venues-uuid-delete';
import { apiV1VenuesUuidGet } from '../fn/venue/api-v-1-venues-uuid-get';
import { ApiV1VenuesUuidGet$Params } from '../fn/venue/api-v-1-venues-uuid-get';
import { apiV1VenuesUuidPatch } from '../fn/venue/api-v-1-venues-uuid-patch';
import { ApiV1VenuesUuidPatch$Params } from '../fn/venue/api-v-1-venues-uuid-patch';
import { apiV1VenuesUuidPut } from '../fn/venue/api-v-1-venues-uuid-put';
import { ApiV1VenuesUuidPut$Params } from '../fn/venue/api-v-1-venues-uuid-put';
import { ApiVenue } from '../models/api-venue';

@Injectable({ providedIn: 'root' })
export class ApiVenueService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1VenuesGetCollection()` */
  static readonly ApiV1VenuesGetCollectionPath = '/v1/venues';

  /**
   * Retrieves the collection of Venue resources.
   *
   * Retrieves the collection of Venue resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1VenuesGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1VenuesGetCollection$Response(params?: ApiV1VenuesGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiVenue>>> {
    return apiV1VenuesGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Venue resources.
   *
   * Retrieves the collection of Venue resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1VenuesGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1VenuesGetCollection(params?: ApiV1VenuesGetCollection$Params, context?: HttpContext): Observable<Array<ApiVenue>> {
    return this.apiV1VenuesGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiVenue>>): Array<ApiVenue> => r.body)
    );
  }

  /** Path part for operation `apiV1VenuesPost()` */
  static readonly ApiV1VenuesPostPath = '/v1/venues';

  /**
   * Creates a Venue resource.
   *
   * Creates a Venue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1VenuesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1VenuesPost$Response(params: ApiV1VenuesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiVenue>> {
    return apiV1VenuesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Venue resource.
   *
   * Creates a Venue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1VenuesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1VenuesPost(params: ApiV1VenuesPost$Params, context?: HttpContext): Observable<ApiVenue> {
    return this.apiV1VenuesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiVenue>): ApiVenue => r.body)
    );
  }

  /** Path part for operation `apiV1VenuesUuidGet()` */
  static readonly ApiV1VenuesUuidGetPath = '/v1/venues/{uuid}';

  /**
   * Retrieves a Venue resource.
   *
   * Retrieves a Venue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1VenuesUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1VenuesUuidGet$Response(params: ApiV1VenuesUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiVenue>> {
    return apiV1VenuesUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Venue resource.
   *
   * Retrieves a Venue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1VenuesUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1VenuesUuidGet(params: ApiV1VenuesUuidGet$Params, context?: HttpContext): Observable<ApiVenue> {
    return this.apiV1VenuesUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiVenue>): ApiVenue => r.body)
    );
  }

  /** Path part for operation `apiV1VenuesUuidPut()` */
  static readonly ApiV1VenuesUuidPutPath = '/v1/venues/{uuid}';

  /**
   * Replaces the Venue resource.
   *
   * Replaces the Venue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1VenuesUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1VenuesUuidPut$Response(params: ApiV1VenuesUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiVenue>> {
    return apiV1VenuesUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Venue resource.
   *
   * Replaces the Venue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1VenuesUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1VenuesUuidPut(params: ApiV1VenuesUuidPut$Params, context?: HttpContext): Observable<ApiVenue> {
    return this.apiV1VenuesUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiVenue>): ApiVenue => r.body)
    );
  }

  /** Path part for operation `apiV1VenuesUuidDelete()` */
  static readonly ApiV1VenuesUuidDeletePath = '/v1/venues/{uuid}';

  /**
   * Removes the Venue resource.
   *
   * Removes the Venue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1VenuesUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1VenuesUuidDelete$Response(params: ApiV1VenuesUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1VenuesUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Venue resource.
   *
   * Removes the Venue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1VenuesUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1VenuesUuidDelete(params: ApiV1VenuesUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1VenuesUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1VenuesUuidPatch()` */
  static readonly ApiV1VenuesUuidPatchPath = '/v1/venues/{uuid}';

  /**
   * Updates the Venue resource.
   *
   * Updates the Venue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1VenuesUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1VenuesUuidPatch$Response(params: ApiV1VenuesUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiVenue>> {
    return apiV1VenuesUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the Venue resource.
   *
   * Updates the Venue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1VenuesUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1VenuesUuidPatch(params: ApiV1VenuesUuidPatch$Params, context?: HttpContext): Observable<ApiVenue> {
    return this.apiV1VenuesUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiVenue>): ApiVenue => r.body)
    );
  }

}
