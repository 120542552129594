<div class="container">
  <div class="col-xs-12">
      <div class="title1">{{ title }}</div>
  </div>
</div>

<section>
  <div class="container" *ngIf="serviceAvailable; else unavailable">
    <b2m-membership-form [steps]="formSteps" (checkCallbackResultEmitter)="handleCheckCallbackResult($event)"></b2m-membership-form>
  </div>
  <ng-template #unavailable>
    <b2m-unavailable [errorCode]="serviceErrorCode" [errorMessage]="serviceErrorMessage"></b2m-unavailable>
</ng-template>
</section>

<b2m-alert id="alert-global"></b2m-alert>
