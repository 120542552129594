/* tslint:disable */
/* eslint-disable */
import { ApiJsonld as ExternalDataApiJsonld } from '../../models/ExternalData/api-jsonld';
export class ApiJsonld {
  '@context'?: (string | {
'@vocab': string;
'hydra': 'http://www.w3.org/ns/hydra/core#';
[key: string]: any;
});
  '@id'?: string;
  '@type'?: string;
  categories?: Array<string>;
  createdAt?: string;
  endDate?: string;
  externalDatas?: Array<ExternalDataApiJsonld>;
  id?: number;
  joinEndDate?: string;
  joinStartDate?: string;
  label?: string;
  startDate?: string;
  updatedAt?: string;
  uuid?: string;
}

