/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1MembersGetCollection } from '../fn/member/api-v-1-members-get-collection';
import { ApiV1MembersGetCollection$Params } from '../fn/member/api-v-1-members-get-collection';
import { apiV1MembersPost } from '../fn/member/api-v-1-members-post';
import { ApiV1MembersPost$Params } from '../fn/member/api-v-1-members-post';
import { apiV1MembersUuidDelete } from '../fn/member/api-v-1-members-uuid-delete';
import { ApiV1MembersUuidDelete$Params } from '../fn/member/api-v-1-members-uuid-delete';
import { apiV1MembersUuidGet } from '../fn/member/api-v-1-members-uuid-get';
import { ApiV1MembersUuidGet$Params } from '../fn/member/api-v-1-members-uuid-get';
import { apiV1MembersUuidPatch } from '../fn/member/api-v-1-members-uuid-patch';
import { ApiV1MembersUuidPatch$Params } from '../fn/member/api-v-1-members-uuid-patch';
import { apiV1MembersUuidPut } from '../fn/member/api-v-1-members-uuid-put';
import { ApiV1MembersUuidPut$Params } from '../fn/member/api-v-1-members-uuid-put';
import { ApiMember } from '../models/api-member';

@Injectable({ providedIn: 'root' })
export class ApiMemberService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1MembersGetCollection()` */
  static readonly ApiV1MembersGetCollectionPath = '/v1/members';

  /**
   * Retrieves the collection of Member resources.
   *
   * Retrieves the collection of Member resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembersGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembersGetCollection$Response(params?: ApiV1MembersGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiMember>>> {
    return apiV1MembersGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Member resources.
   *
   * Retrieves the collection of Member resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembersGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembersGetCollection(params?: ApiV1MembersGetCollection$Params, context?: HttpContext): Observable<Array<ApiMember>> {
    return this.apiV1MembersGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiMember>>): Array<ApiMember> => r.body)
    );
  }

  /** Path part for operation `apiV1MembersPost()` */
  static readonly ApiV1MembersPostPath = '/v1/members';

  /**
   * Creates a Member resource.
   *
   * Creates a Member resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1MembersPost$Response(params: ApiV1MembersPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiMember>> {
    return apiV1MembersPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Member resource.
   *
   * Creates a Member resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1MembersPost(params: ApiV1MembersPost$Params, context?: HttpContext): Observable<ApiMember> {
    return this.apiV1MembersPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiMember>): ApiMember => r.body)
    );
  }

  /** Path part for operation `apiV1MembersUuidGet()` */
  static readonly ApiV1MembersUuidGetPath = '/v1/members/{uuid}';

  /**
   * Retrieves a Member resource.
   *
   * Retrieves a Member resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembersUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembersUuidGet$Response(params: ApiV1MembersUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiMember>> {
    return apiV1MembersUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Member resource.
   *
   * Retrieves a Member resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembersUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembersUuidGet(params: ApiV1MembersUuidGet$Params, context?: HttpContext): Observable<ApiMember> {
    return this.apiV1MembersUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiMember>): ApiMember => r.body)
    );
  }

  /** Path part for operation `apiV1MembersUuidPut()` */
  static readonly ApiV1MembersUuidPutPath = '/v1/members/{uuid}';

  /**
   * Replaces the Member resource.
   *
   * Replaces the Member resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembersUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1MembersUuidPut$Response(params: ApiV1MembersUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiMember>> {
    return apiV1MembersUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Member resource.
   *
   * Replaces the Member resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembersUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1MembersUuidPut(params: ApiV1MembersUuidPut$Params, context?: HttpContext): Observable<ApiMember> {
    return this.apiV1MembersUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiMember>): ApiMember => r.body)
    );
  }

  /** Path part for operation `apiV1MembersUuidDelete()` */
  static readonly ApiV1MembersUuidDeletePath = '/v1/members/{uuid}';

  /**
   * Removes the Member resource.
   *
   * Removes the Member resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembersUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembersUuidDelete$Response(params: ApiV1MembersUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1MembersUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Member resource.
   *
   * Removes the Member resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembersUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembersUuidDelete(params: ApiV1MembersUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1MembersUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1MembersUuidPatch()` */
  static readonly ApiV1MembersUuidPatchPath = '/v1/members/{uuid}';

  /**
   * Updates the Member resource.
   *
   * Updates the Member resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembersUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1MembersUuidPatch$Response(params: ApiV1MembersUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiMember>> {
    return apiV1MembersUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the Member resource.
   *
   * Updates the Member resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembersUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1MembersUuidPatch(params: ApiV1MembersUuidPatch$Params, context?: HttpContext): Observable<ApiMember> {
    return this.apiV1MembersUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiMember>): ApiMember => r.body)
    );
  }

}
