import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { ApiJoinPeriodService } from '../api/services';
import { map, switchMap, tap, concatMap, toArray } from 'rxjs/operators';
import { ApiJoinPeriod } from '../api/models';

@Injectable({
  providedIn: 'root'
})
export class JoinPeriodService {
  private joinPeriods: any[] = [];
  private loadingPages: Set<number> = new Set();
  private totalPages: number | null = null;

  constructor(
    private apiJoinPeriodService: ApiJoinPeriodService
  ) { }

  private fetchPage(page: number, headerXTransactionId: string): Observable<any[]> {
    if (this.loadingPages.has(page)) {
      return of([]);
    }
    this.loadingPages.add(page);

    return this.apiJoinPeriodService.apiV1JoinPeriodsGetCollection({
      'X-Transaction-Id': headerXTransactionId,
      page
     }).pipe(
      tap(data => {
        if (data.length > 0) {
          this.joinPeriods = [...this.joinPeriods, ...data];
          this.loadingPages.delete(page);
          if (data.length < 50) {
            this.totalPages = page; // Last page detected
          }
        } else {
          this.totalPages = page - 1; // No more data
        }
      })
    );
  }

  getJoinPeriods(headerXTransactionId: string): Observable<any[]> {
    if (this.totalPages !== null) {
      return of(this.joinPeriods);
    }

    return this.fetchAllPages(headerXTransactionId);
  }

  private fetchAllPages(headerXTransactionId: string): Observable<any[]> {
    let page = 1;
    const fetchPages$ = new Observable<any[]>(observer => {
      const fetchNextPage = () => {
        this.fetchPage(page, headerXTransactionId).subscribe(data => {
          if (data.length < 50 || this.totalPages !== null) {
            observer.next(this.joinPeriods);
            observer.complete();
          } else {
            page++;
            fetchNextPage();
          }
        });
      };
      fetchNextPage();
    });

    return fetchPages$;
  }

  getByUUID(uuid: string, headerXTransactionId: string): Observable<any> {
    return this.getJoinPeriods(headerXTransactionId).pipe(
      map(joinPeriods => joinPeriods.find(period => period.uuid === uuid))
    );
  }

  public isJoinPeriodNow(joinPeriod: ApiJoinPeriod): boolean {
    const TODAY = new Date();
    return (
      new Date(joinPeriod.startDate) <= TODAY &&
      new Date(joinPeriod.endDate) >= TODAY
    );
  }
}
