/* tslint:disable */
/* eslint-disable */
export class ApiJsonld {
  '@context'?: (string | {
'@vocab': string;
'hydra': 'http://www.w3.org/ns/hydra/core#';
[key: string]: any;
});
  '@id'?: string;
  '@type'?: string;
  address?: string;
  createdAt?: string;
  id?: number;
  name?: string;
  shortName?: string;
  town?: string;
  updatedAt?: string;
  uuid?: string;
  zip?: string;
}

