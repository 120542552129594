
<div class="title1">
  <span class="title-red">B2M </span><span class="title-blue">PARTNERS</span>
</div>

<div class="container text-center">
          <div class="title2">un atout majeur pour le club</div>
          <div class="intro-text">Depuis sa création, B2M Futsal n’a eu de cesse de rechercher des partenaires
            pour péréniser le club et lui permettre de fonctionner.
            <br>
            MERCI à tous nos partenaires pour leur soutien.</div>
          <hr class="violet-hr">
</div>

<div class="container">

  <b2m-block-partners *ngFor="let block of blocks" [title]="block.title" [image]="block.image" [button]="block.button" [partners]="block.partners"></b2m-block-partners>

</div>
