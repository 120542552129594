import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembershipFormComponent } from './components/membership-form/membership-form.component';
import { DynamicFieldComponent } from './components/dynamic-field/dynamic-field.component';
import { StepComponent } from './components/step/step.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TextFieldComponent } from './fields/text-field/text-field.component';
import { SelectFieldComponent } from './fields/select-field/select-field.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DateFieldComponent } from './fields/date-field/date-field.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_DATE_FORMATS } from 'src/app/constants';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CategorySelectorComponent } from './fields/category-selector/category-selector.component';
import { DualSelectorComponent } from './fields/dual-selector/dual-selector.component';
import { CheckboxFieldComponent } from './fields/checkbox-field/checkbox-field.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SummaryComponent } from './fields/summary/summary.component';

@NgModule({
  declarations: [
    MembershipFormComponent,
    DynamicFieldComponent,
    StepComponent,
    TextFieldComponent,
    SelectFieldComponent,
    DateFieldComponent,
    CategorySelectorComponent,
    DualSelectorComponent,
    CheckboxFieldComponent,
    SummaryComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
  ],
  exports: [MembershipFormComponent]
})
export class DynamicFormModule { }
