<div class="container">
  <div class="title1">
      <span class="title-blue">Nos </span>
      <span class="title-red">Stades</span>
  </div>
  <div class="text-center">
      <h2 class="title2">Découvrez nos infrastructures sportives</h2>
      <p class="intro-text">Les villes du Perreux-sur-Marne, de Bry-sur-Marne, de Neuilly-Plaisance et Noisy-le-Grand mettent à
          disposition du club des lieux dédiés pour pratiquer le Futsal dans les meilleures conditions.</p>
      <hr class="violet-hr">
  </div>
</div>

<div class="container">
  <div *ngFor="let stadium of stadiums" class="stadium-card" wmAnimate="bounceInDown" speed="slower" aos="1">
      <div class="row">
          <div class="col-md-6">
              <div class="stadium-image-container">
                  <img [src]="stadium.img" alt="Image du stade" class="img-fluid stadium-image">
              </div>
          </div>
          <div class="col-md-6">
              <div class="stadium-details">
                  <h3 class="stadium-title">{{stadium.name}}</h3>
                  <p class="stadium-address">{{stadium.address}}</p>
                  <a class="btn btn-primary" [attr.href]="'https://www.google.com/maps/dir/?api=1&destination=' + stadium.address | encodeURI"
                      target="_blank">Comment s'y rendre</a>
              </div>
          </div>
      </div>
  </div>
</div>
