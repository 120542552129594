<div class="row">
  <img src="assets/images/degrade_bg.jpg" class="header-bg"/>
</div>

<b2m-header *ngIf="!displayOnWebView"></b2m-header>

<b2m-navbar *ngIf="!displayOnWebView"></b2m-navbar>

<b2m-banner-homepage *ngIf="!displayOnWebView"></b2m-banner-homepage>
<b2m-banner-top *ngIf="!displayOnWebView"></b2m-banner-top>

    <!-- Main Content Area -->
    <div class="main-content">
      <div class="mat-app-background">
        <!-- Routed view  -->
        <router-outlet></router-outlet>
      </div>
  </div>
  <!-- End Main Content Area -->

<hr class="violet-hr">
<b2m-banner-celebration *ngIf="!displayOnWebView"></b2m-banner-celebration>

<!-- banners
<div *ngIf="!displayOnWebView">
  <b2m-banner-business-cup></b2m-banner-business-cup>
  <b2m-banner-membership></b2m-banner-membership>
  <b2m-banner-partner></b2m-banner-partner>
  <b2m-banner-store></b2m-banner-store>
  <b2m-banner-b2mproject></b2m-banner-b2mproject>
  <b2m-banner-uae></b2m-banner-uae>
</div>-->

<b2m-contact *ngIf="!displayOnWebView"></b2m-contact>
<b2m-messenger *ngIf="!displayOnWebView"></b2m-messenger>
<b2m-go-top *ngIf="!displayOnWebView"></b2m-go-top>

<!-- footer -->
<b2m-footer *ngIf="!displayOnWebView" [version]="version"></b2m-footer>
