import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiVenue } from '../api/models';
import { ApiVenueService } from '../api/services';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VenueService {

  private venues$: Observable<ApiVenue[]>;

  constructor(private apiVenueService: ApiVenueService) { }

  getVenues(headerXTransactionId: string): Observable<ApiVenue[]> {
    if (!this.venues$) {
      this.venues$ =  this.apiVenueService.apiV1VenuesGetCollection({'X-Transaction-Id': headerXTransactionId}).pipe(
        shareReplay(1)
      );
    }
    return this.venues$;
  }

  getByUUID(uuid: string): Observable<any> {
    if (!this.venues$) {
      // If venues$ has not been initialized yet, return an empty observable or handle accordingly
      return of(null);
    }

    return this.venues$.pipe(
      map(venues => venues.find(venue => venue.uuid === uuid))
    );
  }
}
