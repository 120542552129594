import { Injectable } from '@angular/core';
import { TEAMS } from 'src/app/data/team-data';
import { Team } from '../models/team.model';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor() { }

  getAll(): Team[] {
    return TEAMS.map(team => {
      team.thumbmail = team.thumbmail !== undefined ? team.thumbmail : 'assets/images/teams/Equipe_avatar.jpg';
      team.posterImg = team.posterImg !== undefined ? team.posterImg : 'assets/images/teams/Equipe_avatar.jpg';
      return team;
    });
  }
}
