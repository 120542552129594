<div class="container">
    <div class="title1"><span class="title-red">règlement</span> intérieur</div>
</div>

<div class="container justify">
    <div class="row">
        <div class="col-md-12">
            <div class="title2">Ce règlement est applicable à partir du 1er juillet 2012</div>
            <div class="intro-text">Tout adhérent de l’association doit avoir connaissance des obligations qui
                le lient au club :<br><br>> Le club favorise la pratique du futsal : organisation d’entraînements
                sous la responsabilité d’un éducateur, encadrement des matchs, prêt de matériel et d’équipements
                pour jouer et utilisation des infrastructures municipales.

                <br><br>> Le joueur accepte le règlement intérieur du club.

            </div>
            <hr class="violet-hr">
        </div>



        <div class="col-md-12">

            <br><br>
            <span class="titre_reglement">ARTICLE I - LES MEMBRES</span>
            <br><br>
            <strong>1.1 Cotisation</strong><br>
            Les membres adhérents doivent s’acquitter d’une cotisation annuelle, dont le barème est fixé
            annuellement à l’Assemblée Générale Ordinaire sur proposition du Comité de direction.
            La cotisation annuelle doit être versée intégralement ou en partie le jour de l’inscription. Dans le cas
            d’un paiement partiel, l’adhérent doit tout de même fournir la totalité des chèques. Tant qu’une
            cotisation est incomplète ou impayée, aucune licence n’est délivrée à l’adhérent.Toute cotisation versée
            à l’association est définitivement acquise. Il ne saurait être exigé un remboursement de cotisation en
            cours d’année en cas de démission, d’exclusion ou de décès d’un membre.
            <br><br>
            <strong>1.2 Admission de membres nouveaux</strong><br>
            Les personnes désirant adhérer devront remplir un bulletin d’adhésion. Pour les mineurs de moins de
            dix-huit ans, ce bulletin
            est rempli par le représentant légal. Cette demande doit être acceptée par le comité de direction. A
            défaut de réponse dans les quinze jours du dépôt du bulletin d’adhésion, la demande est réputée avoir
            été acceptée. Le règlement intérieur à jour est remis
            à chaque nouvel adhérent.
            <br><br>
            <strong>1.3 Exclusions</strong><br>
            Conformément à l’article 6 des statuts, un membre peut être exclu pour les motifs suivants :<br>
            > Matériel détérioré<br>
            > Comportement dangereux<br>
            > Propos désobligeants envers les autres membres ou les éducateurs<br>
            > Comportement non conforme à l’éthique de l’association<br>
            > Non-respect du règlement intérieur<br>
            <br><br>
            Celle-ci doit être prononcée par le comité de direction après avoir entendu les explications du membre
            contre lequel une procédure d’exclusion est engagée.<br>Le membre sera convoqué par lettre recommandée
            avec AR quinze jours avant cette réunion. Cette lettre comportera les motifs de la radiation. Il pourra
            se faire assister d’une personne de son choix. La décision de la radiation sera notifiée par lettre
            recommandée avec AR.
            <br><br>
            <strong>1.4 Démission – Décès – Disparition</strong><br>
            Le membre démissionnaire devra adresser sous lettre sa décision au comité de direction. Le membre
            n’ayant pas réglé sa cotisation annuelle dans un délai de 1 mois à compter<br>de la date d’exigibilité
            sera considéré d’office comme démissionnaire. Aucune restitution de cotisation n’est due au membre
            démissionnaire.
            En cas de décès, la qualité de membre s’éteint avec la personne.

            <br><br>

            <span class="titre_reglement">ARTICLE II - FONCTIONNEMENT DE L’ASSOCIATION</span>
            <br><br>
            <strong>2.1 Mesures de police</strong><br>
            Il est interdit de fumer dans les locaux prêtés à l’association. Des boissons alcoolisées ne peuvent pas
            être introduites dans ces mêmes locaux.
            <br><br>
            <strong>2.2 Assemblée générale ordinaire.</strong><br>
            Conformément à l’article 11 des statuts de l’association, l’assemblée générale ordinaire se réunit une
            fois par an sur convocation du comité de direction ou sur la demande du quart<br>au moins de ses
            membres. Les membres à jour de leur cotisation sont convoqués par e-mail ou lettre simple 15 jours avant
            la réunion.
            Le vote s’effectue par bulletin secret déposé dans l’urne tenue par le secrétaire de séance. Les mineurs
            ont droit de vote à partir de l’âge de 16 ans. Il est désigné un secrétaire de séance en début de
            réunion. Il rédige un procès-verbal de l’assemblée générale. Les votes par procuration ou par
            correspondance sont autorisés.
            <br><br>
            <strong>2.3 Assemblée générale extraordinaire</strong><br>
            Une assemblée générale extraordinaire peut se réunir en cas de modification essentielle des statuts ou
            situation financière difficile. Le fonctionnement d’une assemblée générale extraordinaire est le même
            qu’une assemblée générale ordinaire.
            <br><br>
            <strong>2.4 Modification du règlement intérieur</strong><br>
            Le règlement intérieur est établi par le comité de direction conformément à l’article 18 des statuts de
            l’association puis ratifié par l’assemblée générale ordinaire.
            Le nouveau règlement intérieur est transmis à tous les membres de l’association en début de saison
            sportive.

            <br><br>
            <span class="titre_reglement">ARTICLE III - FONCTIONNEMENT SPORTIF</span>
            <br><br>
            <strong>3.1 Entraînements</strong><br>
            Pour participer aux compétitions officielles, les entraînements sont obligatoires (sauf raison valable
            du point de vue de l’entraîneur), mais la présence aux entraînements n’implique pas obligatoirement que
            le joueur doit figurer sur la feuille du match suivant (choix de l’entraîneur, état de forme, blessure,
            concurrence…). Aucun joueur ne doit gêner le bon déroulement des entraînements.
            <br>
            <strong>Lieux :</strong> Centre des Bords de Marne / Gymnase des Ormes / Gymnase Félix Faure / Gymnase
            Edouard Herriot ou exceptionnellement dans un autre lieu sur convocation.
            <br>
            <strong>Jour :</strong> Selon la catégorie
            <br>
            <strong>Horaire :</strong> Selon la catégorie, mais il faut impérativement être présent 15 minutes avant
            le début.
            <br>
            <strong>Tenue :</strong> Afin de participer pleinement aux exercices, une tenue correcte est exigée et
            deux paires de chaussures sont à prévoir, l’une adaptée aux entraînements en salle,<br>l’autre aux
            entraînements en extérieur.
            <br>
            <strong>Retard :</strong> Tout joueur arrivant en retard devra demander l’autorisation de s’entraîner à
            l’entraîneur et se mettra à la disposition de celui-ci.<br>Il doit également le prévenir en cas de
            départ anticipé. Les Absences répétées sans en informer l’entraîneur entraîneront des sanctions.
            <br>
            Matériel : Chaque joueur prend soin du matériel utilisé pendant les entraînements ou les matchs et
            participe à son rangement et son transport.
            <br><br>
            <strong>3.2 Matchs</strong><br>
            <br>> L’entraîneur désigne les joueurs qui participent au match et les convoquent après le dernier
            entraînement précédant celui-ci.
            <br>> Chaque joueur a le devoir de représenter le club en donnant le meilleur de lui-même. Il doit
            s’entretenir avec l’entraîneur en cas de problème (moral, physique, matériel)<br> ne lui permettant pas
            de se présenter dans les meilleures conditions le jour de la compétition.
            <br>> Le choix de l’entraîneur est sans appel.
            <br>> Un calendrier des matchs est fourni à chaque joueur en début de saison et chaque fois qu’une
            modification a lieu.
            <br>> Le rendez-vous est fixé par l’entraîneur.
            <br>> Le club fournit maillots et shorts à chaque rencontre, ceux-ci sont à restitués propres lors de
            l’entraînement suivant.
            <br><br>
            <strong>3.3 Entraînements</strong><br>
            Porter les couleurs du club doit être considéré par chaque joueur comme un honneur et implique un
            certain nombre de devoirs.D’autre part un joueur doit être maître de lui-même dans toutes les
            circonstances. Certains comportements ne peuvent être tolérés dans une enceinte sportive et à ces
            abords.
            <br>
            <br><strong>Sera passible de sanction :</strong>
            <br>> Tout joueur faisant preuve de mauvais esprit tel que critique, refus de jouer, n’étant pas en état
            de s’entraîner, bagarre, refus de rentrer ou de sortir d’un match ou d’un jeu <br> d’entraînement,
            agressivité non dominée.
            <br>> Tout joueur proférant des insultes vis-à-vis d’autres joueurs, dirigeants, éducateurs, arbitres,
            spectateurs ou adversaire.
            <br>> Tout joueur reconnu coupable de vol ou de complicité.
            <br>> Tout joueur sanctionné d’un carton (jaune ou rouge) pour des faits extérieurs au jeu
            (contestation, altercation, insulte, agression…)
            <br>> Toute déclaration publique concernant la vie du club engage la responsabilité de son auteur sauf
            si celui-ci est mandaté par le comité de direction.

            <br><br>
            <span class="titre_reglement">ARTICLE IV - SANCTIONS</span>
            <br>
            Les amendes prévues selon le barème suivant seront récoltées par un responsable joueur. La somme obtenue
            en fin de saison pourra contribuer au financement d’un repas<br>pour l’ensemble des joueurs
            <br><br>
            <strong>Barème des sanctions :</strong><br>
            <br>> Comportements non tolérés conforme à l’article 3.3 : suspension des compétitions officielles et/ou
            des entraînements.
            <br>> Cotisation non versée conformément à l’article 1.1 : interdiction de s’entraîner jusqu’à la
            régularisation de la situation.
            <br>> Retard à l’entraînement : Tout joueur arrivant 15 minutes après le début de la séance, sans avoir
            prévenu, ne participera pas à celle-ci (même pour une raison valable).
            <br>> Retard pour un match à domicile : Tout joueur en retard (même de 5 minutes) ne participera pas à
            la rencontre.
            <br>> Retard au rendez-vous du match à l’extérieur : Le départ se fera 5 minutes après l’heure de
            rendez-vous avec les joueurs présents.
            <br>> Cartons autres que pour « faits de jeu » : Tout carton (jaune ou rouge) pris par manque de
            maturité, entraînera des sanctions sportives (ex : contestation envers le corps arbitral,<br>
            agressivité démesurée…)
            <br>> Tout manque de respect d’un joueur envers un dirigeant du club ou adverse entraînera une mise à
            l’écart du groupe.
            <br>> Tout joueur suspendu sera à la disposition de son équipe le jour du match pour aider les
            dirigeants.
            <br>> Un joueur n’acceptant pas de se soumettre aux règles en vigueur se verra mis à l’écart.

            <br><br>
            <span class="titre_reglement">ARTICLE IV – DEPART DE L’ADHERENT</span>
            <br>
            <strong>Tout adhérent qui quitte le club est redevable auprès du trésorier des sommes suivantes pour la
                saison en cours ou écoulée en plus de la cotisation normalement payée :</strong>
            <br>> 25 € de licence.
            <br>> 30 € de frais d’équipements pour les Séniors et jeunes
            <br>> 92 € de frais de mutation pour l’adhérent en provenance d’un autre club affilié à la FFF.
            <br><br>
        </div>

    </div>
</div>
