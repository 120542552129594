import { Component, OnInit, Input } from '@angular/core';
import { Menu } from 'src/app/models/menu.model';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'b2m-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() version: string;
  currentYear: number;
  b2mMenu: Menu[];
  familyMenu: Menu[];
  servicesMenu: Menu[];
  
  constructor(
    private menuService: MenuService
  ) { }


  ngOnInit(): void {
    this.currentYear = (new Date()).getFullYear();
    //this.b2mMenu = this.menuService.getMenus().filter(item => item.chapter  === "B2M FUTSAL");
    this.b2mMenu = this.filterMenu(this.menuService.getMenus('orderSitemap'), "B2M FUTSAL");
    this.familyMenu = this.filterMenu(this.menuService.getMenus('orderSitemap'), "B2M FAMILY");
    this.servicesMenu = this.filterMenu(this.menuService.getMenus('orderSitemap'), "SERVICES");
  }

  filterMenu(menu: Menu[], chapter: string) {
    return menu.reduce(function (accumulator, item) {
      if (item.chapter  === chapter) {
        accumulator.push(item);
      }

      if (item.subItems !== null) {
        accumulator =  accumulator.concat(item.subItems.filter(item => item.chapter === chapter));
      }
      return accumulator;
    },[]);
  }
}
