<div *ngIf="display" class="partenaire">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 match_height1 partenaire_bg"></div>
            <div class="col-sm-6 match_height1" align="left" wmAnimate="fadeInleft" speed="fast" aos replay>
                <div class="banner_title white_txt">devenez partenaire !</div>
                <div class="banner_txt white_txt">B2M Futsal peut devenir une vitrine de choix pour communiquer une
                    image positive de votre entreprise et son activité, en profitant des valeurs de notre
                    académie. Un partenariat gagnant-gagnant, qui vous permet de bénéficier d’avantages
                    fiscaux.
                </div>
                <div style="margin-bottom: 35px;">
                    <a href="mailto:partenaire@b2mfutsal.fr" class="partenaire_btn">&gt; contact</a>
                </div>
            </div>

        </div>
    </div>
</div>