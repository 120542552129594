<div class="title1">
	<span class="title-blue">Toutes nos </span><span class="title-red">équipes</span>
</div>

<div class="container text-center">
	<div class="title2">Découvrez toutes nos équipes</div>
	<div class="intro-text">Des Séniors aux Baby Futsal en passant par les Féminines, nos équipes défendent
		fièrement les couleurs du club et des Bords de Marne</div>
	<hr class="violet-hr">
</div>

<div class="container">

	<div class="row">

		<div class="d-flex justify-content-lg-center flex-wrap">

			<div *ngFor="let board of boardStore" class="flex-column produit" wmAnimate="bounceInDown" speed="slower"
				aos="1">
					<div class="text-center grey_bg">
						<img [src]="board.thumbmail===null ? 'assets/images/teams/u11.jpg' : board.thumbmail"
							class="img-fluid" alt="produit">
							<div class="d-flex flex-column justify-content-center align-items-center text-center">
						<div class="btn-more btn-blue" (click)="collapsePoster(board)">
							Découvrir les {{board.shortName}}<br>
						</div>
						</div>
					</div>
			</div>
		</div>
	</div>
	<b2m-collapse-info [posterInput]="posterSelected" id="posterTeam"></b2m-collapse-info>
</div>
