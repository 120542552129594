<div class="summary-container container">
  <div
    class="status-message text-center my-4"
    [ngClass]="{
      accepted: choosenCategory?.isJoined,
      rejected: choosenCategory?.isRejected,
      pending: choosenCategory?.isPending,
      'waiting-list': choosenCategory?.isWaitingList
    }"
  >
    <h2 *ngIf="directlyJoined()">
      <i class="fas fa-check-circle"></i> Félicitations,
      {{ member?.firstName }} {{ member?.lastName }} !
    </h2>
    <h2 *ngIf="choosenCategory?.isRejected">
      <i class="fas fa-times-circle"></i> Désolé, {{ member?.firstName }}
      {{ member?.lastName }} !
    </h2>
    <h2 *ngIf="pendingJoin()">
      <i class="fas fa-clock"></i> Inscription en cours,
      {{ member?.firstName }} {{ member?.lastName }} !
    </h2>
    <h2 *ngIf="toValidateJoin()">
      <i class="fas fa-exclamation-circle"></i> Inscription à valider,
      {{ member?.firstName }} {{ member?.lastName }} !
    </h2>
    <h2 *ngIf="choosenCategory?.isWaitingList">
      <i class="fas fa-hourglass-half"></i> En liste d'attente,
      {{ member?.firstName }} {{ member?.lastName }} !
    </h2>
  </div>

  <div class="attendance-rate-warning card p-4 my-4" *ngIf="isMemberLastSeason && !choosenCategory.isAttendanceRateOK">
    <h3>Information importante</h3>
    <p>
      Malheureusement, vous n'avez pas respecté le taux de présence exigé lors de la saison précédente. En conséquence, vous ne pouvez pas renouveler votre inscription automatiquement.
    </p>
  </div>

  <div
    class="confirmation card p-4 my-4"
    *ngIf="choosenCategory?.isJoined || choosenCategory?.isWaitingList"
  >
    <p *ngIf="directlyJoined()">
      Votre demande d'inscription dans la catégorie
      <strong>{{ choosenCategory?.label }}</strong> pour la saison
      <strong>{{ currentSeason?.label }}</strong> a été acceptée.
    </p>
    <p *ngIf="pendingJoin()">
      Votre demande d'inscription dans la catégorie
      <strong>{{ choosenCategory?.label }}</strong> pour la saison
      <strong>{{ currentSeason?.label }}</strong> est en cours de traitement.
    </p>
    <p *ngIf="toValidateJoin()">
      Votre demande d'inscription dans la catégorie
      <strong>{{ choosenCategory?.label }}</strong> pour la saison
      <strong>{{ currentSeason?.label }}</strong> est sousmise à la validation du responsable de la catégorie.
    </p>
    <p *ngIf="choosenCategory?.isWaitingList">
      Votre demande d'inscription dans la catégorie
      <strong>{{ choosenCategory?.label }}</strong> pour la saison
      <strong>{{ currentSeason?.label }}</strong> est actuellement sur liste
      d'attente.
    </p>
  </div>

  <div
    class="waiting-list-info card p-4 my-4"
    *ngIf="choosenCategory?.isWaitingList"
  >
    <h3>Que signifie être sur liste d'attente ?</h3>
    <p>
      Être sur liste d'attente ne garantit pas une place, mais nous vous
      contacterons dès qu'une place se libérera. Vous serez également informé
      lors de la période des portes ouvertes en fin de saison.
    </p>
  </div>

  <div class="waiting-list-info card p-4 my-4" *ngIf="toValidateJoin()">
    <h3>Qu'est-ce qu'une inscription à valider par le responsable de catégorie ?</h3>
    <p>
      Vous devez vous rendre à la prochaine séance de la catégorie et vous présenter au responsable.
      Après une ou plusieurs séances d'essai, le responsable vous informera de sa décision.
    </p>
  </div>

  <div class="next-steps card p-4 my-4">
    <h3>Prochaines étapes :</h3>
    <ul *ngIf="directlyJoined()">
      <ng-container *ngIf="choosenCategory.licenseMandatory">
        <li>
          Surveillez votre email : la Fédération Française de Football (FFF)
          vous contactera à l'adresse
          <strong
            ><a href="mailto:{{ member?.email }}">{{
              member?.email
            }}</a></strong
          >
          pour effectuer votre demande de licence OBLIGATOIRE
        </li>
        <li>
          Préparez les documents nécessaires qui pourront vous être demandés par
          la FFF :
          <ul>
            <li>Photo de l'adhérent</li>
            <li>Pièce d'identité</li>
            <li>Certificat Médical (si nécessaire)</li>
          </ul>
        </li>
      </ng-container>
      <ng-container *ngIf="!choosenCategory.licenseMandatory">
        <li>
          Envoyez-nous par email à
          <strong
            ><a href="mailto:contact@b2mfutsal.fr"
              >contact@b2mfutsal.fr</a
            ></strong
          >
          :
          <ul>
            <li>Photo de l'adhérent</li>
            <li>Pièce d'identité</li>
            <li>Certificat Médical (si nécessaire)</li>
          </ul>
        </li>
      </ng-container>
    </ul>
    <ul *ngIf="pendingJoin()">
      <li>
        Vous allez recevoir une invitation, par l'application <strong>SPOND</strong>, à
        participer à la prochaine séance d'entraînement de la catégorie pour
        faire un essai.
      </li>
      <li>
        Cette séance d'essai permettra de découvrir la discipline sportive, le cadre du club et de valider si cela vous convient.
      </li>
      <li>
        À l'issue de cette séance, vous échangerez avec l'éducateur pour confirmer l'inscription définitive.
      </li>
    </ul>
    <ul *ngIf="toValidateJoin()">
      <li>
        <strong>Assister à la prochaine séance :</strong> Rendez-vous à la prochaine séance d'entraînement de la catégorie.
      </li>
      <li>
        <strong>Rencontrer le responsable :</strong> Présentez-vous au responsable de la catégorie pour commencer votre période d'essai.
      </li>
      <li>
        <strong>Période d'essai :</strong> Participez activement aux séances d'essai. Cela permettra au responsable de vous évaluer.
      </li>
      <li>
        <strong>Décision finale :</strong> Après les séances d'essai, le responsable vous informera de la décision concernant votre inscription.
      </li>
      <li>
        <strong>Suivi des communications :</strong> Vérifiez régulièrement votre email pour toute mise à jour ou information supplémentaire de notre part.
      </li>
    </ul>
    <ul *ngIf="choosenCategory?.isWaitingList">
      <li>
        Surveillez votre email : nous vous contacterons à l'adresse
        <strong
          ><a href="mailto:{{ member?.email }}">{{ member?.email }}</a></strong
        >
        si une place se libère
      </li>
    </ul>
  </div>

  <div class="engagements card p-4 my-4" *ngIf="choosenCategory?.isJoined">
    <h3>S'inscrire à B2M Futsal, c'est s'engager à :</h3>
    <ul>
      <li>
        <strong>Présence régulière :</strong> Assurez une présence régulière aux entraînements. En cas d'absentéisme de plus de 40% aux séances régulières, le club se réserve le droit de ne pas renouveler l'adhésion la saison suivante.
      </li>
      <li>
        <strong>Respect des horaires :</strong> Soyez ponctuel aux entraînements et aux matchs.
      </li>
      <li>
        <strong>Comportement sportif :</strong> Adoptez un comportement respectueux et fair-play envers les coéquipiers, les adversaires, les encadrants, les arbitres, les dirigeants et les bénévoles.
      </li>
      <li>
        <strong>Participation aux événements :</strong> Participez activement aux événements organisés par le club (tournois, réunions, Assemblée Générale, etc.).
      </li>
      <li>
        <strong>Communication :</strong> Informez le club en cas de difficulté quelconque (blessure, incapacité à participer aux activités, etc.).
      </li>
      <li>
        <strong>Cotisation à jour :</strong> Payez en intégralité la cotisation annuelle.
      </li>
      <li>
        <strong>Licence obligatoire :</strong> Finalisez l'inscription par la demande de licence FFF obligatoire (pour les plus de 5 ans).
      </li>
    </ul>
  </div>

  <div
    class="mutation-info card p-4 my-4"
    *ngIf="choosenCategory.licenseMandatory && mutation && mutationprice > 0"
  >
    <h3>Changement de club :</h3>
    <p>
      Selon vos réponses au questionnaire, vous souhaitez quitter votre club
      actuel (club de football ou de futsal - affilié à la Fédération Française
      de Football) pour rejoindre B2M Futsal. A votre cotisation annuelle
      s'ajoutent des frais de mutation, réclamés par la Ligue de
      Paris-Ile-de-France de Football, pour un montant de {{ mutationprice }} €.
    </p>
  </div>

  <div *ngIf="choosenCategory?.isJoined || choosenCategory?.isPending" class="annual-subscription-fee card p-4 my-4">
    <h3>Tarif de cotisation annuelle</h3>
    <p>
      Le tarif de cotisation annuelle varie selon le lieu d'habitation et le
      nombre d'adhérents au sein du même foyer fiscal.
    </p>
    <div class="subscription-fees">
      <div class="fee">
        <div class="fee-title adherent-1">ADHÉRENT N°1</div>
        <div class="fee-amount">220 € *</div>
      </div>
      <div class="fee">
        <div class="fee-title adherent-2">ADHÉRENT N°2</div>
        <div class="fee-amount">200 € *</div>
      </div>
      <div class="fee">
        <div class="fee-title adherent-3">ADHÉRENT N°3</div>
        <div class="fee-amount">170 € *</div>
      </div>
      <div class="fee">
        <div class="fee-title adherent-4">ADHÉRENT N°4</div>
        <div class="fee-amount">130 € *</div>
      </div>
    </div>
    <p class="discount-info">
      * Une réduction de 30 € s'applique sur chaque adhérent, si l'adresse du
      foyer fiscal est à Bry-sur-Marne, Le Perreux-sur-Marne, Neuilly-Plaisance
      ou Noisy-le-Grand.
    </p>
    <h3>Moyens et possibilités de paiement</h3>
    <p>
      Vous pouvez effectuer le paiement de la cotisation annuelle via :
    </p>
    <ul>
      <li>
        <strong>Virement bancaire :</strong>
        <br> IBAN : FR7617679004320024306206287
        <br> Titulaire : BORDS DE MARNE FUTSAL
        <br> Code Banque : 17679
        <br> Code guichet : 00432
        <br> Compte : 0024306206287
        <br> BIC : SBEXFRP1XXX
        <br> Précisez comme objet du virement "Cotisation {{ currentSeason?.label }} {{ member?.firstName }} {{ member?.lastName }}"
      </li>
      <li>
        <strong>Chèque bancaire :</strong>
        <br> À l’ordre de B2M FUTSAL, à envoyer à l’adresse :
        <address>
          chez Marco FERNANDES
          <br> 2 impasse des Vergers
          <br> 94500 CHAMPIGNY-SUR-MARNE
        </address>
        Précisez au dos du chèque la mention "Cotisation {{ currentSeason?.label }} {{ member?.firstName }} {{ member?.lastName }}"
        <br> Possibilité de payer en 4 échéances maximum en fournissant la totalité des chèques en indiquant au dos la date d'encaissement souhaitée.
      </li>
    </ul>
  </div>

  <!-- Message indicating information will not be sent by email -->
  <div class="email-info alert alert-warning text-center my-4">
    <strong>Note:</strong> Les informations présentées ne seront pas envoyées
    par email.
  </div>

    <!-- Button to save the information as a PDF -->
    <div class="save-info text-center my-4">
      <button (click)="saveAsPDF()" class="btn btn-primary">Sauvegarder les informations en PDF</button>
    </div>
</div>
