<div class="row blue_bg white_txt banner">
  <div class="col-12 col-md-4 text-center banner_img d-flex flex-column justify-content-center grey_bg">
    <img src="assets/images/logos/logo_B2M_Project.svg" class="img-fluid logo mx-auto">
  </div>
  <div class="cold-12 col-md-8 project d-flex flex-column justify-content-center">
    <div class="white_txt title1">des projets réussis !</div>
    Destination UAE / Futsal Business Cup / Partenariat avec le SC Braga / Création d’une ligne merchandising /
    Partenariat TSCI / Création de B2M Partners / Conférence Nutriton Santé Performance / Création de la web série SMART /
    Venue de Djamel Haroun lors du stage gardiens de but / Rapprochement avec l’Olympique Lyonnais.
    <br><br>
    <strong>VIVRE LE FUTSAL & L’EXTRAORDINAIRE</strong>
  </div>
</div>
