<div class="title1">
  <span class="title-blue">Staff & </span><span class="title-red">Académie</span>
</div>
<section>
  <div class="container">
    <div class="intro-text justify">
      Des Babies aux U16 en passant par les Fémikids et les FémiTeens, notre académie est l’une des plus <strong>reconnues
        dans le monde du futsal</strong>.
      <br><br>
      En quelques années, nous nous sommes bâtis <strong>la réputation d’un club sérieux</strong> tant au
      niveau de l’apprentissage de notre sport que sur la bonne conduite de nos joueurs.
      <br><br>
      Chaque année, nous sommes obligés de refuser des dizaines d'adhérents, faute de place.
      <strong>Nos catégories accueillent jusqu'à 25 joueurs</strong> lors d’un ou deux entrainements par
      semaine. Elles participent également à un critérium régional et à des tournois régionaux, nationaux et
      internationaux. Elles sont gérées par un coach responsable, accompagné d'un ou deux adjoints.
      <br><br>
      <strong>Cette Académie doit être le poumon du club</strong>, un vivier nous permettant d’emmener ces
      enfants vers nos équipes seniors avec la culture club et contribuer ainsi à faire monter cette catégorie
      vers les sommets, pour devenir une place forte dans le paysage du Futsal de notre région mais aussi
      sur le plan national.
      <br><br>
      Côté féminines, B2M écrit son histoire sur les mêmes bases et nos Femikids et FemiTeens doivent
      <strong>faire évoluer
        le Futsal féminin des Bords de Marne comme une section majeure du club</strong> avec des ambitions
      croissantes.
    </div>
  </div>

  <hr class="violet-hr">

  <div class="container">
    <div class="title1">
      <span class="title-red">Nos catégories en détail</span>
    </div>
    <b2m-banner-teams></b2m-banner-teams>
  </div>

  <hr class="violet-hr">

  <div class="container">
    <b2m-label></b2m-label>
  </div>

  <div class="container">
    <b2m-goalkeeper></b2m-goalkeeper>
  </div>


  <!-- Staff fotos ? -->

  <b2m-organization-chart [anchorId]="'staff'" [orgArray]="staffArray" [orgTitle]="staffTitle"></b2m-organization-chart>

</section>
