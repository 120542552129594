import { AbstractControl, ValidatorFn } from '@angular/forms';

const knownTowns = [
  { code: '94360', name: 'BRY-SUR-MARNE' },
  { code: '94170', name: 'LE PERREUX-SUR-MARNE' },
  { code: '93360', name: 'NEUILLY-PLAISANCE' },
  { code: '93160', name: 'NOISY-LE-GRAND' }
];

export function zipCodeValidator(townControlName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = /^[0-9]{5}$/.test(control.value);
    if (!valid) {
      return { 'invalidZipCode': { value: control.value } };
    }

    const townControl = control.root.get(townControlName);
    if (!townControl) {
      return null;
    }

    const town = knownTowns.find(t => t.code === control.value);
    if (town) {
      townControl.setValue(town.name, { emitEvent: false });
    }

    return null;
  };
}
