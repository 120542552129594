<div class="row grey-bg label-zone">
  <div class="col-sm-12 col-md-4 vcenter">
      <img src="assets/images/labels/FFF_LABEL-JEUNES_CA-FUTSAL_OR_VERTICAL_RVB.png" alt="Label FFF" class="img-fluid label-image" />
  </div>
  <div class="col-sm-12 col-md-8 justify vcenter label-text">
      <div class="title1">LABEL FFF</div>
      <div class="label-description">
          <div class="label-icon">
              <i class="fas fa-trophy"></i> <!-- Exemple d'icône avec FontAwesome -->
          </div>
          <div class="label-description-text">
              <p>
                  <strong>B2M Futsal a eu l'honneur d'obtenir le Label Jeunes Futsal "OR"</strong> décerné par la
                  <a href="https://www.fff.fr/" target="_blank" rel="noopener noreferrer">Fédération Française de Football</a>.
              </p>
          </div>
      </div>
      <div class="label-description">
          <div class="label-icon">
              <i class="fas fa-check-circle"></i> <!-- Exemple d'icône avec FontAwesome -->
          </div>
          <div class="label-description-text">
              <p>
                  Une belle reconnaissance pour l'académie et les éducateurs qui offrent aux pratiquants les meilleures conditions pour jouer.
              </p>
          </div>
      </div>
      <div class="label-description">
          <div class="label-icon">
              <i class="fas fa-heart"></i> <!-- Exemple d'icône avec FontAwesome -->
          </div>
          <div class="label-description-text">
              <p>
                  <strong><i>L'Esprit Futsal souffle sur les bords de Marne !</i></strong>
              </p>
          </div>
      </div>
  </div>
</div>
