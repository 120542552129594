import { Injectable } from '@angular/core';
import { STAFF } from 'src/app/data/staff-data';
import { Person } from '../models/person.model';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor() { }

  getStaff(): Person[] {
    return STAFF
      .map(coach => ({
        ...coach,
        thumbmail: coach.thumbmail ?? 'assets/images/staff/img_Coach_avatar.jpg'
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}
