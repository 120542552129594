<nav class="navbar navbar-expand-sm navbar-default navbar-nav sticky-top">
    <div class="collapse navbar-nav navbar-collapse justify-content-center">
        <ul ngbNav #nav="ngbNav">
            <li ngbNavItem *ngFor="let menuItem of menuItems">
                <a ngbNavLink *ngIf="menuItem.navLink && !menuItem.scrollLink" [routerLink]="[menuItem.route]"
                    [ngClass]="menuItem.active ? 'active': menuItem.isInscription ? 'inscription' : null"
                    [style.color]="menuItem.menuColor"
                    (click)="onMenuClose();onItemSelect(menuItem)">
                    <i [ngClass]="menuItem.icon"></i><span>{{menuItem.menuTitle}}</span></a>
                <a ngbNavLink *ngIf="menuItem.navLink && menuItem.scrollLink"
                    [routerLinkActive]="['active']"
                    [ngClass]="menuItem.active ? 'active': null"
                    (click)="onMenuClose();scrollToAnchor(menuItem.route)">
                    <i [ngClass]="menuItem.icon"></i><span>{{menuItem.menuTitle}}</span></a>
            </li>
            <div class="separator"></div>
        </ul>
    </div>
</nav>
