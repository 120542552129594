import { Component, OnInit } from '@angular/core';
import { ApiCategory } from 'src/app/api/models';
import { MembershipService } from 'src/app/services/membership.service';

@Component({
  selector: 'b2m-banner-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  categories: ApiCategory[];

  constructor(
    private membershipService: MembershipService
  ) { }

  ngOnInit(): void {
    this.categories = this.membershipService.getAllStatic().map(c => {
      switch(c.type) {
        case 'female':
          c.class = 'btn-red';
          break;
        case 'other':
          c.class = 'btn-grey';
          break;
        default:
          c.class = 'btn-blue';
      }
      return c;
    });
  }

}
