import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ApiCategory, ApiJoinRule, ApiJoinPeriod, ApiSeason } from 'src/app/api/models';
import { ApiCategoryService, ApiJoinPeriodService, ApiJoinRuleService, ApiSeasonService } from 'src/app/api/services';
import { HelperUtils } from 'src/app/utils/helper.utils';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MEMBERSHIP_SEASON_LABEL } from "src/app/constants";
import { JoinPeriod } from 'src/app/models/join-period.model';

@Component({
  selector: 'b2m-registration-settings',
  templateUrl: './registration-settings.component.html',
  styles: [
  ]
})
export class RegistrationSettingsComponent implements OnInit, OnChanges {
  seasons: ApiSeason[] = [];
  categories: ApiCategory[] = [];
  selectedSeason: ApiSeason;
  selectedCategory: ApiCategory;
  joinPeriods: JoinPeriod[] = [];
  dataSource: MatTableDataSource<JoinPeriod>;
  displayedColumns: string[] = ['id', 'joinRule', 'startDate', 'endDate', 'actions'];
  selectedJoinPeriod: ApiJoinPeriod;
  newJoinPeriod: ApiJoinPeriod = {
    category: '',
    endDate: null,
    joinRule: null,
    startDate: null,
  };
  joinRules: ApiJoinRule[] = [];
  selectedJoinRule: ApiJoinRule;
  joinPeriodForm: UntypedFormGroup;
  headerXTransactionId: string

  constructor(
    private formBuilder: UntypedFormBuilder,
    private categoryService: ApiCategoryService,
    private seasonService: ApiSeasonService,
    private joinPeriodService: ApiJoinPeriodService,
    private joinRuleService: ApiJoinRuleService
  ) {}

  ngOnInit(): void {
    this.headerXTransactionId = HelperUtils.generateUUID();
    this.seasonService.apiV1SeasonsGetCollection({'X-Transaction-Id': this.headerXTransactionId}).subscribe((seasons: ApiSeason[]) => {

      seasons.sort((a, b) => b.label.localeCompare(a.label));
      this.seasons = seasons.slice(0, 5);
      this.selectedSeason = seasons.shift(); // select season most recent by default
      this.getCategoriesBySeason(this.selectedSeason);
    });

    this.joinRuleService.apiV1JoinRulesGetCollection({'X-Transaction-Id': this.headerXTransactionId}).subscribe((joinRules: ApiJoinRule[]) => {
      this.joinRules = joinRules;
    });

    this.joinPeriodForm = this.formBuilder.group({
      //category: new FormControl('', [Validators.required]),
      startDate: new UntypedFormControl('', [Validators.required]),
      endDate: new UntypedFormControl('', [Validators.required]),
      //joinRule: new FormControl('', [Validators.required])
    });
  }

  getCategoriesBySeason(season: ApiSeason): void {
    this.categoryService.apiV1CategoriesGetCollection({
      'X-Transaction-Id': this.headerXTransactionId,
      'season.label': MEMBERSHIP_SEASON_LABEL
    }).subscribe((categories: ApiCategory[]) => {
      this.categories = categories.sort((a, b) => b.label.localeCompare(a.label));
    });
  }

  getJoinPeriodsByCategory(category: ApiCategory): void {
    this.joinPeriodService.apiV1JoinPeriodsGetCollection({
      'X-Transaction-Id': this.headerXTransactionId,
      category: category.uuid
    }).subscribe((apiJoinPeriods: ApiJoinPeriod[]) => {
      this.joinPeriods = apiJoinPeriods.map((apiJoinPeriod) => {
        let joinPeriod: JoinPeriod = {};
        Object.assign(joinPeriod, apiJoinPeriod);
        joinPeriod.objectJoinRule = this.joinRules.filter(joinRule => joinRule.uuid === HelperUtils.getIdFromIRI(apiJoinPeriod.joinRule)).shift();
        return joinPeriod;
      });
      this.dataSource = new MatTableDataSource(this.joinPeriods);
    });
  }

  /*addJoinPeriod(newJoinPeriod: JoinPeriod): void {
    newJoinPeriod.category = HelperUtils.getIRI('/v1/categories/' + this.selectedCategory.id);
    newJoinPeriod.joinRule = HelperUtils.getIRI('/v1/join_rules/' + this.selectedJoinRule.id);

    this._joinPeriodService.apiV1JoinPeriodsPost$Json({ body: newJoinPeriod }).subscribe(
      (createdJoinPeriod: JoinPeriod) => {
        console.log('JoinPeriod créée:', createdJoinPeriod);
        this.getJoinPeriodsByCategory(this.selectedCategory);
        this.selectedJoinPeriod = null;
        newJoinPeriod = {
          category: '',
          endDate: null,
          joinRule: null,
          startDate: null,
        };
      },
      (error) => {
        console.error('Erreur lors de la création de la JoinPeriod:', error);
      }
    );
  }*/

  addJoinPeriod() {
    if (this.joinPeriodForm.valid) {
      const newJoinPeriod: ApiJoinPeriod = {
        category: HelperUtils.getIRI('/v1/categories/' + this.selectedCategory.uuid),
        startDate: this.joinPeriodForm.value.startDate,
        endDate: this.joinPeriodForm.value.endDate,
        joinRule: HelperUtils.getIRI('/v1/join_rules/' + this.selectedJoinRule.uuid),
      };
      this.joinPeriodService.apiV1JoinPeriodsPost({
        'X-Transaction-Id': this.headerXTransactionId,
        body: newJoinPeriod
      }).subscribe(joinPeriod => {
        this.joinPeriods.push(joinPeriod);
        /*const data = this.dataSource.data;
        data.push(joinPeriod);
        this.dataSource.data = data;*/
      })

      // Réinitialisez le formulaire
      this.joinPeriodForm.reset();
    }
  }

  editJoinPeriod(joinPeriod: ApiJoinPeriod): void {
    // Implémentez la logique pour mettre à jour la JoinPeriod sélectionnée
  }

  deleteJoinPeriod(joinPeriod: ApiJoinPeriod): void {
    this.joinPeriodService.apiV1JoinPeriodsUuidDelete({
      'X-Transaction-Id': this.headerXTransactionId,
      uuid: joinPeriod.uuid
    }).subscribe(() => {
      // Suppression réussie, effectuez les actions supplémentaires nécessaires, par exemple, recharger la liste des JoinPeriods
      this.getJoinPeriodsByCategory(this.selectedCategory);
      this.selectedJoinPeriod = null; // Réinitialiser la JoinPeriod sélectionnée
    }, (error) => {
      // Gérer l'erreur de suppression, afficher un message d'erreur, etc.
      console.error('Erreur lors de la suppression de la JoinPeriod', error);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges', changes);
  }
}
