<div *ngIf="!field.hidden">
  <div>
    <label for="item-selector">{{ field.label }}</label>
  </div>
  <div class="item-selector" role="listbox" aria-label="Item Selector">
    <div class="item-card"
        *ngFor="let item of field.options"
        (click)="toggleSelection(item)"
        [class.selected]="isSelected(item)"
        role="option"
        [attr.aria-selected]="isSelected(item)">
      <div class="item-header">
        <span class="item-name">{{ item.label }}</span>
      </div>
    </div>
  </div>
</div>
