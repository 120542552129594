import { ViewportScroller } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";

class OrgData {
  id: number;
  name: string = "";
  thumbmail: string = null;
  posterImg: string = null;
  roles: string[] = [];
  isCollapsed: boolean = false;
  description: string = null;
}

@Component({
  selector: "b2m-organization-chart",
  templateUrl: "./organization-chart.component.html",
  styleUrls: ["./organization-chart.component.scss"],
})
export class OrganizationChartComponent implements OnInit {
  posterSelected: OrgData = null;
  @Input() orgArray: Array<OrgData>;
  @Input() orgTitle: string;
  @Input() anchorId: string;
  constructor(private viewportScroller: ViewportScroller) {}

  ngOnInit(): void {
  }

  collapsePoster(orgData: OrgData) {
    if (orgData.posterImg !== null && orgData.posterImg !== undefined) {
      this.orgArray.forEach((element) => {
        element.isCollapsed = false;
      });
      orgData.isCollapsed = true;
      this.posterSelected = orgData;
      this.viewportScroller.scrollToAnchor("posterPerson");
    }
  }
}
